import InputCard from "app/components/atoms/input-card/input-card.component";
import { AiOutlineSearch } from "react-icons/ai";
import styled from "styled-components";

export const Input = styled.input`
width: 100%;
 height: 40px;
 border: 1px solid #dbe7ff;;
 border-radius: 5px;
background-color: #f2f6ff;
  padding: 10px 10px 10px 20px;
    font-size: 20px;
  @media screen and (max-width:768px) {
    height:20px;
    padding: 0;
      font-size: 12px;
    ::placeholder{
      font-size:12px;
      text-align: center;
    }
  }
`;

const Search = ({ setSearchInput, placeholder = "Search", ...props }) => {
  return (
    <InputCard {...props}>
      <AiOutlineSearch style={{width:"20px", height:"20px"}} />
      <Input
        placeholder={placeholder}
        type="text"
        onChange={(e) => setSearchInput(e.target.value)}
      />
    </InputCard>
  );
};

export default Search;
