import { FlexedDiv } from 'app/components/atoms/divs/div.styles';
import Text, { Heading } from 'app/components/atoms/typography';
import Sort from 'app/components/molecules/sort/sort.component';
import { useViewport } from 'hooks';
import { useEffect, useState } from 'react';
import { InvoiceTopBanner } from '../invoice/invoice.styles';
import ProformaMobile from './proformaMobile';
import ProformaList from './proformaDesktop';
import {
  INVOICE_STATUS,
  MOBILE_WINDOW_SIZE,
} from 'utils/constants/constants.utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentActions,
  paymentSelectors,
} from 'store/reducers/payment/paymentSlice';
import Card from 'app/components/atoms/card/card.component';
import { TableHeader } from '../reminder/clientsTable';
import { getStatusByValue } from 'utils/helpers/createStatusStyle/createStatusStyle';
import InvoiceSummaryLink from 'app/components/cell/invoiceSummaryLink/invoiceSummaryLink';

const initialState = {
  searchInput: '',
  pageSize: 10,
  page: 1,
  startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
  endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  statusInput: null,
};

const Proforma = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const { mobile } = useViewport();
  const [dateInput, setDateInput] = useState(
    () =>
      `${new Date(state?.startDate).toLocaleDateString()} - ${new Date(
        state?.endDate
      ).toLocaleDateString()}`
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { searchInput, pageSize, page, startDate, endDate, statusInput } =
    state;
  const isLoading = useSelector(paymentSelectors.isLoading);
  const transactions = useSelector(paymentSelectors.transactions);
  const { getTransactions } = paymentActions;
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const dispatchGetTransactions = () => {
    dispatch(
      getTransactions({
        page,
        pageSize,
        startDate,
        status: statusInput,
        endDate,
        filterText: searchInput,
      })
    );
  };
  useEffect(() => {
    dispatchGetTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, startDate, endDate, statusInput, page, pageSize]);

  useEffect(() => {
    if (dateInput && !dateInput.includes('undefined')) {
      const dates = dateInput.split(' - ');
      setState((s) => ({
        ...s,
        startDate: new Date(dates[0]).toLocaleString(),
        endDate: new Date(dates[1]).toLocaleString(),
      }));
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dateInput]);

  const handlePageChange = (newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  };

  const handlePerRowsChange = async (newPageSize) => {
    setState((prevState) => ({ ...prevState, pageSize: newPageSize }));
  };
  return (
    <>
      <InvoiceTopBanner>
        <div className=" client-invoice__new">
          <div className="invoice_header">
            <Heading color="var(--primary2)" size={mobile ? 18 : 32}>
              Payment Proforma
            </Heading>
          </div>
        </div>
      </InvoiceTopBanner>
      <section style={{ padding: '20px' }}>
        <FlexedDiv>
          <Sort
            filter={'date-range'}
            dateRange={dateInput}
            setDateRange={setDateInput}
          />
        </FlexedDiv>

        <Card style={{ marginTop: '1rem' }}>
          <TableHeader
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0,
            }}
          >
            <div style={{ borderBottom: '1px solid #F2F6FF', width: '100%' }}>
              <Heading color=" var(--primary2)" level={2}>
                Proforma Invoices
              </Heading>
              <Text color="var(--grey2)">
                Monitor the progress of your invoice
              </Text>
            </div>
            <FlexedDiv
              width="100%"
              style={{ gap: '20px', marginBlock: '20px' }}
            >
              <InvoiceSummaryLink
                pendingCount={
                  transactions?.data?.filter(
                    (item) =>
                      getStatusByValue(item.status) === INVOICE_STATUS.PENDING
                  ).length
                }
                approvedCount={
                  transactions?.data?.filter(
                    (item) =>
                      getStatusByValue(item.status) === INVOICE_STATUS.APPROVED
                  ).length
                }
                declinedCount={
                  transactions?.data?.filter(
                    (item) =>
                      getStatusByValue(item.status) === INVOICE_STATUS.DECLINED
                  ).length
                }
                changeRequestCount={
                  transactions?.data?.filter(
                    (item) =>
                      getStatusByValue(item.status) ===
                      INVOICE_STATUS.CHANGE_REQUESTED
                  ).length
                }
                totalCount={transactions?.totalCount}
                statusInput={statusInput}
                setStatusInput={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    statusInput: value,
                  }))
                }
              />
            </FlexedDiv>
          </TableHeader>
          {windowWidth < MOBILE_WINDOW_SIZE && (
            <ProformaMobile
              transactions={transactions?.data}
              pageCount={transactions?.pageCount}
              loading={isLoading}
              onChangePage={handlePageChange}
            />
          )}

          {windowWidth >= MOBILE_WINDOW_SIZE && (
            <ProformaList
              transactions={transactions?.data}
              totalCount={transactions?.totalCount}
              loading={isLoading}
              paginationServer
              paginationTotalRows={transactions?.totalCount}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          )}
        </Card>
      </section>
    </>
  );
};

export default Proforma;
