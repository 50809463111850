import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { NoStyleButton } from '../button/button.styles';
import { Span } from '../typography';

export const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li>
      <NoStyleButton
        onClick={() => setIsOpen(!isOpen)}
        className="accordion-header "
      >
        <Span size={'16px'} weight="500" color={isOpen ? 'black' : 'inherit'}>
          {title}
        </Span>
        <IoIosArrowDown />
      </NoStyleButton>
      {isOpen && <div className="accordion-item-panel">{children}</div>}
    </li>
  );
};
