import Chart from 'chart.js/auto';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { dashboardSelectors } from 'store/reducers/dashboard/dashboardSlice';
import Spinner from '../atoms/spinner/spinner.component';
import ChartLabel from './ChartLabel';
import { FlexedDiv } from '../atoms/divs/div.styles';
import styled from 'styled-components';
const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-around;
`;

const DoughnutChart = ({ invoices, label = 'Invoices', height }) => {
  const isLoading = useSelector(dashboardSelectors.isLoading);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!isLoading && chartData) {
      const chartInstance = new Chart(chartRef?.current, {
        type: 'doughnut',
        data: {
          labels: Object.entries(chartData)?.map(([key, value]) => {
            return `${key} ${value?.amount
              ?.toFixed(2)
              ?.replace(
                /\d(?=(\d{3})+\.)/g,
                '$&,'
              )} ${value?.percentage?.toFixed(2)}%`;
          }),
          datasets: [
            {
              label: label,
              data: Object.values(chartData)?.map((item) => item?.amount),
              backgroundColor: Object.values(chartData)?.map(
                (item) => item?.color
              ),
              hoverOffset: 4,
            },
          ],
        },
        options: {
          borderDash: 0,
          borderDashOffset: 0,
          maintainAspectRatio: false,
          spacing: 0,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
      return () => {
        chartInstance.destroy();
      };
    }
  }, [chartData, isLoading, label]);

  useEffect(() => {
    const data =
      label === 'Invoices'
        ? {
            Approved: {
              amount: invoices?.approvedAmount,
              percentage: invoices?.approvedPercentage,
              color: '#B8C6E4',
            },
            Validation: {
              amount: invoices?.changeRequestedAmount,
              percentage: invoices?.changeRequestPercentage,
              color: '#0148DE',
            },
            Pending: {
              amount: invoices?.pendingAmount,
              percentage: invoices?.pendingPercentage,
              color: '#C99A0A',
            },
            Declined: {
              amount: invoices?.declinedAmount,
              percentage: invoices?.declinedPercentage,
              color: '#BF4545',
            },
          }
        : invoices;
    setChartData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, label]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ChartContainer>
      <div style={{ height: height ?? '100.79px' }}>
        <canvas id="myChart" ref={chartRef}></canvas>
      </div>

      <FlexedDiv
        style={{ marginBottom: '10px', gap: '.5rem', flexWrap: 'wrap' }}
      >
        {chartData &&
          Object.entries(chartData)?.map(([key, value]) => (
            <ChartLabel
              key={key}
              color={value?.color}
              name={key}
              value={value}
            />
          ))}
      </FlexedDiv>
    </ChartContainer>
  );
};

export default DoughnutChart;
