import PreFooter from 'app/components/molecules/pre-footer/pre-footer.component';
import Banner from 'app/components/molecules/banner/banner.component';

import JoinUs from 'app/components/molecules/join-us/join-us.compnent';
import BusinessType from 'app/components/molecules/business-type/business-type.component';
import HowItWorks from 'app/components/molecules/how-it-works/how-it-works.component';
import Features from 'app/components/molecules/features/features.component';
import PlanPanel from 'app/components/molecules/plan-panel/plan-panel.component';
import Text, { Heading } from 'app/components/atoms/typography';

import './home.styles.css';
import styled from 'styled-components';
import { Label } from '../plans/plan.style';
import { useViewport } from 'hooks';
import { useState } from 'react';
import { PLAN_TYPE_NUMBER } from 'utils/constants/constants.utils';
import Cookies from 'app/components/molecules/cookies/cookies.component';

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 80px 64px;
  width: 100%;

  div > .home-plan-panel {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }
  .header {
    text-align: center;
    margin-bottom: 30px;
    p {
      margin: 5px 0;
    }
  }
  .plan-subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  @media screen and (min-width: 768px) {
    padding: 80px 84px;
    .home-plan-panel {
      flex-direction: row;
    }
    .header {
      margin: 0 100px 22px;
      text-align: left;
    }
    .plan-subscription {
      flex-direction: row;
    }
  }

  @media screen and (min-width: 950px) {
    div > .home-plan-panel {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media screen and (max-width: 375px) {
    padding: 34px 20px;
  }
`;

const Home = () => {
  const { mobile } = useViewport();
  const [planType, setPlanType] = useState(PLAN_TYPE_NUMBER.ORGANIZATION);

  return (
    <div className="home__wrapper" id="home">
      <Banner />
      <JoinUs />
      <BusinessType />
      <HowItWorks />
      <Features />
      <PlanContainer>
        <div id="plans">
          <div className="header">
            <Heading size={mobile ? 20 : 40}>Choose Your Plan</Heading>
            <div className="plan-subscription">
              <Text size={mobile ? 14 : 20}>
                We have plans that suits your business.
              </Text>
              <div style={{ display: 'flex' }}>
                <Label htmlFor="organization">
                  <input
                    type="radio"
                    id="organization"
                    name="option"
                    value={PLAN_TYPE_NUMBER.ORGANIZATION}
                    onClick={() => {
                      setPlanType(PLAN_TYPE_NUMBER.ORGANIZATION);
                    }}
                  />
                  <Text
                    size={mobile ? 14 : 20}
                    color="var(--grey1)"
                    htmlFor="organization"
                  >
                    Organization
                  </Text>
                </Label>

                <Label htmlFor="freelancer">
                  <input
                    type="radio"
                    id="freelancer"
                    name="option"
                    value={PLAN_TYPE_NUMBER.CLIENT}
                    onClick={() => {
                      setPlanType(PLAN_TYPE_NUMBER.CLIENT);
                    }}
                    style={{ marginLeft: '20px' }}
                  />
                  <Text
                    size={mobile ? 14 : 20}
                    color="var(--grey1)"
                    htmlFor="freelancer"
                  >
                    Freelancer
                  </Text>
                </Label>
              </div>
            </div>
          </div>
          <PlanPanel className="home-plan-panel" planType={planType} />
        </div>
      </PlanContainer>
      {/* <Testimonies /> */}
      <PreFooter />
      <Cookies />
    </div>
  );
};

export default Home;
