import { OrganizationSettingsContainer } from './settings.styles';
import {
  SettingsActions,
  SettingsSelectors,
} from 'store/reducers/settings/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Text from 'app/components/atoms/typography';
import LogoBillHeader from 'app/components/molecules/setting/organization/logo-bill-header';

const BillSettings = () => {
  const dispatch = useDispatch();
  const organization = useSelector(SettingsSelectors.organization);
  const isLoading = useSelector(SettingsSelectors.isLoading);
  const { getOrganizationDetails } = SettingsActions;

  useEffect(() => {
    dispatch(getOrganizationDetails());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OrganizationSettingsContainer>
      <div>
        <Text color="var(--grey2)" size="18px">
          Customize your bill to fit your brand identity.
        </Text>
        <LogoBillHeader organization={organization} isLoading={isLoading} />
      </div>
    </OrganizationSettingsContainer>
  );
};

export default BillSettings;
