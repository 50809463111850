import { useDispatch, useSelector } from 'react-redux';
import Button from 'app/components/atoms/button/button.component';
import Card, { CardContainer } from 'app/components/atoms/card/card.component';
import PageMessage from 'app/components/molecules/page-message/page-message.component';
import Table from 'app/components/molecules/table/table.component';
import {
  BaseButton,
  InvertedButton,
} from 'app/components/atoms/button/button.styles';
import styled from 'styled-components';
import { RiArrowDropDownLine, RiSendPlaneFill } from 'react-icons/ri';
import { HiOutlinePlus } from 'react-icons/hi';
import { TableContainer, TableHeader } from '../reminder/clientsTable';
import './modal.styles.css';
import Text, { Heading } from 'app/components/atoms/typography';
import { BsFilter, BsSearch } from 'react-icons/bs';
import { useEffect, useRef } from 'react';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import PeopleIcon from '../../assets/img/peopleImage.png';
import {
  SettingsActions,
  SettingsSelectors,
} from 'store/reducers/settings/settingsSlice';
import { useState } from 'react';
import { useCheckAdmin, useOnClickOutside, useViewport } from 'hooks';
import InviteContacts from './invite-contacts.component';
import { useNavigate } from 'react-router-dom';
import { ACTIVATION_STATUS } from 'utils/constants/constants.utils';
import {
  externalUserActions,
  externalUserSelectors,
} from 'store/reducers/externalUser/externalUserSlice';
import CustomModal from 'app/components/atoms/modal/modal.component';
import {
  InvertedStatusButton,
  StatusButton,
} from 'app/components/atoms/button/button.styles';
import { PeopleFlex } from './people.styles';
import PeopleMobileView from './people.mobile';
import ModalHouse from 'app/components/molecules/modals/modalHouse';
import { ExternalUserModal } from 'app/components/cell/externalUserModal/externalUserModal';
import { Flex } from '../dashboard/dashboard.styles';
import { FilterDropdown } from 'app/components/molecules/organization/organization.styles';
import {
  getActiveStatusName,
  getBooleanStatusStyle,
} from 'utils/helpers/createStatusStyle/createStatusStyle';
import { getPeopleAction } from 'utils/helpers/getPeopleAction/getPeopleAction';
import dp1 from '../../assets/img/dp1.png';
import dp2 from '../../assets/img/dp2.png';
import dp3 from '../../assets/img/dp3.png';
import dp4 from '../../assets/img/dp4.png';

const ButtonContainer = styled.div`
  display: flex;
  margin: 50px auto 30px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  gap: 20px;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    margin: 10px auto;
  }
  @media screen and (min-width: 820px) {
    justify-content: start;
    flex-direction: row;
  }
  .btn {
    display: flex;
    min-width: auto;
    align-items: center;
    gap: 10px;
    &__send {
      background-color: transparent;
      color: var(--primary);
    }
    &__add {
      .dropdown {
        padding: 0;
      }
      .dropdown__content {
        min-width: auto;
        width: 100%;
        padding: 16px 32px;
        font-size: 20px;
        height: 60px;
        color: white;
        background-color: #0148de;
        &:hover {
          background-color: #3777ff;
          color: #f2f6ff;
          border: none;
        }
      }
    }
  }
`;

const ClientHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: 400px) {
    flex-direction: ${({ direction }) => direction};
  }
  .card {
    width: fit-content;
    padding: 25px;
    &__text {
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
      }
      img + img {
        margin-left: -10px;
      }

      @media screen and (max-width: 493px) {
        :last-child {
          margin: 0;
        }
      }
      @media screen and (max-width: 444px) {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    text-align: center;
    @media screen and (min-width: 600px) {
      width: 300px;
      text-align: left;
    }
  }
  .btn {
    min-width: auto;
  }
  @media screen and (min-width: 768px) {
    .btn {
      position: absolute;
      right: 0;
      margin: 0;
      bottom: 0;
    }
    display: block;
  }
`;

const PeopleListContainer = styled.div`
  .dropdown {
    padding: 0;
  }
  .dropdown__content {
    min-width: 100%;
    width: 100%;
    padding: 16px;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      padding: 8px;
      font-size: 14px;
    }
    &:hover {
      background-color: #3777ff;
      color: #f2f6ff;
      border: none;
    }
  }
`;

const PeopleList = ({ users, totalCount }) => {
  const defaultDp = [dp1, dp2, dp3, dp4];

  const { getPaginatedExternalUsers, deleteExternalUser } = externalUserActions;
  const { searchUsers, userActivation } = SettingsActions;

  const [, setSelectedRows] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const isLoading = useSelector(SettingsSelectors.isLoading);
  const isExtLoading = useSelector(externalUserSelectors.isLoading);
  const [id, setId] = useState(null);
  const [manage, setManage] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(users);
  const [dropShow, setDropShow] = useState(false);
  const [addDropShow, setAddDropShow] = useState(false);
  const [approveDel, setApproveDel] = useState(false);
  const [reload, setReload] = useState(false);
  const [editUser, setEditUser] = useState({ value: false, data: null });
  const [format, setFormat] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inviteContact, setInviteContact] = useState(false);
  const {
    visible: roleVisible,
    setVisible: setRoleVisible,
    ref: statusRef,
  } = useOnClickOutside(false);

  const componentRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiResponse } = useHandleApiResponse();
  const { role } = useCheckAdmin();
  const { mobile } = useViewport();

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleEditUser = (row) => {
    setIsOpen(true);
    setEditUser({ value: true, data: row });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsDeleteLoading(true);
    dispatch(deleteExternalUser(id)).then((res) => {
      handleApiResponse(res, null);
      setActiveStatus(!activeStatus);
      setId(null);
      setApproveDel(false);
      setIsDeleteLoading(false);
    });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    if (manage) {
      setPageSize(newPerPage);
      setPage(page);
    }
  };

  const handlePageChange = (page) => {
    if (manage) {
      setPage(page);
    }
  };

  useEffect(() => {
    if (role === USER_ROLES.EASEI_ADMIN) {
      setAvailableRoles([
        USER_ROLES.EASEI_USER,
        USER_ROLES.CLIENT_USER,
        USER_ROLES.EASEI_ADMIN,
        USER_ROLES.ORGANIZATION_ADMIN,
        USER_ROLES.ORGANIZATION_USER,
      ]);
    } else if (role === USER_ROLES.ORGANIZATION_ADMIN) {
      setAvailableRoles([USER_ROLES.CLIENT_USER, USER_ROLES.ORGANIZATION_USER]);
    } else if (role === USER_ROLES.ORGANIZATION_USER) {
      setAvailableRoles([USER_ROLES.CLIENT_USER]);
    } else if (role === USER_ROLES.EASEI_USER) {
      setAvailableRoles([
        USER_ROLES.CLIENT_USER,
        USER_ROLES.ORGANIZATION_ADMIN,
        USER_ROLES.ORGANIZATION_USER,
      ]);
    }
  }, [role]);

  useEffect(() => {
    let input = {
      page: page,
      pageSize,
      role: selectedRole,
      filter: filterInput,
    };
    if (role === USER_ROLES.CLIENT_USER) {
      input = {
        page: page,
        pageSize,
        filter: filterInput,
      };
      dispatch(getPaginatedExternalUsers(input)).then((res) => {
        setFilteredData(res.payload.data);
      });
    } else {
      dispatch(searchUsers(input)).then((res) => {
        setFilteredData(res.payload.data);
      });
    }
    setManage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole, reload, activeStatus, page, filterInput]);

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.fullName,

      sortable: true,
      grow: role !== 'ClientUser' && 2,
    },
    {
      name: 'Email address',
      selector: (row) => row.email,

      sortable: true,
      grow: 2,
    },
    {
      name: 'Phone number',
      selector: (row) => row.phoneNumber,

      sortable: true,
      grow: role !== 'ClientUser' && 2,
    },
    {
      name: 'Role',
      selector: (row) => row.role,

      sortable: true,
      grow: role !== 'ClientUser' && 2,
    },
    {
      name: 'Status',
      selector: (row) => (
        <p style={{ color: getBooleanStatusStyle(row.isActive) }}>
          {getActiveStatusName(row.isActive)}
        </p>
      ),
      sortable: true,
    },
    {
      name: '',
      selector: (row) =>
        getPeopleAction(
          row,
          role,
          userActivation,
          setActiveStatus,
          activeStatus
        ),
      button: true,
    },
  ];

  if (role === USER_ROLES.CLIENT_USER) {
    columns[1].grow = 1;
    columns[5] = {
      name: '',
      selector: (row) =>
        getPeopleAction(
          row,
          role,
          userActivation,
          setActiveStatus,
          activeStatus,
          handleEditUser,
          setApproveDel,
          setId
        ),
      button: true,
    };
    delete columns[3];
    delete columns[4];
  }

  return (
    <>
      {filteredData === null ? (
        <>
          {role !== USER_ROLES.CLIENT_USER && (
            <PeopleListContainer>
              <div>
                <Heading size={mobile ? 18 : 32}>Welcome Charity,</Heading>
                <Text size={mobile ? 12 : 18}>
                  Let's handle your invoicing needs hassle-free!
                </Text>
              </div>
              <Card>
                <PeopleFlex>
                  <img src={PeopleIcon} alt="icon" />
                  <div
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '32px 0 40px 0',
                      gap: '16px',
                    }}
                  >
                    <Heading color="var(--primary2)" size={mobile ? 18 : 36}>
                      Business is no fun without people
                    </Heading>
                    <Text
                      maxWidth="321px"
                      color="var(--grey1)"
                      size={mobile ? 12 : 18}
                    >
                      Create and manage your vendors, all in one place.
                    </Text>
                  </div>
                  <BaseButton
                    width="true"
                    onClick={() => setInviteContact(true)}
                  >
                    Invite your Staff
                  </BaseButton>

                  <Text
                    margin="32px 0 0 0"
                    color="var(--primary5)"
                    size={mobile ? 12 : 18}
                  >
                    Click here to import your staff's contacts from file
                  </Text>
                </PeopleFlex>
              </Card>
            </PeopleListContainer>
          )}
          {
            <InviteContacts
              visible={inviteContact}
              setVisible={setInviteContact}
              byCsv={true}
            />
          }
        </>
      ) : (
        <PeopleListContainer>
          {mobile ? (
            ''
          ) : (
            <div style={{ marginBottom: '20px' }}>
              <PageMessage heading="People" />
            </div>
          )}
          {role === USER_ROLES.CLIENT_USER && (
            <ClientHeader direction="column">
              <CardContainer
                maxWidth={mobile ? '200px' : '256px'}
                p={mobile ? '3px 10px' : ''}
              >
                <Text size="14" color="#7B8599">
                  Clients
                </Text>
                <Flex>
                  <Text
                    margin="0"
                    style={{ marginTop: '16px' }}
                    size={mobile ? 16 : 24}
                    color="var(grey1)"
                    weight="700"
                    className="card__text"
                  >
                    {totalCount}
                  </Text>
                  <div className="dp card__text">
                    {defaultDp?.map((dp) => {
                      return <img key={dp} src={dp} alt="dp" />;
                    })}
                  </div>
                </Flex>
              </CardContainer>
              <ButtonContainer justifyContent="space-between" className="btn">
                <BaseButton
                  minWidthS="100%"
                  minWidthM="150px"
                  radius={mobile ? '30px' : ''}
                  height={mobile ? '35px' : '60px'}
                  padding={mobile ? '0' : ''}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <HiOutlinePlus style={{ marginRight: '8px' }} />
                  <Text color="white" size={mobile ? 12 : 16}>
                    Add People
                  </Text>
                </BaseButton>
              </ButtonContainer>
            </ClientHeader>
          )}

          {(role === USER_ROLES.ORGANIZATION_ADMIN ||
            role === USER_ROLES.ORGANIZATION_USER) && (
            <ButtonContainer justifyContent="space-between" className="btn">
              <InvertedButton
                minWidthS="100%"
                minWidthM="150px"
                radius={mobile ? '30px' : ''}
                height={mobile ? '35px' : '60px'}
                padding={mobile ? '0' : ''}
                className="btn btn__send"
                onClick={() => {
                  navigate('/reminder', { state: { value: true } });
                }}
              >
                <Text
                  size={mobile ? 12 : ''}
                  weight={700}
                  color="var(--primary)"
                >
                  Send Reminder
                </Text>
                <RiSendPlaneFill />
              </InvertedButton>
              <Button
                minWidthS="100%"
                minWidthM="150px"
                radius={mobile ? '30px' : ''}
                height={mobile ? '35px' : '60px'}
                padding={mobile ? '0' : ''}
                className="btn btn__add"
                style={{ position: 'relative' }}
                onClick={() => setAddDropShow(!addDropShow)}
              >
                <HiOutlinePlus />
                <Text size={mobile ? 12 : ''} weight={700} color="white">
                  Add People
                </Text>
                <RiArrowDropDownLine size={30} />
                {addDropShow && (
                  <div
                    className="dropdown"
                    style={{
                      position: 'absolute',
                      top: '45px',
                      color: 'black',
                      zIndex: 1,
                      left: '0',
                      right: '0',
                    }}
                  >
                    <div
                      className="dropdown__content"
                      onClick={() => {
                        setVisible(true);
                        setFormat(false);
                      }}
                    >
                      Email
                    </div>
                    <div
                      className="dropdown__content"
                      onClick={() => {
                        setVisible(true);
                        setFormat(true);
                      }}
                    >
                      CsvFile
                    </div>
                  </div>
                )}
              </Button>
            </ButtonContainer>
          )}

          {mobile ? (
            <PeopleMobileView
              setFilterInput={setFilterInput}
              users={filteredData}
              userActivation={userActivation}
              ACTIVATION_STATUS={ACTIVATION_STATUS}
              activeStatus={activeStatus}
              setActiveStatus={setActiveStatus}
              setId={setId}
              handleEditUser={handleEditUser}
              setApproveDel={setApproveDel}
              loading={isLoading}
              role={role}
              USER_ROLES={USER_ROLES}
              setDropShow={setDropShow}
              dropShow={dropShow}
              availableRoles={availableRoles}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              onChangePage={handlePageChange}
              page={page}
              setPage={setPage}
              pageCount={Math.ceil(totalCount / pageSize)}
            />
          ) : (
            <TableContainer>
              <Card>
                <div ref={componentRef}>
                  <TableHeader>
                    <div>
                      <Heading color=" var(--primary2)" level={2}>
                        {role === USER_ROLES.CLIENT_USER
                          ? 'All Clients'
                          : 'All Staff'}
                      </Heading>
                      <Text color="var(--grey2)">
                        View all your
                        {role === USER_ROLES.CLIENT_USER
                          ? ' client '
                          : ' staff '}
                        information here.
                      </Text>
                    </div>

                    {role !== USER_ROLES.CLIENT_USER && (
                      <div
                        className="search"
                        style={{ position: 'relative' }}
                        onClick={() => setRoleVisible(!roleVisible)}
                      >
                        <BsFilter color="var(--accent)" />
                        <Text margin="0" color="var(--grey2)">
                          {selectedRole ? selectedRole : 'Role'}
                        </Text>
                        <RiArrowDropDownLine size={30} color="var(--grey2)" />
                        {roleVisible && (
                          <FilterDropdown ref={statusRef}>
                            {availableRoles?.map((option) => (
                              <div
                                className="dropdown_options"
                                key={option}
                                onClick={() => {
                                  setSelectedRole(option);
                                  setRoleVisible(!roleVisible);
                                }}
                              >
                                {option}
                              </div>
                            ))}
                          </FilterDropdown>
                        )}
                      </div>
                    )}

                    <div className="search field">
                      <BsSearch
                        onClick={() => {
                          setFilterInput(searchInput);
                        }}
                      />
                      <input
                        type="text"
                        style={{ outline: 'none' }}
                        placeholder="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                  </TableHeader>
                  <Table
                    title={
                      <Text
                        style={{
                          borderBottom: '2px solid #3A3E47',
                          width: 'fit-content',
                          padding: '8px',
                        }}
                        color="#3A3E47"
                      >
                        All ({totalCount})
                      </Text>
                    }
                    columns={columns}
                    data={users}
                    totalCount={totalCount}
                    onSelectedRowsChange={handleRowSelected}
                    selectableRows={false}
                    pagination
                    progressPending={isLoading || isExtLoading}
                    actions={<></>}
                    paginationServer
                    paginationTotalRows={totalCount}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </div>
              </Card>
            </TableContainer>
          )}

          {approveDel && (
            <CustomModal
              isOpen={approveDel}
              className="modal modal-del"
              close={setApproveDel}
            >
              <form
                style={{ marginTop: '65px', textAlign: 'center' }}
                onSubmit={handleDelete}
              >
                <Text>Are you sure you want to delete this user?</Text>
                <div className="btn">
                  <InvertedStatusButton
                    disabled={isDeleteLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      setApproveDel(false);
                    }}
                  >
                    NO
                  </InvertedStatusButton>
                  <StatusButton
                    isLoading={isDeleteLoading}
                    disabled={isDeleteLoading}
                    type="submit"
                  >
                    YES
                  </StatusButton>
                </div>
              </form>
            </CustomModal>
          )}

          {visible && (
            <InviteContacts
              visible={visible}
              setVisible={setVisible}
              byCsv={format || false}
            />
          )}

          <ModalHouse
            className="modal modal__extra"
            close={setIsOpen}
            isOpen={isOpen}
            setEditUser={setEditUser}
            form={
              <ExternalUserModal
                reload={reload}
                setReloaad={setReload}
                close={setIsOpen}
                data={editUser.value ? editUser.data : null}
              />
            }
            title={editUser.value ? 'Update Client' : 'New Client'}
            height="500px"
          />
        </PeopleListContainer>
      )}
    </>
  );
};

export default PeopleList;
