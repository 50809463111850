import Button from 'app/components/atoms/button/button.component';
import Card from 'app/components/atoms/card/card.component';
import Table from 'app/components/molecules/table/table.component';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SettingsSelectors,
  SettingsActions,
} from 'store/reducers/settings/settingsSlice';
import { USER_ROLES } from 'store/reducers/auth/utils/userRoleConstant';
import { useRef } from 'react';
import styled from 'styled-components';
import Text, { Heading } from 'app/components/atoms/typography';
import { BsSearch } from 'react-icons/bs';
import { PlanCheckbox } from '../plans/plan.style';
import { useViewport } from 'hooks';
import { Flex } from '../dashboard/dashboard.styles';
import {
  getBooleanStatusName,
  getBooleanStatusStyle,
} from 'utils/helpers/createStatusStyle/createStatusStyle';
import ReactPaginate from 'react-paginate';
import { skipTake } from 'utils/helpers/skipTake/skipTake';

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) =>
    (justifyContent && 'flex-end') || 'flex-start'};
  align-items: ${({ alignItems }) =>
    (alignItems && 'flex-end') || 'flex-start'};
  gap: 20px;
  margin: ${({ margin }) => margin || '10px 0'};
  .week-active {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 800px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .year-picker {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px;
  }
  .admin {
    display: flex;
    gap: 10px;
    align-items: center;
    @media screen and (min-width: 800px) {
      flex-direction: row;
    }
  }
  @media screen and (min-width: 800px) {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }

  .search {
    border: 1px solid #dbe7ff;
    border-radius: 4px;
    background: #f6f6f6;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 8px 16px;
    input {
      border: 0;
      background-color: transparent;
      outline: none;
    }
  }
  .search.field {
    padding: 15px 16px;
  }
  .search.field.rounded {
    border-radius: 24px;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  @media screen and (min-width: 600px) {
    .btn-submit {
      align-self: flex-end;
      min-width: auto;
    }
  }
`;

export const ReceiptTableContainer = styled(TableContainer)`
  margin-left: 20px;
  margin-right: 20px;
`;

const ClientsTable = ({ setSelectClient, setSelectedRows, selectedRows }) => {
  const dispatch = useDispatch();
  const { mobile } = useViewport();
  const componentRef = useRef();

  const isLoading = useSelector(SettingsSelectors.isLoading);
  const allUsers = useSelector(SettingsSelectors.users);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);

  const totalCount = useSelector(SettingsSelectors.totalCount);

  const [selectedUsers, setSelectedUsers] = useState(selectedRows);
  const [searchInput, setSearchInput] = useState('');
  const { searchUsers } = SettingsActions;

  const handleRowSelected = useCallback((state) => {
    if (state.allSelected === true && state.selectedCount === 0) {
      return;
    }
    setSelectedUsers(state.selectedRows);
  }, []);

  const rowSelectCriteria = useCallback(
    (row) => {
      return selectedUsers.some((el) => el.id === row.id);
    },
    // eslint-disable-next-line
    [users]
  );

  const mobileRowSelectCriteria = useCallback(
    (row) => {
      return selectedUsers.some((el) => el.id === row.id);
    },
    // eslint-disable-next-line
    [selectedUsers]
  );

  const handleContinue = () => {
    setSelectClient(false);
    setSelectedRows(selectedUsers);
  };

  const handleMobileChecked = (state) => {
    let combinedArray = [];

    if (state.checked) {
      combinedArray = selectedUsers.concat([state.item]);
    } else {
      combinedArray = selectedUsers.filter((r) => r.id !== state.item.id);
    }

    const uniqueMap = new Map();

    combinedArray.forEach((item) => {
      if (!uniqueMap.has(item.id)) {
        uniqueMap.set(item.id, item);
      }
    });

    const uniqueArray = Array.from(uniqueMap.values());

    setSelectedUsers(uniqueArray);
  };

  const customSort = (a, b) => {
    // First, sort by isActive
    if (a.isActive && !b.isActive) {
      return -1;
    } else if (!a.isActive && b.isActive) {
      return 1;
    }

    // Then, sort selected users above non-selected users
    const aSelected = selectedUsers.some((user) => user.id === a.id);
    const bSelected = selectedUsers.some((user) => user.id === b.id);

    if (aSelected && !bSelected) {
      return -1;
    } else if (!aSelected && bSelected) {
      return 1;
    }

    // Finally, keep the original order for other cases
    return 0;
  };

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      role: USER_ROLES.CLIENT_USER,
    };

    dispatch(searchUsers(payload)).then((res) => {
      setUsers([...res?.payload?.data].sort(customSort));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUsers(
      allUsers
        .filter((user) =>
          user?.fullName
            ?.toLowerCase()
            .includes(searchInput.toLocaleLowerCase())
        )
        .sort(customSort)
    );

    // eslint-disable-next-line
  }, [searchInput, allUsers]);

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.fullName,

      sortable: true,
      grow: 2,
    },
    {
      name: 'Email address',
      selector: (row) => row.email,

      sortable: true,
      grow: 2,
    },
    {
      name: 'Phone number',
      selector: (row) => row.phoneNumber,
      sortable: true,
      grow: 2,
    },
  ];

  return (
    <TableContainer>
      <Card p="10px 0px 20px">
        <div ref={componentRef}>
          <TableHeader margin="10px 25px">
            <div>
              <Heading color=" var(--primary2)" level={2}>
                All Clients
              </Heading>
              <Text color="var(--grey2)">
                View all your client’s information here.
              </Text>
            </div>
            <div className="search">
              <BsSearch />
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          </TableHeader>

          {mobile ? (
            <div>
              {skipTake(users, page * pageSize, pageSize)?.map((item) => {
                return (
                  <div
                    style={{
                      borderBottom: '.5px solid var(--primary5)',
                      padding: '5px 25px 20px',
                    }}
                    key={item.id}
                  >
                    <PlanCheckbox marginTop="0">
                      <input
                        type="checkbox"
                        name=""
                        checked={mobileRowSelectCriteria(item)}
                        onChange={(event) => {
                          handleMobileChecked({
                            item,
                            checked: event.target.checked,
                          });
                        }}
                      />
                      <div style={{ width: '100%' }}>
                        <Flex>
                          <Heading size={14} color="var(--primary2)">
                            {item.fullName}
                          </Heading>
                          <div
                            style={{
                              color: getBooleanStatusStyle(item.isActive),
                            }}
                          >
                            {getBooleanStatusName(item.isActive)}
                          </div>
                        </Flex>
                        <Flex>
                          <Text size={12} color="var(--grey2)">
                            {item.email}
                          </Text>
                          <Text size={12} color="var(--grey2)">
                            {item.phoneNumber}
                          </Text>
                        </Flex>
                      </div>
                    </PlanCheckbox>
                  </div>
                );
              })}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 30,
                }}
              >
                <ReactPaginate
                  breakLabel="..."
                  previousLabel={'← Previous'}
                  nextLabel={'Next →'}
                  forcePage={page}
                  onPageChange={(e) => {
                    setPage(e.selected);
                  }}
                  pageRangeDisplayed={2}
                  pageCount={Math.ceil(totalCount / pageSize)}
                  renderOnZeroPageCount={null}
                  containerClassName={'pagination'}
                  previousLinkClassName={'pagination__link'}
                  nextLinkClassName={'pagination__link'}
                  disabledClassName={'pagination__link--disabled'}
                  activeClassName={'pagination__link--active'}
                />
              </div>
            </div>
          ) : (
            <Table
              columns={columns}
              data={users}
              onSelectedRowsChange={handleRowSelected}
              selectableRows
              selectableRowSelected={rowSelectCriteria}
              pagination
              progressPending={isLoading}
              actions={<></>}
              paginationTotalRows={totalCount}
              paginationPerPage={10}
            />
          )}
        </div>
      </Card>
      <Button
        width="fit-content"
        className="btn-submit"
        onClick={handleContinue}
      >
        Continue
      </Button>
    </TableContainer>
  );
};
export default ClientsTable;
