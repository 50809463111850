import React from 'react';
import { getBillStatusCount } from 'utils/helpers/getInvoiceStatusCount/getInvoiceStatusCount';
import Text from 'app/components/atoms/typography';
import { BILL_STATUS_DROPDOWN_DATA } from 'utils/constants/constants.utils';
import { SummaryLink } from 'app/components/atoms/divs/div.styles';
import { useState } from 'react';
import { Flex } from 'app/pages/dashboard/dashboard.styles';
import { useViewport } from 'hooks';

const BillsTabs = ({ paid, unpaid, totalCount, setStatusInput }) => {
  const [statusInput, setStatusLabelInput] = useState('All');
  const { mobile } = useViewport();

  const handleStatusClick = (status) => {
    const newStatusLabelInput = status === 'All' ? null : status.label;
    const newStatusInput = status === 'All' ? null : status.value;
    setStatusLabelInput(newStatusLabelInput);
    setStatusInput(newStatusInput);
  };

  return (
    <SummaryLink style={{ flexWrap: mobile ? 'wrap' : 'nowrap' }}>
      {BILL_STATUS_DROPDOWN_DATA.option?.map((status, index) => {
        let count = getBillStatusCount(status.label, unpaid, paid, totalCount);
        return (
          <div
            key={index}
            className={`${
              (statusInput === status.label && status.label) ||
              ((statusInput === '' || statusInput === 'Status') &&
                status.label === 'All')
                ? 'active'
                : 'inactive'
            }`}
            onClick={() => handleStatusClick(status)}
          >
            <Flex gap="5px" justifyContent="true">
              <Text size={16}>{status.label}</Text>
              <Text size={16}> ({count})</Text>
            </Flex>
          </div>
        );
      })}
    </SummaryLink>
  );
};

export default BillsTabs;
