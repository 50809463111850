import {
  FlexedDiv,
  InvoiceDetailsContent,
  TableTotalContainer,
} from 'app/components/atoms/divs/div.styles';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import {
  FlexReceiptButton,
  ReceiptFooter,
  ResponsiveInvertedButton,
} from 'app/pages/receipt/receipt.style';
import React from 'react';
import { Card } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import { convertCurrencyToSign } from 'utils/helpers/convertCurrencyToSign/convertCurrencyToSign';
import { setDecimalPlaces } from 'utils/helpers/setDecimalPlaces/setDecimalPlaces';

import tag from 'app/assets/svg/tag.svg';
import Button from 'app/components/atoms/button/button.component';

export class ComponentToPrint extends React.Component {
  render() {
    const { paymentDetails, handlePrint, currency, hideButton } = this.props;

    const beneficiaries =
      paymentDetails?.data?.lineItems ??
      paymentDetails?.data?.paymentBeneficiaryInformation;
    return (
      <InvoiceDetailsContent
        repeat={'4'}
        bgcolor={'#011747'}
        style={{ backgroundColor: 'white' }}
      >
        <Card className="card">
          <div className="card__header">
            <Heading size="48px" color="#f2f6ff" responsiveFontSize={24}>
              {paymentDetails?.data?.rrr ??
                paymentDetails?.data?.transactionReference}
            </Heading>
            <Text color="#B5CCFE" size="18px" responsiveFontSize={14}>
              Reference Number
            </Text>
          </div>
          <div className="card__body">
            {!hideButton && (
              <FlexReceiptButton>
                <FlexedDiv>
                  <img src={tag} alt="info" style={{ height: '20px' }} />
                  <Span color="#00C8F0">
                    Print bill to present at the bank and request to pay for
                    Remitta
                  </Span>
                </FlexedDiv>

                <div
                  className="invoice-details-download-button"
                  style={{ marginLeft: 'auto' }}
                  onClick={handlePrint}
                >
                  <ResponsiveInvertedButton
                    padding="0 5px"
                    mobilePadding="8px 7px"
                    onClick={handlePrint}
                  >
                    <FiDownload style={{ marginRight: '10px' }} />
                    <Span color="currentColor">Download Bill</Span>
                  </ResponsiveInvertedButton>
                </div>
              </FlexReceiptButton>
            )}
            <div className="flex">
              <div>
                <Text responsiveFontSize={14}>Invoice Number</Text>
                {beneficiaries?.map((beneficiary) => (
                  <Text
                    weight="500"
                    size="18px"
                    responsiveFontSize={14}
                    key={beneficiary.id}
                  >
                    {beneficiary.id}
                  </Text>
                ))}
              </div>
              <div className="right">
                <Text responsiveFontSize={14}>Pay to:</Text>
                <div>
                  {beneficiaries.map((beneficiary) => (
                    <Text
                      weight="500"
                      size="18px"
                      responsiveFontSize={14}
                      key={beneficiary.id}
                    >
                      {beneficiary.accountName}
                    </Text>
                  ))}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="grid__header proforma">
                <Text responsiveFontSize={12} weight="500">
                  Service
                </Text>
                <Text responsiveFontSize={12} weight="500">
                  Description
                </Text>
                <Text responsiveFontSize={12} weight="500">
                  Price
                </Text>
                <Text responsiveFontSize={12} weight="500">
                  Total
                </Text>
              </div>

              <div>
                {beneficiaries.map(
                  ({ id, accountName, amount, description }, key) => (
                    <div className="grid__body" key={key}>
                      <Text responsiveFontSize={12}>{id}</Text>
                      <Text responsiveFontSize={12}>{description}</Text>
                      <Text responsiveFontSize={12}>{accountName}</Text>
                      <Text responsiveFontSize={12}>
                        {addCommasToNumber(setDecimalPlaces(amount))}
                      </Text>
                    </div>
                  )
                )}
              </div>

              <TableTotalContainer
                style={{
                  fontWeight: '700',
                  fontSize: '20px',
                  borderTop: '1px solid #a4b0c9',
                }}
              >
                <div
                  style={{
                    marginLeft: 'auto',
                    width: '100%',
                    maxWidth: '510px',
                  }}
                >
                  <FlexedDiv
                    style={{
                      padding: '16px 10px',
                    }}
                  >
                    <Text size="16px" responsiveFontSize={12}>
                      Sub Total:
                    </Text>
                    <Text size="16px" responsiveFontSize={12}>
                      {convertCurrencyToSign(currency?.label)}
                      {addCommasToNumber(
                        setDecimalPlaces(
                          paymentDetails?.data?.totalAmount -
                            paymentDetails?.data?.serviceCharge
                        )
                      )}
                    </Text>
                  </FlexedDiv>
                  <FlexedDiv
                    style={{
                      padding: '16px 10px',
                    }}
                  >
                    <Text size="16px" responsiveFontSize={12}>
                      Service Charge:{' '}
                    </Text>
                    <Text size="16px" responsiveFontSize={12}>
                      {convertCurrencyToSign(currency?.label)}
                      {addCommasToNumber(
                        setDecimalPlaces(paymentDetails?.data?.serviceCharge)
                      )}
                    </Text>
                  </FlexedDiv>
                  <FlexedDiv
                    style={{
                      backgroundColor: 'var(--primary8)',
                      padding: '16px 10px',
                    }}
                  >
                    <Text size="20px" responsiveFontSize={12}>
                      Total
                    </Text>
                    <Text size="20px" responsiveFontSize={12}>
                      {convertCurrencyToSign(currency?.label)}
                      {addCommasToNumber(
                        setDecimalPlaces(paymentDetails?.data?.totalAmount)
                      )}
                    </Text>
                  </FlexedDiv>
                </div>
              </TableTotalContainer>
            </div>

            <Button
              padding="0 5px"
              mobilePadding="8px 7px"
              onClick={handlePrint}
              maxWidth={'257px'}
              margin="40px auto"
            >
              <Span color="currentColor">Print Bill</Span>
            </Button>
            <ReceiptFooter>
              Powered by Easei
              <img
                src="https://easeitestac8f.blob.core.windows.net/uploadblob-test/easeiInvoiceLogo.png"
                alt=""
                width="18"
                height="18"
                style={{ marginLeft: '13px' }}
              />
            </ReceiptFooter>
          </div>
        </Card>
      </InvoiceDetailsContent>
    );
  }
}
