import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useViewport } from 'hooks';
import { MobileResponsiveButton } from 'app/components/atoms/divs/div.styles';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import { InvoiceTopBanner } from './bills.styles';
import { ROUTES } from 'utils/constants/constants.utils';
import styled from 'styled-components';
import CurrenciesDropdown from 'app/components/molecules/currenciesDropDown/currencies-dropdown.component';
import DateFilterDropdown from 'app/components/molecules/datefilter/datefilter-dropdown.component';
import CircularProgressWithChildren from 'app/components/progress-bar/CircularProgressWithChildren';
import Sort from 'app/components/molecules/sort/sort.component';
import BillsList from 'app/components/cell/billsList/billsList';
import { billActions, billSelectors } from 'store/reducers/bills/billsSlice';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import { subDays } from 'date-fns';
import Button from 'app/components/atoms/button/button.component';

const MainContent = styled.div`
  padding: 24px 35px;
  & > div:not(:first-child) {
    margin-top: 24px;
  }
  .filters {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    @media screen and (max-width: 500px) {
      gap: 0;
    }
  }
`;

const BillsOverview = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  gap: 26px;
  @media screen and (max-width: 830px) {
    flex-direction: column;
    align-items: center;
  }
  .stats-container {
    background-color: white;
    border-radius: 8px;
    flex: 1;
    display: flex;
    gap: 40px;
    padding: 40px 24px;
    @media screen and (max-width: 500px) {
      gap: 10px;
      padding: 0 10px;
      margin-top: 20px;
    }
    .stat-item {
      flex: 1;
      &:not(:last-child) {
        border-right: 1px solid var(--grey3);
      }
      .stat-invoice {
        white-space: nowrap;
      }
    }
  }
  .status-container {
    background-color: white;
    border-radius: 8px;
    gap: 20px;
    padding: 16px 20px;
    min-height: 224px;
    display: flex;
    flex-direction: column;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 26px;
  margin-top: 40px;
  position: relative;
  z-index: 10000;
`;
const colors = {
  paid: { color: '#5CBC77', bg: '#5CBC774D' },
  unpaid: { color: '#BF4545', bg: '#BF45454D' },
  total: { color: '#7D7D7D', bg: '#7D7D7D33' },
};

const initialState = {
  searchInput: '',
  pageSize: 10,
  page: 1,
  invoicePaymentStatus: null,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
};
const Bills = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('');
  const [dateInput, setDateInput] = useState('');
  const {
    searchInput,
    pageSize,
    page,
    invoicePaymentStatus,
    startDate,
    endDate,
  } = state;
  const { searchBills, getBillingClients } = billActions;
  const isLoading = useSelector(billSelectors.isLoading);
  const bills = useSelector(billSelectors.bills);
  const totalCount = useSelector(billSelectors.totalBillCount);
  const billStats = useSelector(billSelectors.billStats);
  const billingClients = useSelector(billSelectors.billingClients);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { mobile } = useViewport();

  const handlePerRowsChange = async (newPageSize) => {
    setState((prevState) => ({ ...prevState, pageSize: newPageSize }));
  };

  const handlePageChange = (newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  };
  const handleCreate = () => {
    navigate(ROUTES.CREATE_BILLS);
  };
  const dispatchSearchBills = () => {
    dispatch(
      searchBills({
        page,
        pageSize,
        startDate,
        endDate,
        filter: searchInput,
        currency: selectedCurrency.label,
        invoicePaymentStatus,
      })
    );
  };

  useEffect(() => {
    if (selectedCurrency?.label) {
      dispatchSearchBills();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchInput,
    invoicePaymentStatus,
    startDate,
    selectedCurrency,
    endDate,
    page,
    pageSize,
  ]);
  useEffect(() => {
    dispatch(getBillingClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (dateInput && !dateInput.includes('undefined')) {
      const dates = dateInput.split(' - ');
      setState((s) => ({
        ...s,
        startDate: new Date(dates[0]).toLocaleString(),
        endDate: new Date(dates[1]).toLocaleString(),
      }));
    }
  }, [dateInput]);
  useEffect(() => {
    if (selectedTimeFilter) {
      const enddate = new Date();
      let startdate;
      if (selectedTimeFilter.label === 'Last 30 days') {
        startdate = subDays(new Date(), 30);
      } else if (selectedTimeFilter.label === 'Last week') {
        startdate = subDays(new Date(), 7);
      } else if (selectedTimeFilter.label === 'Today') {
        startdate = subDays(new Date(), 0);
      }

      if (startdate) {
        setState((s) => ({
          ...s,
          startDate: startdate,
          endDate: enddate,
        }));
        setDateInput(
          `${startdate?.toLocaleDateString()} - ${enddate?.toLocaleDateString()}`
        );
      }
    }
  }, [selectedTimeFilter]);
  return (
    <>
      <InvoiceTopBanner>
        <div className=" client-invoice__new">
          <div className="invoice_header">
            <Heading color="var(--primary2)" size={mobile ? 18 : 32}>
              Bills
            </Heading>
            <Text size={mobile ? 12 : 16}>
              Create bills and track their progress
            </Text>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="client-invoice__new_button">
              {mobile ? (
                <MobileResponsiveButton onClick={handleCreate}>
                  <Text color="#fff" weight={700}>
                    Create New bill
                  </Text>
                </MobileResponsiveButton>
              ) : (
                <Button onClick={handleCreate}>
                  <Text color="#fff" weight={700}>
                    Create New bill
                  </Text>
                </Button>
              )}
            </div>
            {mobile && (
              <CurrenciesDropdown
                selected={selectedCurrency}
                setSelected={setSelectedCurrency}
              />
            )}
          </div>
        </div>
      </InvoiceTopBanner>
      <MainContent>
        <BillsOverview>
          <div>
            <Text weight="700" color="var(--primary2)">
              Overview
            </Text>
          </div>
          <div className="filters">
            <DateFilterDropdown
              selected={selectedTimeFilter}
              setSelected={setSelectedTimeFilter}
            />
            {!mobile && (
              <CurrenciesDropdown
                selected={selectedCurrency}
                setSelected={setSelectedCurrency}
              />
            )}
          </div>
        </BillsOverview>
        <StatusContainer>
          <div className="stats-container">
            {billStats.map((stat) => (
              <div className="stat-item" key={stat.name}>
                <Text
                  color={colors[`${stat.name.toLowerCase()}`].color}
                  size={mobile && '12px'}
                >
                  {stat.name}
                </Text>
                <Text
                  weight="500"
                  size={mobile ? '16px' : '24px'}
                  color="#323232"
                >
                  {stat.value ? addCommasToNumber(stat.value) : '0'}
                </Text>
                <Text
                  padding="0 10px"
                  bg={colors[`${stat.name.toLowerCase()}`].bg}
                  display="inline-block"
                  size={mobile && '12px'}
                  radius="10px"
                  className="stat-invoice"
                >
                  {stat.invoices} Invoices
                </Text>
              </div>
            ))}
          </div>
          <div className="status-container ">
            <div className="filters">
              <Span>Bill Status</Span>
              <DateFilterDropdown
                selected={selectedTimeFilter}
                setSelected={setSelectedTimeFilter}
              />
            </div>
            <CircularProgressWithChildren
              values={{
                total: {
                  value: billStats.find((item) => item.name === 'TOTAL')
                    ?.invoices,
                  color: 'var(--primary1)',
                  label: 'Unpaid',
                },
                remaining: {
                  value: billStats.find((item) => item.name === 'UNPAID')
                    ?.invoices,
                  color: 'var(--grey3)',
                  label: 'Paid',
                },
              }}
              strokeWidth={12}
            >
              <Span size="21px" weight="bold" display="block">
                Unpaid
              </Span>
              <Span
                size="21px"
                weight="bold"
                display="block"
                bg="white"
                radius="6px"
                padding="0 6px"
              >
                {addCommasToNumber(
                  billStats.find((item) => item.name === 'UNPAID')?.value
                ) ?? '0'}
                {}
              </Span>
            </CircularProgressWithChildren>
          </div>
        </StatusContainer>
        <FiltersContainer>
          <Sort
            iconColor="#FEBA27"
            filter={'clients'}
            setSearchInput={(value) =>
              setState((prevState) => ({
                ...prevState,
                searchInput: value.fullName,
              }))
            }
            statusInput={''}
            items={billingClients}
            maxWidth="212px"
          />
          <Sort
            filter={'date-range'}
            dateRange={dateInput}
            setDateRange={setDateInput}
          />
        </FiltersContainer>
        <BillsList
          bills={bills}
          totalCount={totalCount}
          loading={isLoading}
          pageSize={pageSize}
          paginationServer
          statusInput={invoicePaymentStatus}
          setSearchInput={(value) =>
            setState((prevState) => ({
              ...prevState,
              searchInput: value,
            }))
          }
          setStatusInput={(value) =>
            setState((prevState) => ({
              ...prevState,
              invoicePaymentStatus: value,
            }))
          }
          paginationTotalRows={totalCount}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </MainContent>
    </>
  );
};

export default Bills;
