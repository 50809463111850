import easeiRequest, { easeiRequestTwo } from './requests';

const auth = {
  /**
   *
   * @param {{email: string, password: string}} data
   * @returns {AxiosResponse<{data: {id: string, roles: [], userName: string, companyName: string, token: string}, succeeded: boolean, message: string, statusCode: number}>}
   */
  login: (data) => easeiRequest.post('account/login', data),

  logout: () => easeiRequest.post('account/logout'),

  refreshToken: (data) => easeiRequest.post('account/refresh-token', data),

  /**
   *
   * @param {{access_token: string}} data
   * @returns {AxiosResponse<{data: {id: string, roles: [], userName: string, companyName: string, token: string}, succeeded: boolean, message: string, statusCode: number}>}
   */
  googleLogin: (data) => easeiRequest.post('account/google-login', data),

  /**
   *
   * @param {{accessToken: string}} data
   * @returns {AxiosResponse<{data: {id: string, roles: [], userName: string, companyName: string, token: string}, succeeded: boolean, message: string, statusCode: number}>}
   */
  facebookLogin: (data) => easeiRequest.post('account/facebook-login', data),

  /**
   *
   * @param {{accessToken: string, rawIdToken: string}} data
   * @returns {AxiosResponse<{data: {id: string, roles: [], userName: string, companyName: string, token: string}, succeeded: boolean, message: string, statusCode: number}>}
   */
  microsoftLogin: (data) => easeiRequest.post('account/microsoft-login', data),

  /**
   *
   * @param {{industry: string, businessName: string, businessEmail: string, businessPhoneNumber: string, organizationSector: string, password: string, planId: number}} data
   * @returns {AxiosResponse<{{data: string, succeeded: boolean, message: string, errors: [string]}}}
   */
  organizationSignup: (data) =>
    easeiRequest.post('account/organization-signup', data),

  /**
   *
   * @param {{firstName: string, lastName: string, email: string, phonenUmber: string, password: string, planId: number}} data
   * @returns {AxiosResponse<{{data: string, succeeded: boolean, message: string, errors: [string]}}}
   */
  clientUserSignup: (data) =>
    easeiRequest.post('account/client-user-signup', data),

  /**
   *
   * @param {string} email
   * @returns {AxiosResponse<{{data: string, succeeded: boolean, message: string, errors: [string]}}}
   */
  forgotPassword: (email) =>
    easeiRequest.post('account/forgot-password', email),

  /**
   *
   * @param {{token: string, email: string, newPassword: string, confirmPassword: string}} body
   * @returns {AxiosResponse<{{data: string, succeeded: boolean, message: string, errors: [string]}}}
   */
  resetPassword: (body) => easeiRequest.post('account/reset-password', body),

  /**
   *
   * @param {{token: string, email: string, confirmPassword: string, Password: string, firstName: string, lastName: string}} body
   * @returns {AxiosResponse<{{data: string, succeeded: boolean, message: string, errors: [string]}}}
   */
  confirmInvite: (body) => easeiRequest.post('account/confirm-invite', body),

  /**
   *
   * @param {{token: string, email: string}} body
   * @returns {AxiosResponse<{{data: string, succeeded: boolean, message: string, errors: [string]}}}
   */
  confirmEmail: (body) => easeiRequest.post('account/confirm-email', body),

  /**
   *
   * @param {{CurrentPassword: string, newPassword: string, confirmPassword: string, email: string}} body
   * @returns {AxiosResponse<{{data: string, succeeded: boolean, message: string, errors: [string]}}}
   */
  changePassword: (body) => easeiRequest.post('account/change-password', body),
};

const plan = {
  /**
   *
   * @param {number} planType
   * @returns {AxiosResponse< {data: [{id: number,name: string,description: string, cost: number, planType: number, planTypeName: string, features: [{id: number,description: string}]}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  getPlan: () => easeiRequest.get(`plan`),
  myPlan: () => easeiRequest.get(`plan/my-plan`),
  createPlan: (input) => easeiRequest.post(`Plan`, input),
  updatePlan: (input) => easeiRequest.put(`Plan`, input),
  makePlanPayment: (input) => easeiRequest.post(`Plan/payment`, input),
  verifyPayment: (reference) =>
    easeiRequest.get(`Plan/verify-payment`, {
      params: {
        reference,
      },
    }),
};

const feature = {
  getFeature: () => easeiRequest.get(`feature`),
  createFeature: (input) => easeiRequest.post(`feature`, input),
  updateFeature: (input) => easeiRequest.put(`feature`, input),
};

const notification = {
  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @returns {AxiosResponse< {data: [{id: string, details: string, isRead: boolean, notificationAction: number, extraDetails: string, notificationActionName: string}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  getNotifications: (pageSize, page) =>
    easeiRequest.get(`notification`, {
      params: {
        page,
        pageSize,
      },
    }),

  updateNotification: (ids) =>
    easeiRequest.patch(`notification`, {
      notificationIds: ids,
    }),
};

const chat = {
  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @param {string} invoiceNumber
   * @returns {AxiosResponse< {data: [{id: string, invoiceNumber: string, message: string, fileUrl: string, createdBy: string, createdOn: string,updatedOn:string,profilePicture:string}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  getChatRoom: (invoiceNumber, pageSize, page) =>
    easeiRequest.get(`chat/search-by-invoice-number`, {
      params: {
        invoiceNumber,
        pageSize,
        page,
      },
    }),
  /**
   *
   * @param {string} invoiceNumber
   * @param {string} message
   * @param {string} fileUrl
   * @returns {AxiosResponse< {data: [{id: string, invoiceNumber: string, message: string, fileUrl: string, createdBy: string, createdOn: string,updatedOn:string,profilePicture:string}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  postChatMessage: (input) => easeiRequest.post(`chat`, input),
};

const invoice = {
  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @returns {AxiosResponse< {data: [{id: string, details: string, isRead: boolean, notificationAction: number, extraDetails: string, notificationActionName: string}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  search: (payload) =>
    easeiRequest.get(`invoice/search`, {
      params: payload,
    }),

  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @returns {AxiosResponse< {data: [{id: string, details: string, isRead: boolean, notificationAction: number, extraDetails: string, notificationActionName: string}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  advanceSearch: (
    page,
    pageSize,
    status,
    startDate,
    endDate,
    client,
    organizationId
  ) =>
    easeiRequest.get(`invoice/search`, {
      params: {
        page,
        pageSize,
        status,
        startDate,
        endDate,
        client,
        organizationId,
      },
    }),

  getById: (id) => easeiRequest.get(`invoice/invoice-id?invoiceId=${id}`),
  updateStatus: (input) => easeiRequest.patch(`invoice/update-status`, input),
  createExternal: (input) => easeiRequest.post(`invoice/external`, input),
  createInternal: (input) => easeiRequest.post(`invoice/internal`, input),
  update: (input) => easeiRequest.patch(`invoice`, input),
  generatePaymentReference: (input) =>
    easeiRequest.post(`invoice/generatePaymentReference`, input),
};

const bill = {
  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @returns {AxiosResponse< {data: [{id: string, details: string, isRead: boolean, notificationAction: number, extraDetails: string, notificationActionName: string}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  search: (payload) =>
    easeiRequest.get(`bill/search`, {
      params: payload,
    }),

  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @returns {AxiosResponse< {data: [{id: string, details: string, isRead: boolean, notificationAction: number, extraDetails: string, notificationActionName: string}],pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  advanceSearch: (
    page,
    pageSize,
    status,
    startDate,
    endDate,
    client,
    organizationId
  ) =>
    easeiRequest.get(`bill/search`, {
      params: {
        page,
        pageSize,
        status,
        startDate,
        endDate,
        client,
        organizationId,
      },
    }),

  getById: (id) => easeiRequest.get(`bill/bill-id?billId=${id}`),
  getBillingClients: (id) => easeiRequest.get('billingClient'),
  updateStatus: (input) => easeiRequest.post(`bill/update-status`, input),
  create: (input) => easeiRequest.post(`bill`, input),
};
const dashboard = {
  /**
   *
   * @param {number} day
   * @param {number} year
   * @param {number} month
   * @returns {AxiosResponse< {
   * "total": 0,
   *"totalChangeRequests": 0,
   *"totalApproved": 0,
   *"totalDeclined": 0,
   *"totalPending": 0,
   *"lastFiveInvoices": [
   * {
   *  "id": "string",
   * "invoiceNo": "string",
   *"userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
   *"billTo": "string",
   *"from": "string",
   *"currency": "string",
   *"organizationId": 0,
   *"status": 0,
   *"statusName": "string",
   *"type": 0,
   *"typeName": "string",
   *"invoicePaymentStatus": 0,
   *"invoicePaymentStatusName": "string",
   *"date": "2023-04-18T22:57:10.522Z",
   *"subject": "string",
   *"totalAmount": 0,
   *"totalTax": 0,
   *"services": [
   *  {
   *   "id": "string",
   *  "serviceName": "string",
   * "description": "string",
   *"unitType": 0,
   *"itemUnit": "string",
   *"charge": 0,
   *"tax": 0
   *}
   *],
   *"bankName": "string",
   *"accountName": "string",
   *"accountNumber": "string",
   *"additionalInformation": "string",
   *"uploadedFileURL": "string",
   *"comment": "string",
   *"isActive": true,
   *"invoiceFinancing": true
   * }
   *],
   *"timeData": {
   *  "timeType": "string",
   * "data": [
   *  {
   *   "timeKey": 0,
   *  "totalPending": 0,
   * "totalApproved": 0,
   * "totalDeclined": 0
   *}
   *]
   *}
   *}}
   */
  clientDashboard: (day, month, year, weekly, currency) =>
    easeiRequestTwo.get(`dashboard/client-dashboard`, {
      params: {
        day,
        month,
        year,
        weekly,
        currency,
      },
    }),
  orgDashboard: (day, month, year, weekly, currency) =>
    easeiRequestTwo.get(`dashboard/organization-dashboard`, {
      params: {
        day,
        month,
        year,
        weekly,
        currency,
      },
    }),
  /**
   *
   * @param {number} day
   * @param {number} year
   * @param {number} month
   * @param {number} topOrganization
   * @param {number} topClient
   * @returns {AxiosResponse< {
   *  "clientUsersInfo": {
   *"freelancers": 0,
   *"contractors": 0
   *},
   *  "topOrBottomFiveOrganizations": [
   * {
   *  "name": "string",
   * "dateRegistered": "2023-04-27T18:34:41.316Z",
   *"clientCount": 0,
   *"invoiceCount": 0
   *}
   *],
   *  "topOrBottomFiveClients": [
   * {
   *  "name": "string",
   * "dateRegistered": "2023-04-27T18:34:41.316Z",
   * "phoneNumber": "string",
   * "invoiceCount": 0
   *}
   *],
   *"graphData": [
   * {
   *  "organizations": 0,
   * "clients": 0
   *}
   *],
   * "invoiceMetrics": [
   * {
   *   "count": 0,
   *   "percentage": 0,
   *   "metric": 0,
   *   "name": "string"
   * }
   *],
   *  "organizationMetrics": [
   * {
   *   "count": 0,
   *   "percentage": 0,
   *   "metric": 0,
   *   "name": "string"
   * }
   *]
   *}}
   */
  easeiDashboard: (day, month, year, topOrganization, topClient) =>
    easeiRequest.get(`dashboard/dashboard-admin-details`, {
      params: {
        day,
        month,
        year,
        topOrganization,
        topClient,
      },
    }),
};
const recurringInvoice = {
  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @returns {AxiosResponse< {data: [
   * {id: string, from: string, status: boolean, billTo : string, externalUserId: number,
   * currency: string, totalAmount: number, totalTax: number, organizationId: number,
   * comment: string, uploadedFileURL: string, additionalInformation: string, accountNumber: string
   * accountName: string, bankName: string, services: recurType: string, createdAt: Date, isActive: boolean,
   * [{ id: string, serviceName: string, description: string, unitType: 0, itemUnit: string, charge: number}]
   * }],
   * pageSize: number, page: number, totalCount: number, pageCount: number }}
   */
  search: (
    page,
    pageSize,
    status,
    startDate,
    endDate,
    client,
    recurType,
    organizationId
  ) =>
    easeiRequest.get(`RecurringInvoice/search`, {
      params: {
        page,
        pageSize,
        status,
        startDate,
        endDate,
        client,
        organizationId,
        recurType,
      },
    }),

  updateStatus: (id, status) =>
    easeiRequest.patch(`RecurringInvoice/update-status`, {
      id,
      status,
    }),

  getById: (id) => easeiRequest.get(`RecurringInvoice/id?id=${id}`),

  createExternal: (input) =>
    easeiRequest.post(`RecurringInvoice/external`, input),

  createInternal: (input) =>
    easeiRequest.post(`RecurringInvoice/internal`, input),

  update: (input) => easeiRequest.patch(`RecurringInvoice`, input),
};

const merchant = {
  search: (page, pageSize, status, name) =>
    easeiRequest.get(`merchant`, {
      params: {
        page,
        pageSize,
        status,
        name,
      },
    }),

  updateStatus: (id, status) =>
    easeiRequest.patch(`merchant/update-status`, {
      id,
      status,
    }),

  create: (input) => easeiRequest.post(`merchant`, input),
  update: (input) => easeiRequest.put(`merchant`, input),
};

const merchantConfiguration = {
  search: (page, pageSize, status, minimumAmount, maximumAmount, merchantId) =>
    easeiRequest.get(`merchantConfiguration`, {
      params: {
        page,
        pageSize,
        status,
        minimumAmount,
        maximumAmount,
        merchantId,
      },
    }),

  updateStatus: (id, status) =>
    easeiRequest.patch(`merchantConfiguration/update-status`, {
      id,
      status,
    }),

  create: (input) => easeiRequest.post(`merchantConfiguration`, input),
  update: (input) => easeiRequest.put(`merchantConfiguration`, input),
};

const settings = {
  search: (page, pageSize, role, filter) =>
    easeiRequest.get(`Settings/SearchUsers`, {
      params: {
        page,
        pageSize,
        role,
        filter,
      },
    }),
  getFeatureTypes: () => easeiRequest.get(`Settings/PlanFeature`),

  onboardMerchantAdmin: (input) =>
    easeiRequest.post(`settings/onboard-merchant-admin`, input),

  getOrganizationDetails: () =>
    easeiRequest.get(`settings/get-organization-details`),

  getUserDetails: () => easeiRequest.get(`settings/user-details`),

  getAccountDetails: () => easeiRequest.get(`settings/account-details`),

  updateOrganizationDetails: (input) =>
    easeiRequest.put(`settings/update-organization-details`, input),

  updateUserDetails: (input) =>
    easeiRequest.put(`settings/update-user-details`, input),

  updateAccountDetails: (input) =>
    easeiRequest.post(`settings/account-details-setup`, input),

  getBankList: () => easeiRequest.get(`settings/getBanks`),

  uploadFile: (file) => {
    let formData = new FormData();
    formData.append('File', file);
    return easeiRequest.post('settings/upload-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateProfilePicture: (file) => {
    let formData = new FormData();
    formData.append('Image', file);
    return easeiRequest.put('settings/update-image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  inviteUsers: (input) => easeiRequest.post(`settings/invite-users`, input),

  uploadUsersCsv: (input) => {
    return easeiRequest.post(`settings/upload-users-csv`, input, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  userActivation: (input) =>
    easeiRequest.post(`settings/user-activation`, input),

  closeAccount: (input) =>
    easeiRequest.post(`settings/initiate-account-closure`, input),
};

const otp = {
  verifyOTP: (input) =>
    easeiRequest.patch(`otp/source:int`, input.token, {
      params: {
        source: input?.source,
      },
    }),
};

const organization = {
  search: (
    page,
    pageSize,
    isActive,
    name,
    planId,
    merchantId,
    invoiceFinancing,
    activePlan
  ) =>
    easeiRequest.get(`Organization/search`, {
      params: {
        page,
        pageSize,
        isActive,
        name,
        planId,
        merchantId,
        invoiceFinancing,
        activePlan,
      },
    }),

  getAll: () => easeiRequest.get(`organization/get-all`),
  getById: (id) => easeiRequest.get(`organization/${id}`),
  updatePartnership: (input) =>
    easeiRequest.patch(`Organization/update-invoice-financing`, input),
};

const charge = {
  getAll: (input) => easeiRequest.get(`charge/paginated`, { params: input }),
  getUnitTypes: () => easeiRequest.get(`charge/unitTypes`),
  createCharge: (input) => easeiRequest.post(`charge`, input),
  editCharge: (input) => easeiRequest.put(`charge/${input.id}`, input),
};

const currency = {
  getAll: () => easeiRequest.get(`currency`),
};

const payment = {
  transactions: (input) =>
    easeiRequest.get(`payment/transactions`, { params: input }),
  transactionsDetails: (input) =>
    easeiRequest.get(`payment/retrieve-transaction-details`, { params: input }),
  confirmTransactionsDetails: (input) =>
    easeiRequest.patch(`payment/confirm-transaction-payment`, input),
  transactionRef: (input) =>
    easeiRequest.get(`payment/transactionRef`, { params: input }),
  initiateInvoicePayment: (input) =>
    easeiRequest.post(`payment/initiateInvoicePayment`, input),
  UpdateInvoicePaymentStatus: (input) =>
    easeiRequest.post(`payment/UpdateInvoicePaymentStatus`, input),
};

const support = {
  contactUs: (input) => easeiRequest.post(`support/contact-us`, input),
  gdpr: (input) => easeiRequest.post(`support/gdpr`, input),
};

const receipt = {
  search: (
    page,
    pageSize,
    status,
    startDate,
    endDate,
    filter,
    organizationId,
    receiptType
  ) =>
    easeiRequest.get(`receipt`, {
      params: {
        page,
        pageSize,
        status,
        startDate,
        endDate,
        filter,
        receiptType,
        organizationId,
      },
    }),
  getById: ({ id, receiptType }) =>
    easeiRequest.get(`receipt/id?Id=${id}&receiptType=${receiptType}`),
};

const externalUser = {
  search: (page, pageSize, filter) =>
    easeiRequest.get(`externalUser/paginated`, {
      params: {
        page,
        pageSize,
        filter,
      },
    }),
  getExternalUsers: () => easeiRequest.get(`externalUser`),
  create: (input) => easeiRequest.post(`externalUser`, input),
  update: (input) => easeiRequest.put(`externalUser`, input),

  getPaginatedExternalUsers: (page, pageSize, filter) =>
    easeiRequest.get(`externalUser/paginated`, {
      params: {
        page,
        pageSize,

        filter,
      },
    }),
  delete: (input) => easeiRequest.delete(`externalUser/${input}`),
};

const invoiceReminder = {
  search: (startDate, endDate) =>
    easeiRequest.get(`invoiceReminder/Search`, {
      params: {
        startDate,
        endDate,
      },
    }),

  updateStatus: (id, status) =>
    easeiRequest.patch(`invoiceReminder/update-status`, {
      id,
      status,
    }),

  create: (input) => easeiRequest.post(`invoiceReminder/create`, input),
  update: (input) => easeiRequest.put(`invoiceReminder/update`, input),
};

export const api = {
  auth,
  plan,
  feature,
  notification,
  invoice,
  recurringInvoice,
  invoiceReminder,
  merchant,
  merchantConfiguration,
  settings,
  charge,
  currency,
  organization,
  externalUser,
  support,
  chat,
  otp,
  dashboard,
  payment,
  receipt,
  bill,
};
