import Dropdown from 'app/components/atoms/dropdown/dropdown.component';
import { Span } from 'app/components/atoms/typography';
import { useOnClickOutside } from 'hooks';
import { useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { DropdownSelectContainer } from '../currenciesDropDown/currencies-dropdown.component';

const DateFilterDropdown = ({ selected, setSelected }) => {
  const { visible, setVisible, ref } = useOnClickOutside(false);
  useEffect(() => {
    if (!selected) {
      setSelected(dateOptions[0]);
    }
  }, [selected]);
  const handleClick = (item) => {
    setSelected(item);
    setVisible(false);
  };

  return (
    <DropdownSelectContainer ref={ref}>
      <div className="selected-item" onClick={() => setVisible(!visible)}>
        {selected && <Span display="inline">{selected.label}</Span>}

        <FaAngleDown size={16} className="icon" />
      </div>
      {visible && <Dropdown options={dateOptions} callback={handleClick} />}
    </DropdownSelectContainer>
  );
};

export default DateFilterDropdown;
const dateOptions = [
  { label: 'Last 30 days' },
  { label: 'Last week' },
  { label: 'Today' },
];
