import { useState } from 'react';
import Table from 'app/components/molecules/table/table.component';
import Card from 'app/components/atoms/card/card.component';
import Text, { Heading } from 'app/components/atoms/typography';
import { useCheckAdmin, useViewport } from 'hooks';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { TableHeader } from 'app/pages/reminder/clientsTable';
import { invoiceActions } from 'store/reducers/invoice/invoiceSlice';
import ActionMenu from 'app/components/molecules/action-menu/action-menu.component';
import BillsTabs from './BillTabs';
import BillsMobileView from './billsMobileView';
import { billSelectors } from 'store/reducers/bills/billsSlice';
import { useSelector } from 'react-redux';
import { BsSearch } from 'react-icons/bs';
import { FlexedDiv } from 'app/components/atoms/divs/div.styles';

const BillsList = ({
  bills,
  totalCount,
  loading,
  statusInput,
  setStatusInput,
  tableHeader = null,
  pageSize,
  page,
  columnsOrg,
  onChangePage,
  ...rest
}) => {
  const { mobile } = useViewport();
  const { role } = useCheckAdmin();
  const { advanceSearch } = invoiceActions;

  const [searchTerm, setSearchTerm] = useState('');

  const paidCount = useSelector(billSelectors.paidBillCount);
  const unPaidCount = useSelector(billSelectors.unpaidBillCount);

  const columns = (columnsOrg && Object.create(columnsOrg)) || [
    {
      name: 'Ref No',
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: 'Client name',
      selector: (row) => row.to,
      grow: 1.5,
    },
    {
      name: 'Payment status',
      selector: (row) => row.invoicePaymentStatusName,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => row.createdDate,
      format: (row) => new Date(row.createdDate).toLocaleDateString(),
      sortable: true,
    },
  ];

  const basicMenu = (row) => (
    <ActionMenu
      popupList={['View', 'Download']}
      row={row}
      downloadFileName={row.invoiceNo}
      downloadLink={row.invoicePdfUrl}
    />
  );

  if (
    role === USER_ROLES.ORGANIZATION_ADMIN ||
    role === USER_ROLES.ORGANIZATION_USER
  ) {
    columns.push({
      name: 'Action',
      selector: (row) => basicMenu(row),
      button: true,
    });
  }

  return (
    <Card>
      <div>
        {tableHeader ? (
          tableHeader
        ) : (
          <TableHeader
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0,
            }}
          >
            <FlexedDiv width="100%" flexDirection={mobile ? 'column' : 'row'}>
              <div style={{ borderBottom: '1px solid #F2F6FF', width: '100%' }}>
                <Heading color=" var(--primary2)" size={mobile ? 12.24 : 24}>
                  Bills
                </Heading>
                <Text color="var(--grey2)" size={mobile ? 10 : 16} margin="0">
                  Monitor the progress of your Bills
                </Text>
              </div>

              <div className="search field rounded">
                <BsSearch onClick={() => rest?.setSearchInput(searchTerm)} />
                <input
                  type="text"
                  style={{ outline: 'none' }}
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </FlexedDiv>
            <BillsTabs
              paid={paidCount}
              unpaid={unPaidCount}
              totalCount={totalCount}
              statusInput={statusInput}
              setStatusInput={setStatusInput}
              advanceSearch={advanceSearch}
            />
          </TableHeader>
        )}
        {mobile ? (
          <BillsMobileView
            invoices={bills}
            pageCount={Math.ceil(totalCount / pageSize)}
            page={page}
            loading={loading}
            onChangePage={onChangePage}
          />
        ) : (
          <Table
            columns={columns}
            data={bills}
            onChangePage={onChangePage}
            pagination
            progressPending={loading}
            actions={<></>}
            paginationServer
            paginationTotalRows={totalCount}
            {...rest}
          />
        )}
      </div>
    </Card>
  );
};

export default BillsList;
