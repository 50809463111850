import {
  BaseButton,
  InvertedButton,
  NoStyleButton,
} from 'app/components/atoms/button/button.styles';
import {
  FlexedDiv,
  TableTotalContainer,
} from 'app/components/atoms/divs/div.styles';
import { InvoiceBody } from 'app/components/atoms/generalInvoiceStyles/general.sytlyes';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import Table from 'app/components/molecules/table/table.component';
import { useViewport } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { RiArrowLeftSFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';
import {
  invoiceActions,
  invoiceSelectors,
} from 'store/reducers/invoice/invoiceSlice';
import { isEmpty, uniqBy, concat } from 'lodash';
import Button from 'app/components/atoms/button/button.component';
import { CurrencySelectors } from 'store/reducers/currency/currencySlice';
import AddInvoiceNumber from './AddInvoiceNumber';
import Card from 'app/components/atoms/card/card.component';
import { convertCurrencyToSign } from 'utils/helpers/convertCurrencyToSign/convertCurrencyToSign';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import { setDecimalPlaces } from 'utils/helpers/setDecimalPlaces/setDecimalPlaces';
import CustomModal from 'app/components/atoms/modal/modal.component';
import { Flex } from 'app/pages/dashboard/dashboard.styles';
import {
  ResponsiveBaseButton,
  ResponsiveInvertedButton,
} from 'app/pages/receipt/receipt.style';
import ReactModal from 'react-modal';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import { unwrapResult } from '@reduxjs/toolkit';
import { extractInvoicesAndReferences } from 'utils/helpers/extractInvoicesAndReference';
import {
  paymentActions,
  paymentSelectors,
} from 'store/reducers/payment/paymentSlice';

const GeneratePaymentReference = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [invoiceView, setInvoiceView] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [existingRefs, setExistingRefs] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { mobile } = useViewport();
  const componentRef = useRef(null);

  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState(null);

  const selectedInvoices = useSelector(invoiceSelectors.selectedInvoices);
  const paymentDetails = useSelector(invoiceSelectors.paymentDetails);
  const isLoading = useSelector(invoiceSelectors.isLoading);
  const currencies = useSelector(CurrencySelectors.currencies);
  const paymentLoading = useSelector(paymentSelectors.isLoading);
  const transaction = useSelector(paymentSelectors.transaction);

  const { generatePaymentReference } = invoiceActions;
  const { getTransactionByReference } = paymentActions;

  const columns = [
    {
      name: 'Invoice No',
      selector: (row) => row.invoiceNo,
    },
    {
      name: 'Client Name',
      selector: (row) => row.client,
    },
    {
      name: 'Price',
      selector: (row) => row.amount,
      format: (row) =>
        row.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
    },
  ];

  useEffect(() => {
    const invoices = isEmpty(location.state)
      ? selectedInvoices
      : location.state;
    if (!isEmpty(invoices)) {
      setCurrency(
        currencies.filter(function (item) {
          return item.label === invoices[0]?.currency;
        })[0] || null
      );

      setData(invoices);
    }
  }, [selectedInvoices, currencies]);

  const handleClick = (val) => {
    const newArray = uniqBy(concat(data, val), 'invoiceNo');
    setData(newArray);
  };
  const getRemita = (rrr = false) => {
    if (rrr && !isLoading) {
      setVisible(true);
    }

    dispatch(
      generatePaymentReference({
        invoices: data?.map((item) => item.id),
        generateRRR: rrr,
      })
    )
      .then(unwrapResult)
      .catch((error) => {
        if (error?.message?.includes('already have payments initiated')) {
          const invoicesAndRefs = extractInvoicesAndReferences(error?.message);
          setExistingRefs(invoicesAndRefs);
          setVisible(true);
        }
      })
      .finally(() => {
        if (rrr) {
          window.history.replaceState({}, '');
        }
      });
  };
  useEffect(() => {
    getRemita();
  }, [data]);

  const onBeforeGetContentResolve = React.useRef(null);

  const handleAfterPrint = React.useCallback(() => {
    setHideButton(false);
    setInvoiceView(false);
  }, []);

  const handlePayInBank = (ref) => {
    dispatch(getTransactionByReference({ transactionReference: ref })).then(
      () => {
        setTimeout(() => {
          setVisible(false);
          setInvoiceView(true);
        }, 500);
      }
    );
  };

  const handleOnBeforeGetContent = React.useCallback(() => {
    setHideButton(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, [setHideButton]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'AwesomeFileName',
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <section style={{ padding: '20px' }}>
      <FlexedDiv>
        <Heading color=" var(--primary2)" size={mobile ? 12.24 : 24}>
          Proforma Invoice
        </Heading>

        <BaseButton width="true" onClick={() => navigate(-1)}>
          <RiArrowLeftSFill color="white" /> <Text color="white">Back</Text>
        </BaseButton>
      </FlexedDiv>
      <Card style={{ marginTop: '50px' }}>
        <InvoiceBody>
          <Table
            title="Invoice Details"
            className="table"
            columns={columns}
            data={data}
            persistTableHead
            contextActions={<></>}
            actions={<></>}
          />
          <div className="details">
            <AddInvoiceNumber onClick={handleClick} />
          </div>

          <TableTotalContainer
            style={{
              fontWeight: '700',
              fontSize: '20px',
              borderTop: '1px solid #a4b0c9',
            }}
          >
            <div
              style={{
                marginLeft: 'auto',
                width: '100%',
                maxWidth: '510px',
              }}
            >
              <FlexedDiv
                style={{
                  padding: '16px 10px',
                }}
              >
                <Text size="16px" responsiveFontSize={12}>
                  Sub Total:
                </Text>
                <Text size="16px" responsiveFontSize={12}>
                  {convertCurrencyToSign(currency?.label)}
                  {addCommasToNumber(
                    setDecimalPlaces(
                      paymentDetails?.data?.totalAmount -
                        paymentDetails?.data?.serviceCharge
                    )
                  )}
                </Text>
              </FlexedDiv>
              <FlexedDiv
                style={{
                  padding: '16px 10px',
                }}
              >
                <Text size="16px" responsiveFontSize={12}>
                  Service Charge:{' '}
                </Text>
                <Text size="16px" responsiveFontSize={12}>
                  {convertCurrencyToSign(currency?.label)}
                  {addCommasToNumber(
                    setDecimalPlaces(paymentDetails?.data?.serviceCharge)
                  )}
                </Text>
              </FlexedDiv>
              <FlexedDiv
                style={{
                  backgroundColor: 'var(--primary8)',
                  padding: '16px 10px',
                }}
              >
                <Text size="20px" responsiveFontSize={12}>
                  Total
                </Text>
                <Text size="20px" responsiveFontSize={12}>
                  {convertCurrencyToSign(currency?.label)}
                  {addCommasToNumber(
                    setDecimalPlaces(paymentDetails?.data?.totalAmount)
                  )}
                </Text>
              </FlexedDiv>
            </div>
          </TableTotalContainer>
        </InvoiceBody>
        <FlexedDiv
          justifyContent={'center'}
          flexDirection={mobile ? 'column' : ''}
          style={{
            maxWidth: '200px',
            gap: '10px',
            marginTop: '20px',
            marginInline: 'auto',
          }}
        >
          <InvertedButton className="cancel" onClick={() => navigate(-1)}>
            Cancel
          </InvertedButton>
          <div style={{ flex: data.length > 0 ? 1 : 3 }}></div>
          {data.length > 0 && (
            <Button
              onClick={() => {
                getRemita(true);
              }}
              isLoading={isLoading}
            >
              Create
            </Button>
          )}
        </FlexedDiv>
      </Card>
      <CustomModal
        isOpen={visible && isArray(existingRefs) && existingRefs?.length > 0}
        close={setVisible}
        className="modal modal-del overflow"
      >
        <Flex justifyContent="center" flexDirection="column" margin="40px 0">
          <Heading responsiveFontSize={18} level={2}>
            Refrences Already created!
          </Heading>
          <table>
            <thead>
              <tr>
                <th>Invoices</th>
                <th>Refrence</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {existingRefs.map((item) => (
                <tr
                  key={item.reference}
                  style={{ borderTop: '1px solid black', paddingBlock: '5px' }}
                >
                  <td style={{ paddingRight: '5px' }}>
                    {item.invoices?.map((inv) => (
                      <Span>{inv}</Span>
                    ))}
                  </td>
                  <td style={{ paddingRight: '5px' }}>{item.reference}</td>
                  <td style={{ paddingBlock: '5px' }}>
                    <Flex gap="8px">
                      <Button
                        padding={'0 !important'}
                        minWidth={'0'}
                        fontSize="13px"
                        height="40px"
                        buttonType="inverted"
                        responsiveFontSize=".6rem"
                        isLoading={paymentLoading}
                        onClick={() => {
                          handlePayInBank(item.reference);
                        }}
                      >
                        Pay in Bank
                      </Button>
                      <ResponsiveBaseButton
                        padding={'2px !important'}
                        minWidth={'0'}
                        fontSize="13px"
                        height="40px"
                        responsiveFontSize=".6rem"
                        isLoading={paymentLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${window.location.origin}/remitapay/${item.reference}`,
                            '_blank'
                          );
                          navigate(-1);
                        }}
                      >
                        Pay Online
                      </ResponsiveBaseButton>
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={
          visible &&
          Boolean(paymentDetails?.data?.paymentLink) &&
          existingRefs?.length <= 0
        }
        close={setVisible}
        className="modal modal-del overflow"
      >
        <Flex justifyContent="center" flexDirection="column" margin="40px 0">
          <Heading responsiveFontSize={18} level={2}>
            RRR generated!
          </Heading>
          <Text responsiveFontSize={12}>
            Your Remitta number has been generated. Kindly choose a payment
            method below to continue.
          </Text>
          <Text responsiveFontSize={12}>
            RRR Number -
            <span style={{ fontWeight: 'bolder' }}>
              {paymentDetails?.data?.rrr}
            </span>
          </Text>

          <Flex margin="1rem 0">
            <ResponsiveInvertedButton
              marginRight="1rem"
              fontSize="13px"
              responsiveFontSize=".6rem"
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
                setInvoiceView(true);
              }}
            >
              Pay in Bank
            </ResponsiveInvertedButton>
            <ResponsiveBaseButton
              fontSize="13px"
              responsiveFontSize=".6rem"
              onClick={(e) => {
                e.preventDefault();
                window.open(paymentDetails?.data?.paymentLink, '_blank');
                navigate(-1);
              }}
            >
              Pay Online
            </ResponsiveBaseButton>
          </Flex>
        </Flex>
      </CustomModal>
      <ReactModal
        isOpen={invoiceView}
        close={setInvoiceView}
        className={'proforma-modal'}
        style={{ backgroundColor: 'transparent' }}
      >
        <div>
          <NoStyleButton
            onClick={() => setInvoiceView(false)}
            style={{ position: 'absolute', top: '40px', left: '5%' }}
          >
            <Span
              size="24px"
              color="#011747"
              style={{
                borderRadius: '50%',
                border: '1px solid #011747',
                padding: '6px 12px',
              }}
            >
              x
            </Span>
          </NoStyleButton>
          <ComponentToPrint
            ref={componentRef}
            paymentDetails={paymentDetails ?? transaction}
            handlePrint={handlePrint}
            hideButton={hideButton}
            currency={currency}
          />
        </div>
      </ReactModal>
    </section>
  );
};

export default GeneratePaymentReference;
