import FormInputTwo from 'app/components/atoms/form-input-two/form-input-two.component';
import FormSelect from 'app/components/atoms/form-select/form-select.component';
import LoadingDots from 'app/components/atoms/loading-dots/loading-dots.component';
import Text from 'app/components/atoms/typography';
import SignupPanel from 'app/components/molecules/signup-panel/signup-panel.component';
import { AuthLayout } from 'app/layouts';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants/constants.utils';
import SignUpPlanSelection from './singup-plan-selection';
import { useState } from 'react';
import CustomModal from 'app/components/atoms/modal/modal.component';
import bgImg from 'app/assets/img/modalbg.png';

const SignUp = ({
  formContent,
  validationSchema,
  defaultFormFields,
  handleSubmit,
  showSocialMedia,
  plans,
  isLoading,
}) => {
  const [showSelectPlan, setShowSelectPlan] = useState(false);
  const [values, setValues] = useState({});
  const [plan, setPlan] = useState(0);

  const navigate = useNavigate();

  const handleSignupClicked = (values, setSubmitting) => {
    setSubmitting(false);

    setShowSelectPlan(true);

    setValues(values);
  };

  const handleFinish = () => {
    setShowSelectPlan(false);

    handleSubmit({ ...values, planId: plan });
  };

  return (
    <AuthLayout>
      <div className="auth-login-text">
        <Text>
          Already have an account?{' '}
          <span onClick={() => navigate(ROUTES.SIGN_IN)}>Login</span>
        </Text>
      </div>
      <Formik
        initialValues={defaultFormFields}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSignupClicked(values, setSubmitting);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              {formContent.length ? (
                formContent?.map((item, index) => {
                  const { name, label, type, placeholder, options, disabled } =
                    item;
                  return type === 'select' ? (
                    <FormSelect
                      label={label}
                      options={options}
                      value={values[name]}
                      onChange={(value) => setFieldValue(name, value.value)}
                      key={index}
                      disabled={disabled}
                      name={name}
                      required
                    />
                  ) : (
                    <FormInputTwo
                      label={label}
                      type={type}
                      name={name}
                      required
                      key={index}
                      placeholder={placeholder}
                    />
                  );
                })
              ) : (
                <LoadingDots text="Fetching Form" />
              )}
              <SignupPanel
                isSubmitting={isLoading}
                showSocialMedia={showSocialMedia}
              />
            </Form>
          );
        }}
      </Formik>

      {showSelectPlan && (
        <CustomModal
          isOpen={showSelectPlan}
          className="signupModal modal-del no-scrollbar"
          style={{ content: { backgroundImage: `url(${bgImg})` } }}
          close={setShowSelectPlan}
        >
          <SignUpPlanSelection
            className=""
            handleFinish={handleFinish}
            plan={plan}
            setPlan={setPlan}
            plans={plans}
          />
        </CustomModal>
      )}
    </AuthLayout>
  );
};

export default SignUp;
