import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import invoiceSlice from '../invoice/invoiceSlice';
import { USER_ROLES } from '../auth/utils';

const entity = 'dashboard';

export const initialState = {
  invoiceMetrics: [],
  lastFiveInvoices: [],
  timeData: {},
  loading: false,
};

const usersDashboard = createAsyncThunk(
  `${entity}/organization-dashboard`,
  async (input) => {
    const { day, month, year, weekly = false, currency = 'NGN' } = input;
    const res = await api.dashboard.orgDashboard(
      day,
      month,
      year,
      weekly,
      currency
    );
    return res.data.data;
  }
);

const clientDashboard = createAsyncThunk(
  `${entity}/client-dashboard`,
  async (input) => {
    const { day, month, year, weekly = false, currency = 'NGN' } = input;
    const res = await api.dashboard.clientDashboard(
      day,
      month,
      year,
      weekly,
      currency
    );
    return res.data.data;
  }
);

const dashboardSlice = createSlice({
  name: entity,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(usersDashboard.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(usersDashboard.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(usersDashboard.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(clientDashboard.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(clientDashboard.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(clientDashboard.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});
export const dashboardActions = {
  ...invoiceSlice.actions,
  usersDashboard,
  clientDashboard,
};

export const dashboardSelectors = {
  isLoading: (state) => state.dashboard.loading,
  invoiceMetrics: (state) => state.dashboard.invoiceMetrics,
  clientMetrics: (state) => {
    return [
      {
        name: 'APPROVED INVOICES',
        amount: state.dashboard.approvedAmount,
        count: state.dashboard.approvedCount,
      },
      {
        name: 'PENDING INVOICES',
        amount: state.dashboard.pendingAmount,
        count: state.dashboard.pendingCount,
      },
      {
        name: 'REJECTED INVOICES',
        amount: state.dashboard.declinedAmount,
        count: state.dashboard.declinedCount,
      },
      {
        name: 'VALIDATION REQUEST',
        amount: state.dashboard.changeRequestedAmount,
        count: state.dashboard.changeRequestedCount,
      },
    ];
  },
  orgMetrics: (state) => {
    return [
      {
        name: 'BILLS RECEIVED',
        amount: state.dashboard.totalBillsAmount,
        count: state.dashboard.totalBillsCount,
        notAllowed: [USER_ROLES.CLIENT_USER],
      },
      {
        name: 'UNPAID BILLS',
        amount: state.dashboard.unPaidBillsAmount,
        count: state.dashboard.unPaidBillsCount,
        notAllowed: [USER_ROLES.CLIENT_USER],
      },
      {
        name: 'PAID INVOICES',
        amount:
          state.dashboard.paidInvoicesAmount ?? state.dashboard.paidAmount,
        count: state.dashboard.paidInvoicesCount ?? state.dashboard.paidCount,
      },
      {
        name: 'UNPAID INVOICES',
        amount:
          state.dashboard.unPaidInvoicesAmount ?? state.dashboard.unPaidAmount,
        count:
          state.dashboard.unPaidInvoicesCount ?? state.dashboard.unPaidCount,
      },
    ];
  },
  userInvoiceMetrics: (state) => {
    return [
      {
        name: 'Total',
        percentage: 0,
        count: state.dashboard.totalInvoiceCount,
        metric: 1,
      },
      {
        name: 'TotalPaid',
        percentage: 100,
        count: state.dashboard.paidInvoicesCount,
        metric: 0,
      },
      {
        name: 'Approved',
        percentage: 0,
        count: state.dashboard.approvedAmount,
        metric: 0,
      },
      {
        name: 'Declined',
        percentage: 0,
        count: state.dashboard.declinedAmount,
        metric: 0,
      },
      {
        name: 'Pending',
        percentage: 0,
        count: state.dashboard.pendingAmount,
        metric: 0,
      },
      {
        name: 'ChangesRequested',
        percentage: 0,
        count: state.dashboard.changeRequestedAmount,
        metric: 0,
      },
    ];
  },

  lastFiveInvoices: (state) => state.dashboard.lastFiveInvoices,
  timeData: (state) => state.dashboard.timeData,
  usersDashboard: (state) => state.dashboard,
};
export default dashboardSlice.reducer;
