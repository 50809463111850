import styled from 'styled-components';

export const CardContainer = styled.div`
  display: ${({ display }) => display || ''};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  background: ${({ b }) => b || '#ffffff'};
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: left;
  position: relative;
  // overflow: hidden;
  border: ${({ border }) => border || ''};
  padding: ${({ p }) => p || '10px 25px 20px'};
  height: ${({ h }) => h || '100%'};
  margin: ${({ m }) => m || '0'};
  max-width: ${({ maxWidth }) => maxWidth || ''};
  width: ${({ width }) => width || '100%'};
  @media screen and (max-width: 768px) {
    padding: ${({ pM }) => pM || ''};
  }
  p {
    margin: 0;
  }
`;

export const CardStyle = styled.div`
  background: ${({ background }) => background || '#ffffff'};
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: ${({ radius }) => radius || '10px'};
  text-align: left;
  padding: ${({ padding }) => padding || '10px 25px 20px'};
  height: ${({ height }) => height || '100%'};
  margin: ${({ margin }) => margin || '0px'};
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
`;

const Card = ({ children, ...otherProps }) => {
  return <CardContainer {...otherProps}>{children}</CardContainer>;
};

export default Card;
