import LoadingDots from 'app/components/atoms/loading-dots/loading-dots.component';
import DataTable from 'react-data-table-component';
import { IoIosAddCircleOutline } from 'react-icons/io';

import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  button {
    border: none;
    background: none;
    display: flex;
    justify-contents: center;
    align-items: center;
    gap: 15px;
    font-weight: bold;
    color: #0148de;
    cursor: pointer;
  }
`;

const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
    <LoadingDots height={'auto'} />
    <div>Fetching data...</div>
  </div>
);
//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      borderCollapse: 'separate',
      borderSpacing: '0 7px',
      border: '1px solid #F2F6FF',
    },
  },
  headCells: {
    style: {
      background: 'var(--primary8)',
      color: '#3a3e47',
      fontWeight: 'bold',
      height: '63px',
      fontSize: '13px',
      textDecoration: 'underline',
    },
  },
  cells: {
    style: {
      borderTop: '1px solid #dbe7ff',
      borderBottom: '1px solid #dbe7ff',
    },
  },
  pagination: {
    style: {
      zIndex: 1,
    },
  },
};

export const NoData = ({ onClick }) => (
  <Div>
    <button onClick={onClick}>
      <IoIosAddCircleOutline size={20} />
      Add a new item
    </button>
  </Div>
);

const Table = ({ ...rest }) => {
  return (
    <DataTable
      noHeader
      customStyles={customStyles}
      progressComponent={<CustomLoader />}
      {...rest}
    />
  );
};

export default Table;
