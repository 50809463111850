import styled from 'styled-components';

export const PlanContainer = styled.main`
  position: relative;
  z-index: 1;
  background-image: url(${(props) => props.watermarkURL});
  background-size: contain;
  position: relative;
  @media screen and (max-width: 900px) {
    background-position: center 10%;
  }
  @media screen and (max-width: 460px) {
    background-position: center 30%;
  }
`;

export const PlanContentContainers = styled.div`
  display: grid;
  grid-template-columns: ${({ columnTemplate }) =>
    columnTemplate || 'repeat(3, 1fr)'};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  gap: ${({ gap }) => gap || '10px'};
  @media screen and (max-width: 1022px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row-reverse;
  }
`;

export const PlanContentBox = styled.div`
  border: 1px solid var(--primary5);
  padding: ${({ padding }) => padding || '22px'};
  text-align: start;
  border-radius: 8px;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'var(--primary9)'};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const CheckListContainer = styled.div`
  width: 70%;
  @media screen and (max-width: 1200px) {
    margin-left: 10px;
  }
`;
export const CheckListItem = styled.div`
  display: flex;
  /* align-items: center; */
  margin-top: 16px;
`;
export const WriteIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.1rem;
  background-color: var(--primary2);
  border-radius: 50%;
  width: 59px;
  height: 59px;
  display: grid;
  place-items: center;
  color: white;
  font-size: 25px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 33px;
    height: 33px;
    font-size: 13.7px;
    right: 2.5rem;
  }
`;
export const WriteIcon2 = styled(WriteIcon)`
  right: 0;
  background-color: transparent;
  color: var(--primary2);
  border: 1px solid var(--primary2);
`;
export const PlanForm = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-right: 51px;
  }
`;
export const PlanInput = styled.input`
  background-color: rgba(219, 231, 255, 0.3);
  padding: 17px;
  border: 0.218068px solid #000000;
  border-radius: 4.36136px;
`;

export const PlanSelect = styled.select`
  background-color: rgba(219, 231, 255, 0.3);
  padding: 17px;
  border: 0.218068px solid #000000;
  border-radius: 4.36136px;
`;

export const PlanFormContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 150px 70% 1fr;
    max-width: 100%;
    gap: 22px;
  }
`;
export const PlanFeatures = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(219, 231, 255, 0.3);
  padding: 14px;
  border: 0.218068px solid #000000;
  border-radius: 4.36136px;
  width: 50%;
  margin-top: 49px;
`;

export const PlanCheckbox = styled.label`
  margin-top: ${({ marginTop }) => marginTop || '35px'};
  display: flex;
  gap: 14px;
  input {
    border-radius: 0;
    transform: scale(1.5);
    border-color: var(--grey2);
  }
`;

export const Label = styled.label`
  display: flex;
  gap: 5px;
`;

export const PlanTextarea = styled.textarea`
  background-color: rgba(219, 231, 255, 0.3);
  padding: 17px;
  border: 0.218068px solid #000000;
  border-radius: 4.36136px;
`;
