import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { ChildrenStyle } from '../../../layouts/auth.layout/auth.styles';
import { authActions } from 'store/reducers/auth/authSlice';

import { AuthLayout } from 'app/layouts';
import Text from '../../../components/atoms/typography';
import FormInputTwo from 'app/components/atoms/form-input-two/form-input-two.component';
import SignInPanel from 'app/components/molecules/signin-panel/signin-panel.component';
import {
  EmailValidator,
  SoftPasswordValidator,
} from 'utils/validation/validation.utils';

const defaultFormFields = {
  email: '',
  password: '',
};

const validationSchematic = Yup.object({
  email: EmailValidator,
  password: SoftPasswordValidator,
});

export const Login = () => {
  const { authenticateUser } = authActions;

  let navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <AuthLayout headText="Welcome Back">
      <div className="auth-login-text">
        <Text>
          Don't have an account?{' '}
          <span onClick={() => navigate('/client-user-signup')}>Register</span>
        </Text>
      </div>
      <Formik
        initialValues={defaultFormFields}
        onSubmit={async (values, { setSubmitting }) => {
          dispatch(authenticateUser(values));
          setSubmitting(false);
        }}
        validationSchema={validationSchematic}
      >
        {({ isSubmitting }) => (
          <Form>
            <ChildrenStyle>
              <FormInputTwo label="Email" type="email" name="email" required />
              <FormInputTwo
                label="Password"
                type="password"
                name="password"
                required
              />
            </ChildrenStyle>
            <SignInPanel isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
