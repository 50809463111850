import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const easeiRequest = axios.create({
  baseURL: `${BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export const easeiRequestTwo = axios.create({
  baseURL: `${BASE_URL}/v2`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export default easeiRequest;
