import React, { forwardRef } from 'react';
import Modal from 'react-modal';

import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';

const CloseButton = styled.div`
  cursor: pointer;
  font-weight: 700;
`;

const Title = styled.div`
  cursor: pointer;
  color: #0148de;
  font-weight: 700;
`;

const TopPane = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--primary8);
`;

const BottomPane = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHouse = forwardRef(
  ({ close, isOpen, form, title, height, className, setEditUser }, ref) => {
    const customStyles = {
      content: {
        display: 'flex',
        flexDirection: 'column',
        width: '550px',
        height: height || 'auto',
        position: 'absolute',
        top: '5%',
        left: '33%',
        background: 'white',
        border: '1px solid #dbe7ff',
        borderRadius: '10px',
        transition: '1.1s ease-out',
        boxShadow: '-2rem 2rem 2rem rgba(black, 0.2)',
        filter: 'blur(0)',
        transform: 'scale(1)',
        opacity: 1,
        padding: 0,
        zIndex: 9999999999999,
      },
    };
    return (
      <Modal
        isOpen={isOpen}
        style={className ? {} : customStyles}
        className={className}
        onRequestClose={(e) => {
          close(false);
          if (setEditUser) {
            setEditUser({ value: false, data: null });
          }
        }}
        ariaHideApp={false}
        ref={ref}
      >
        <div
          style={{
            border: '0.55px solid var(--grey2)',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <TopPane>
            <Title>{title}</Title>
            <CloseButton
              className="modal-close"
              onClick={(e) => {
                close(false);
                if (setEditUser) {
                  setEditUser({ value: false, data: null });
                }
              }}
              id="bg"
            >
              <AiOutlineClose
                size={20}
                color="#0148DE"
                onClick={(e) => close(e)}
                id="bg2"
              />
            </CloseButton>
          </TopPane>
          <BottomPane>{form}</BottomPane>
        </div>
      </Modal>
    );
  }
);

export default ModalHouse;
