import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import _ from 'lodash';
import Button from 'app/components/atoms/button/button.component';
import {
  BaseButton,
  InvertedButton,
  InvertedStatusButton,
  StatusButton,
} from 'app/components/atoms/button/button.styles';
import Table, { NoData } from 'app/components/molecules/table/table.component';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import { ROUTES } from 'utils/constants/constants.utils';
import { useCheckAdmin, useViewport } from 'hooks';
import {
  CurrencyActions,
  CurrencySelectors,
} from 'store/reducers/currency/currencySlice';
import ActionMenu from 'app/components/molecules/action-menu/action-menu.component';
import CustomModal from 'app/components/atoms/modal/modal.component';
import {
  getInvoiceTotalAmount,
  getInvoiceTotalCost,
  getInvoiceTotalTax,
} from 'utils/helpers/getInvoiceCalculations/getInvoiceCalculations';
import { FlexedDiv } from 'app/components/atoms/divs/div.styles';
import { NewInvoiceItem } from 'app/components/cell/invoiceItem/invoiceItem.component';
import ModalHouse from 'app/components/molecules/modals/modalHouse';
import { InvoiceRecordSummary } from 'app/components/cell/invoiceRecordSummary/InvoiceRecordSummary';
import { appDataActions } from 'store/reducers/appData/appDataSlice';
import {
  ButtonContainer,
  ContentWrapper,
  CustomSelect,
  Div,
  InvoiceBody,
  InvoiceInputDataHouse,
  InvoiceSubjectInput,
  Label,
  OptionsSection,
} from 'app/components/atoms/generalInvoiceStyles/general.sytlyes';
import AddMultipleOrganizations from './addMultipleOrganizations';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { billSelectors } from 'store/reducers/bills/billsSlice';

const OrgItem = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
  .innerDiv {
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;
function filterSelectedItems(orgList, selected) {
  return orgList.filter((item) =>
    selected.includes(item.label ?? item.fullName)
  );
}
function formatItems(item) {
  return {
    id: item.id ?? 0,
    fullName: item.label ?? item.fullName,
    phoneNumber: item.phoneNumber,
    email: item.email,
    address: item.address,
  };
}

const BaseBillSetup = ({
  handleSave,
  ClientDropDown,
  invoice,
  backRoute,
  organization,
  setOrganization,
}) => {
  const { getCurrencies } = CurrencyActions;
  const { resetPreviewState } = appDataActions;
  const [isDeleteLoading] = useState(false);
  const isBillLoading = useSelector(billSelectors.isLoading);
  const currencies = useSelector(CurrencySelectors.currencies);
  const isCurrencyLoading = useSelector(CurrencySelectors.isLoading);

  const [data, setData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMultiUsers, setShowMultiUsers] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [subject, setSubject] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhonenumber, setClientPhoneNumber] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [approveDel, setApproveDel] = useState(false);
  const [deleteItem, setDeleteItem] = useState('');
  const [selectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const { role } = useCheckAdmin();
  const { mobile } = useViewport();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (row) => {
    setApproveDel(true);
    setDeleteItem(row.serviceName);
  };

  const handleCancel = () => {
    dispatch(resetPreviewState());
    navigate(ROUTES.BILLS);
  };
  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
  };

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.serviceName
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'serviceName'));
      }
    };
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: 'red' }}
        icon
        height="44px"
      >
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);

  const columns = [
    {
      name: 'Items',
      selector: (row) => row.itemUnit,
    },
    {
      name: 'Service',
      selector: (row) => row.serviceName,
      grow: 2,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      grow: 2,
    },
    {
      name: 'Unit',
      selector: (row) => row.itemUnit,
    },
    {
      name: 'Price',
      selector: (row) => getInvoiceTotalAmount([row]),
    },
    {
      name: '',
      selector: (row) => (
        <ActionMenu
          popupList={['Delete']}
          row={row}
          handleDelete={handleDelete}
        />
      ),
      button: true,
    },
  ];

  const mobileColumns = [
    {
      name: 'Service',
      selector: (row) => row.serviceName,
      grow: 2,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      grow: 3,
    },

    {
      name: 'Unit',
      selector: (row) => row.itemUnit,
    },
    {
      name: 'Price',
      selector: (row) => getInvoiceTotalAmount([row]),
    },
    {
      name: '',
      selector: (row) => (
        <ActionMenu
          popupList={['Delete']}
          row={row}
          handleDelete={handleDelete}
        />
      ),
      button: true,
    },
  ];

  useEffect(() => {
    dispatch(getCurrencies());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(invoice)) {
      setCurrency(
        currencies.filter(function (item) {
          return item.label === invoice.currency;
        })[0] || null
      );

      setInvoiceNo(invoice.invoiceNo);
      setData(invoice.services);
      setSubject(invoice.subject);
      if (!_.isEmpty(invoice.clients) || !_.isEmpty(invoice.orgList)) {
        if (invoice?.clients?.length === invoice?.orgList?.length) {
          setIsCheckAll(true);
          setIsCheck(invoice?.clients?.map((li) => li.fullName ?? li.label));
        } else {
          setIsCheck(invoice?.clients?.map((li) => li.label ?? li.fullName));
        }

        if (
          invoice.clients.length === 1 &&
          invoice.orgList &&
          _.isEmpty(invoice.orgList)
        ) {
          setOrganization({
            ...invoice.clients[0],
            label: invoice.clients[0].fullName,
            value: invoice.clients[0].id,
          });
        } else {
          setOrgList(invoice.orgList ?? invoice.clients);
        }
      }
    }
  }, [invoice, currencies]);

  useEffect(() => {
    if (!_.isEmpty(organization)) {
      setClientEmail(organization.email);
      setClientPhoneNumber(organization.phoneNumber);
    }
  }, [organization]);

  const handleCloseModal = (e) => {
    setShowModal(false);
  };

  const handleDataSave = (isPreview) => {
    if (
      (orgList.length <= 0 && !clientPhonenumber) ||
      (orgList.length > 0 && isCheck.length === 0)
    ) {
      toast.error('Select at least one Organization!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const payload = {
        subject: subject,
        services: data,
        currency: currency?.label,
        invoiceNo: invoice.invoiceNo,
        id: invoice.id,
        orgList,
        clients:
          orgList.length <= 0
            ? [
                formatItems({
                  id: organization?.id,
                  label: organization.fullName ?? organization.label,
                  phoneNumber: clientPhonenumber,
                  email: clientEmail,
                }),
              ]
            : isCheckAll
            ? orgList.map((item) => formatItems(item))
            : filterSelectedItems(orgList, isCheck).map((item) =>
                formatItems(item)
              ),
      };
      handleSave(payload, isPreview);
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(orgList.map((li) => li.fullName ?? li.label));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
    if (checked && isCheck.length + 1 >= orgList.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  };

  return (
    <div style={{ margin: mobile ? '38px 10px' : '38px 100px' }}>
      {mobile ? (
        ''
      ) : (
        <FlexedDiv>
          <Heading>{invoiceNo ? invoiceNo : 'New Bill'}</Heading>
          <BaseButton
            width="true"
            onClick={() =>
              _.isEmpty(backRoute) ? navigate(-1) : navigate(backRoute)
            }
          >
            <Text color="white">Back</Text>
          </BaseButton>
        </FlexedDiv>
      )}
      <OptionsSection>
        <FlexedDiv justifyContent="space-between">
          {orgList.length > 0 ? (
            <OrgItem justifyContent="flex-start">
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={isCheckAll}
              />

              <div className="innerDiv">
                <Span>All ({orgList.length})</Span>
              </div>
            </OrgItem>
          ) : (
            <div></div>
          )}
          <Div justifyContent="flex-end">
            <button onClick={() => setShowMultiUsers(true)}>
              {orgList.length > 0 ? 'Edit' : 'Create for Multiple Clients'}
            </button>
          </Div>
        </FlexedDiv>
        <CustomModal
          isOpen={showMultiUsers}
          close={setShowMultiUsers}
          className="modal modal-del modal-users no-scrollbar"
        >
          <Text size="18px">
            Send a bill to multiple clients at once. Add the clients you will
            like to send this bill to.{' '}
            <Span color="#BF4545">(Max of 10 clients)</Span>
          </Text>

          <AddMultipleOrganizations
            orgList={orgList}
            setOrgList={setOrgList}
            clientList={ClientDropDown}
            organization={organization}
            closeModal={() => setShowMultiUsers(false)}
          />
        </CustomModal>
        {orgList.length > 0 && (
          <ContentWrapper repeat="2" style={{ marginBottom: '1.5rem' }}>
            {orgList.map((item) => (
              <OrgItem
                justifyContent="flex-start"
                key={item.label ?? item.fullName}
              >
                <input
                  type="checkbox"
                  id={item.label ?? item.fullName}
                  onClick={handleClick}
                  checked={isCheck.includes(item.label ?? item.fullName)}
                />

                <div className="innerDiv">
                  <Span>{item.label ?? item.fullName}</Span>
                  <Span>{item.email}</Span>
                </div>
              </OrgItem>
            ))}
          </ContentWrapper>
        )}
        {orgList.length > 0 && (
          <ContentWrapper repeat="2">
            <InvoiceInputDataHouse>
              <Label>Subject</Label>
              <InvoiceSubjectInput
                placeholder="what is the bill for?"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </InvoiceInputDataHouse>
            <InvoiceInputDataHouse>
              <Label>Currency</Label>
              <CustomSelect
                isDisabled={isCurrencyLoading}
                isLoading={isCurrencyLoading}
                placeholder="Select currency"
                options={currencies}
                onChange={handleCurrencyChange}
                className="react-select-container"
                classNamePrefix="react-select"
                name="currency"
                value={currency}
              />
            </InvoiceInputDataHouse>
          </ContentWrapper>
        )}
        {orgList.length <= 0 && (
          <ContentWrapper repeat="2">
            {ClientDropDown}

            <InvoiceInputDataHouse>
              <Label>Email Address</Label>
              <InvoiceSubjectInput
                placeholder="Client email"
                type="email"
                value={clientEmail}
                disabled={organization?.email}
                onChange={(e) => setClientEmail(e.target.value)}
              />
            </InvoiceInputDataHouse>
            <InvoiceInputDataHouse>
              <Label>Currency</Label>
              <CustomSelect
                isDisabled={isCurrencyLoading}
                isLoading={isCurrencyLoading}
                placeholder="Select currency"
                options={currencies}
                onChange={handleCurrencyChange}
                className="react-select-container"
                classNamePrefix="react-select"
                name="currency"
                value={currency}
              />
            </InvoiceInputDataHouse>
            <InvoiceInputDataHouse>
              <Label>Subject</Label>
              <InvoiceSubjectInput
                placeholder="what is the bill for?"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </InvoiceInputDataHouse>

            <InvoiceInputDataHouse>
              <Label>Phone Number</Label>
              <InvoiceSubjectInput
                placeholder="Client phone number"
                value={clientPhonenumber}
                disabled={organization?.phoneNumber}
                onChange={(e) => setClientPhoneNumber(e.target.value)}
              />
            </InvoiceInputDataHouse>
          </ContentWrapper>
        )}
      </OptionsSection>
      <InvoiceBody style={{ marginTop: '50px' }}>
        <Table
          title="Invoice Details"
          className="table"
          columns={mobile ? mobileColumns : columns}
          data={data}
          persistTableHead
          contextActions={contextActions}
          actions={<></>}
        />
        <div className="details">
          <NoData onClick={() => setShowModal(!showModal)} />
          <ModalHouse
            className="modal modal__extra"
            close={handleCloseModal}
            isOpen={showModal}
            form={<NewInvoiceItem data={data} setData={setData} />}
            title="New Invoice"
            height="100%"
          />

          {data.length > 0 && (
            <InvoiceRecordSummary
              summaryDisplayCondition={data.length !== 0}
              totalAmount={getInvoiceTotalAmount(data)}
              subTotal={getInvoiceTotalCost(data)}
              vat={getInvoiceTotalTax(data)}
              currency={currency?.label}
              comment={additionalInformation}
              setComment={setAdditionalInformation}
              role={role}
              bill={true}
              placeholder="Enter additional details..."
            />
          )}
        </div>
      </InvoiceBody>
      <ButtonContainer>
        {data.length > 0 && (
          <>
            <InvertedButton
              onClick={() => {
                handleDataSave(true);
              }}
            >
              Preview
            </InvertedButton>
            <Button
              onClick={() => {
                handleDataSave(false);
              }}
              isLoading={isBillLoading}
            >
              Save
            </Button>
          </>
        )}
        <div style={{ flex: data.length > 0 ? 1 : 3 }}></div>
        <InvertedButton className="cancel" onClick={handleCancel}>
          Cancel
        </InvertedButton>
      </ButtonContainer>

      {approveDel && (
        <CustomModal
          isOpen={approveDel}
          className="modal modal-del"
          close={setApproveDel}
        >
          <form
            style={{ marginTop: '65px', textAlign: 'center' }}
            onSubmit={(e) => {
              e.preventDefault();
              setData(data.filter((item) => item.serviceName !== deleteItem));
              setApproveDel(false);
            }}
          >
            <Text>Are you sure you want to remove this item?</Text>
            <div className="btn" style={{ marginBottom: '20ox' }}>
              <InvertedStatusButton
                margin="0 10px 0 0"
                disabled={isDeleteLoading}
                onClick={(e) => {
                  e.preventDefault();
                  setApproveDel(false);
                }}
              >
                {' '}
                NO
              </InvertedStatusButton>
              <StatusButton
                isLoading={isDeleteLoading}
                disabled={isDeleteLoading}
                type="submit"
              >
                YES
              </StatusButton>
            </div>
          </form>
        </CustomModal>
      )}
    </div>
  );
};

export default BaseBillSetup;
