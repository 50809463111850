import Text, { Heading } from 'app/components/atoms/typography';
import React, { useEffect, useState } from 'react';
import {
  Label,
  PlanCheckbox,
  PlanContainer,
  PlanFeatures,
  PlanFormContent,
  PlanInput,
  PlanSelect,
  PlanTextarea,
} from '../plan.style';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import { IoMdArrowDropdown } from 'react-icons/io';
import { BaseButton } from 'app/components/atoms/button/button.styles';
import { planActions, planSelectors } from 'store/reducers/plan/planSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  featureActions,
  featureSelector,
} from 'store/reducers/feature/featureSlice';
import { MOBILE_WINDOW_SIZE, PLAN_TYPE } from 'utils/constants/constants.utils';
import Button from 'app/components/atoms/button/button.component';
import {
  SettingsActions,
  SettingsSelectors,
} from 'store/reducers/settings/settingsSlice';
import { PositiveNumberValidator } from 'utils/validation/validation.utils';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import WaterMark from '../../../assets/img/logo-watermark.png';
import { appDataActions } from 'store/reducers/appData/appDataSlice';
import { FlexedDiv } from 'app/components/atoms/divs/div.styles';
import { FormSwitchNoFormik } from 'app/components/atoms/form-switch/form-switch.component';

const selectOptions = [
  { value: 1, label: PLAN_TYPE.ORGANIZATION },
  { value: 2, label: PLAN_TYPE.CLIENT },
];

const initialFeatureData = {
  description: '',
  featureType: [],
  featureId: 0,
  id: 0,
};

const BaseCreatePlan = () => {
  const { id } = useParams();
  const { getFeature } = featureActions;
  const { getFeatureTypes } = SettingsActions;
  const { createPlan, updatePlan } = planActions;
  const { createFeature, updateFeature } = featureActions;
  const { handleApiResponse } = useHandleApiResponse();
  const { setHeaderText, resetState } = appDataActions;

  const allPlans = useSelector(planSelectors.allPlans);
  const featureLists = useSelector(featureSelector.featureList);
  const featureTypes = useSelector(SettingsSelectors.featureTypes);
  const isFeatureLoading = useSelector(featureSelector.isLoading);
  const isPlanLoading = useSelector(planSelectors.isLoading);

  let plan = allPlans?.filter((p) => p.id === Number(id))[0];

  const initialFormData = {
    id: plan?.id ?? 0,
    name: plan?.name ?? '',
    description: plan?.description ?? '',
    cost: plan?.cost ?? 0,
    freePeriod: plan?.freePeriod ?? 0,
    freePeriodOffset: plan?.freePeriodOffset ?? 0,
    planType: plan?.planType ?? '',
    canExpire: plan?.canExpire ?? false,
    features: plan?.features?.map((feature) => feature.id) ?? [],
  };

  const [selectedPage, setSelectedPage] = useState('new_plan');
  const [planFormData, setPlanFormData] = useState(initialFormData);
  const [featureFormData, setfeatureFormData] = useState(initialFeatureData);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFeature());
    dispatch(getFeatureTypes());
    dispatch(setHeaderText({ value: 'Plan & Feature', type: 'setHeaderText' }));

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      dispatch(resetState());
    };

    // eslint-disable-next-line
  }, [id]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handlePageChange = (e) => {
    setSelectedPage(e.target.value);
  };

  const handlePlanFormDataChange = (e) => {
    const { name, value } = e.target;
    setPlanFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePlanFeatureSelected = (e) => {
    let targetNumber = Number(e?.target?.value) ?? 0;
    if (targetNumber <= 0) return;

    let features = planFormData['features'];

    setPlanFormData((prevFormData) => ({
      ...prevFormData,
      features: features?.includes(targetNumber)
        ? features.filter((number) => number !== targetNumber)
        : features.concat(targetNumber),
    }));
  };

  const handlePlanFormDataSubmit = () => {
    if (Number(id) > 0) {
      dispatch(updatePlan(planFormData)).then((res) => {
        handleApiResponse(res, '/plans');
      });
    } else {
      dispatch(createPlan(planFormData)).then((res) => {
        handleApiResponse(res, '/plans');
      });
    }
  };

  const handleFeatureFormDataChange = (e) => {
    const { name, value } = e.target;
    let feature = featureLists?.find(
      (f) => f.featureType === Number(value ?? 0)
    );

    const updatedFormData = {
      ...featureFormData,
      [name]: value,
    };

    if (name === 'featureType' && feature) {
      updatedFormData.description = feature.description;
      updatedFormData.featureId = feature.id;
    }

    setfeatureFormData(updatedFormData);
  };

  const handleFeatureFormDataSubmit = (e) => {
    e.preventDefault();

    if (featureFormData.featureId === 0) {
      dispatch(createFeature(featureFormData)).then((res) => {
        handleApiResponse(res, '/plans');
      });
    } else {
      dispatch(updateFeature(featureFormData)).then((res) => {
        handleApiResponse(res, '/plans');
      });
    }
  };

  const validate = Yup.object({
    cost: PositiveNumberValidator,
    freePeriod: PositiveNumberValidator,
    //featureIds: ArrayCountValidator("select at least one feature"),
  });

  const handleChange = () => {
    setPlanFormData((prevFormData) => ({
      ...prevFormData,
      canExpire: !prevFormData.canExpire,
    }));
  };
  return (
    <>
      <PlanContainer watermarkURL={WaterMark} className="dashboard-container">
        {windowWidth > MOBILE_WINDOW_SIZE && (
          <FlexedDiv>
            <Heading color="var(--primary2)">
              {selectedPage === 'new_plan' ? ' Setup Plan' : ' Setup Feature'}
            </Heading>
            <BaseButton width="true" onClick={() => navigate(-1)}>
              <Text color="white">Back</Text>
            </BaseButton>
          </FlexedDiv>
        )}
        <div style={{ display: 'flex', gap: '10px', margin: '80px 0 60px' }}>
          <Label htmlFor="new_plan">
            <input
              type="radio"
              id="new_plan"
              name="option"
              value="new_plan"
              onChange={handlePageChange}
              checked={selectedPage === 'new_plan'}
            />
            <Heading level={2} color="var(--primary2)" margin="0 10px 0">
              New Plan
            </Heading>
          </Label>

          <Label htmlFor="new_feature">
            <input
              type="radio"
              id="new_feature"
              name="option"
              value="new_feature"
              onChange={handlePageChange}
              style={{ marginLeft: '20px' }}
            />{' '}
            <Heading
              level={2}
              color="var(--primary2)"
              htmlFor="new_feature"
              margin="0 10px 0"
            >
              {' '}
              New Feature
            </Heading>
          </Label>
        </div>

        {selectedPage === 'new_plan' && (
          <Formik
            initialValues={initialFormData}
            validationSchema={validate}
            onSubmit={handlePlanFormDataSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <PlanFormContent>
                    <Text>Plan Name</Text>
                    <PlanInput
                      required
                      type="text"
                      name="name"
                      placeholder="Name your Plan"
                      value={planFormData.name}
                      onChange={handlePlanFormDataChange}
                    />
                  </PlanFormContent>

                  <PlanFormContent>
                    <Text>Description</Text>
                    <PlanInput
                      required
                      type="text"
                      name="description"
                      placeholder="Add a description"
                      value={planFormData.description}
                      onChange={handlePlanFormDataChange}
                    />
                  </PlanFormContent>
                  <PlanFormContent>
                    <Text>Amount</Text>
                    <PlanInput
                      required
                      type="number"
                      name="cost"
                      placeholder="Input Monthly Price (₦)"
                      value={planFormData.cost}
                      onChange={handlePlanFormDataChange}
                    />
                  </PlanFormContent>
                  <PlanFormContent>
                    <Text>Free Period</Text>
                    <PlanInput
                      required
                      type="number"
                      name="freePeriod"
                      placeholder="Free Period"
                      value={planFormData.freePeriod}
                      onChange={handlePlanFormDataChange}
                    />
                  </PlanFormContent>
                  <PlanFormContent>
                    <Text>Free Period Offset</Text>
                    <PlanInput
                      type="number"
                      name="freePeriodOffset"
                      placeholder="Free Period Offset"
                      value={planFormData.freePeriodOffset}
                      onChange={handlePlanFormDataChange}
                    />
                  </PlanFormContent>

                  <PlanFormContent>
                    <Text>Subscriber</Text>
                    <PlanSelect
                      required
                      placeholder="SelectClient"
                      options={selectOptions}
                      defaultValue={planFormData.planType}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="planType"
                      onChange={handlePlanFormDataChange}
                    >
                      <option value="" style={{ width: '100px' }}>
                        Select Client
                      </option>
                      {selectOptions?.map((option) => (
                        <option
                          style={{ width: '100px' }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </PlanSelect>
                  </PlanFormContent>
                  <FlexedDiv justifyContent="start">
                    <Text>Can this plan expire?</Text>
                    <FormSwitchNoFormik
                      checked={planFormData.canExpire}
                      name="canExpire"
                      onChange={handleChange}
                    />
                  </FlexedDiv>
                  <div>
                    <PlanFeatures>
                      Features
                      <IoMdArrowDropdown />
                    </PlanFeatures>
                    {featureLists?.map(({ id, description }) => {
                      return (
                        <PlanCheckbox key={id} htmlFor={`featureIds-${id}`}>
                          <input
                            type="checkbox"
                            name="featureIds"
                            id={`featureIds-${id}`}
                            checked={planFormData.features.includes(id)}
                            value={id}
                            onChange={handlePlanFeatureSelected}
                          />
                          <Text>{description}</Text>
                        </PlanCheckbox>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      placeItems: 'center',
                      marginTop: '124px',
                    }}
                  >
                    <Button
                      width="true"
                      isLoading={isPlanLoading}
                      disabled={isPlanLoading}
                      type="submit"
                    >
                      {' '}
                      {Number(id) > 0 ? 'Update Plan' : 'Create Plan'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {selectedPage === 'new_feature' && (
          <form onSubmit={handleFeatureFormDataSubmit}>
            <PlanFormContent>
              <Text>Description</Text>
              <PlanTextarea
                required
                placeholder="Text"
                value={featureFormData.description}
                onChange={handleFeatureFormDataChange}
                name="description"
              />
            </PlanFormContent>
            <PlanFormContent>
              <Text>Feature type</Text>
              <PlanSelect
                required
                placeholder="Drop Down"
                options={selectOptions}
                className="react-select-container"
                classNamePrefix="react-select"
                name="featureType"
                onChange={handleFeatureFormDataChange}
              >
                <option value="" style={{ width: '100px' }}>
                  Select Feature
                </option>
                {featureTypes?.map(({ id, name }) => (
                  <option key={id} style={{ width: '100px' }} value={id}>
                    {name}
                  </option>
                ))}
              </PlanSelect>
            </PlanFormContent>
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                marginTop: '124px',
              }}
            >
              <Button
                width="true"
                type="submit"
                isLoading={isFeatureLoading}
                disabled={isFeatureLoading}
              >
                {' '}
                Setup Feature
              </Button>
            </div>
          </form>
        )}
      </PlanContainer>
    </>
  );
};

export default BaseCreatePlan;
