import Dropdown from 'app/components/atoms/dropdown/dropdown.component';
import Spinner from 'app/components/atoms/spinner/spinner.component';
import { Span } from 'app/components/atoms/typography';
import { useOnClickOutside } from 'hooks';
import { useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  CurrencyActions,
  CurrencySelectors,
} from 'store/reducers/currency/currencySlice';
import styled from 'styled-components';
export const DropdownSelectContainer = styled.div`
  position: relative;
  .selected-item {
    display: flex;
    align-items: center;
    border: 1px solid ${({ bordercolor }) => bordercolor || 'var(--grey4)'};
    padding: ${({ padding }) => padding || '4px 10px'};
    border-radius: ${({ radius }) => radius || '4px'};
    background-color: ${({ bgcolor }) => bgcolor || 'transparent'};
    .icon {
      margin-left: auto;
    }
  }
`;
const CurrenciesDropdown = ({ selected, setSelected }) => {
  const { visible, setVisible, ref } = useOnClickOutside(false);
  const dispatch = useDispatch();
  const { getCurrencies } = CurrencyActions;
  const currencies = useSelector(CurrencySelectors.currencies);
  const isCurrencyLoading = useSelector(CurrencySelectors.isLoading);
  useEffect(() => {
    if (!selected && currencies) {
      setSelected(currencies.find((c) => c.label === 'NGN'));
    }
  }, [selected, currencies]);

  const handleClick = (item) => {
    setSelected(item);
    setVisible(false);
  };

  useEffect(() => {
    if (currencies?.length <= 0) {
      dispatch(getCurrencies());
    }
  }, [currencies]);

  return (
    <DropdownSelectContainer
      ref={ref}
      bordercolor="var(--accent)"
      bgcolor="white"
    >
      <div className="selected-item" onClick={() => setVisible(!visible)}>
        {isCurrencyLoading && <Spinner size="24px" />}
        {selected && <Span display="inline">{selected.label}</Span>}

        <FaAngleDown size={16} className="icon" />
      </div>
      {visible && (
        <Dropdown
          options={currencies}
          callback={handleClick}
          marginLeft={'0'}
        />
      )}
    </DropdownSelectContainer>
  );
};

export default CurrenciesDropdown;
