import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LOGOUT } from 'utils/constants/constants.utils';
import { Span } from '../typography';

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 90%;
  min-width: 100%;
  max-width: 250px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 3px;
  animation: pulse 0.5s;
  z-index: 1000;

  @media (max-width: 767px) {
    margin-left: ${({ marginLeft }) => marginLeft || '-170px'};
  }
  span {
    display: inline-block;
    vertical-align: sub;
    width: auto;
    margin: 0.5rem 0;
  }

  li:hover {
    background: #e5e5e5;
  }

  i {
    margin-right: 0.5rem;
  }

  li {
    padding: 1px 20px;
    list-style-type: none;
  }

  ul {
    list-style-type: none;
    all: unset;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Dropdown = ({ options, handleLogout, callback, marginLeft }) => {
  const navigate = useNavigate();
  const handleClick = (option) => () => {
    if (option.value === LOGOUT) {
      handleLogout();
    } else if (option.value === 'helpAndSupport') {
      navigate('/contact-us');
    } else {
      callback(option);
    }
  };

  return (
    <DropdownContainer marginLeft={marginLeft}>
      <ul>
        {options?.map((option, index) => (
          <li onClick={handleClick(option)} key={index}>
            <Span size={14}>
              {option?.icon && <i>{option?.icon}</i>}
              {option.label}
            </Span>
          </li>
        ))}
      </ul>
    </DropdownContainer>
  );
};

export default Dropdown;
