import styled from 'styled-components';

export const EmptyInvoiceContainer = styled.section`
  margin: 40px;
  .empty-banner {
    max-width: 70%;
    margin: 0 auto;
    &__content {
      max-width: 358px;
      margin: 108px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      .img-container {
        max-width: 20.5rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin: 20px;
    .empty-banner {
      max-width: 90%;
      &__content {
        max-width: 300px;
        margin: 40px auto;
        .img-container {
          max-width: 20.5rem;
        }
      }
    }
  }
`;
export const CustomInvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 20px;
  /* @media screen and (min-width: 850px) {
     margin: 30px 0;
  } */
  .metric {
    position: relative;
    .left {
      position: absolute;
      top: 3.8em;
      left: -1.5em;
      @media screen and (min-width: 850px) {
        left: -2.7em;
      }
    }
    .right {
      position: absolute;
      top: 3.8em;
      right: -1.5em;
      @media screen and (min-width: 850px) {
        right: -2.7em;
      }
    }
  }
`;

export const InvoiceTopBanner = styled.div`
  background: white;
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
  border-top: 2px solid #dbe7ff;
  width: 100%;

  .client-invoice__new {
    display: flex;
    justify-content: space-between;
    margin: 40px;
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      margin: 20px;
    }
    div {
      margin-bottom: 20px;
    }
    .client-invoice__new_button {
      display: flex;
      justify-content: flex-end;
    }
  }

  .invoice-details__close {
    display: flex;
    justify-content: space-between;

    svg {
      cursor: pointer;
      opacity: 0.65;
      filter: alpha(opacity=65);
      color: #808080;
      width: 25px;
      height: 25px;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }
  .invoice_header {
    @media screen and (max-width: 500px) {
      p,
      h1 {
        margin: 0;
      }
      p:last-child {
        font-size: 10px;
      }
    }
  }
`;

export const InvoiceHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @media screen and (min-width: 700px) {
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
  }
  button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: calc(100%);
  left: 0;
  font-size: 10px;
  width: 100px;
`;
