import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside, useViewport } from 'hooks';

import Text, { Heading } from 'app/components/atoms/typography';
import InvoiceList from 'app/components/cell/invoiceList/invoiceList';
import InvoiceCarousel from 'app/components/molecules/invoice/invoice-carousel.component';
import PopupContent from 'app/components/atoms/popup/popup-content.component';
import SearchComponent from 'app/components/cell/searchComponent/searchComponent';
import { CreateDropdown } from './subComponents';

import { InvoiceTypes, ROUTES } from 'utils/constants/constants.utils';
import {
  invoiceActions,
  invoiceSelectors,
} from 'store/reducers/invoice/invoiceSlice';
import { InvoiceTopBanner } from '../invoice.styles';
import {
  CreateNewContainer,
  FlexedDiv,
  MobileResponsiveButton,
  SimpleSearchContainer,
} from 'app/components/atoms/divs/div.styles';
import { USER_ROLES } from 'store/reducers/auth/utils';
import CurrenciesDropdown from 'app/components/molecules/currenciesDropDown/currencies-dropdown.component';
import LoadingDots from 'app/components/atoms/loading-dots/loading-dots.component';

const initialState = {
  searchInput: '',
  organization: '',
  pageSize: 10,
  page: 1,
  invoicePaymentStatus: null,
  startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
  endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  statusInput: null,
};

const InvoiceView = ({ role }) => {
  const [state, setState] = useState(initialState);
  const [selectedCurrency, setSelectedCurrency] = useState({ label: 'NGN' });
  const {
    searchInput,
    organization,
    pageSize,
    page,
    invoicePaymentStatus,
    startDate,
    endDate,
    statusInput,
  } = state;

  const pendingCount = useSelector(invoiceSelectors.pendingInvoiceCount);
  const changeRequestCount = useSelector(invoiceSelectors.changeRequestCount);
  const approvedCount = useSelector(invoiceSelectors.approvedInvoiceCount);
  const declinedCount = useSelector(invoiceSelectors.declinedInvoiceCount);
  const totalCount = useSelector(invoiceSelectors.totalInvoiceCount);
  const invoices = useSelector(invoiceSelectors.invoices);
  const isLoading = useSelector(invoiceSelectors.isLoading);
  const { searchInvoices } = invoiceActions;

  const { visible, setVisible, ref } = useOnClickOutside(false);
  const { mobile } = useViewport();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePerRowsChange = async (newPageSize) => {
    setState((prevState) => ({ ...prevState, pageSize: newPageSize }));
  };

  const handlePageChange = (newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  };

  const handleCreate = (e) => {
    if (e === InvoiceTypes.External) {
      navigate(ROUTES.CREATE_EXTERNAL_INVOICE);
    } else {
      navigate(ROUTES.CREATE_INVOICE);
    }
  };

  const dispatchSearchInvoices = () => {
    dispatch(
      searchInvoices({
        page,
        pageSize,
        startDate,
        status: statusInput,
        endDate,
        filter: searchInput,
        organizationId: organization,
        invoicePaymentStatus,
        currency: selectedCurrency.label,
      })
    );
  };

  useEffect(() => {
    dispatchSearchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchInput,
    organization,
    invoicePaymentStatus,
    startDate,
    endDate,
    statusInput,
    page,
    pageSize,
    selectedCurrency,
  ]);

  const handlePopupClicked = () => {
    setVisible(!visible);
  };

  return (
    <section style={{ width: '100%' }}>
      <InvoiceTopBanner>
        <div className=" client-invoice__new">
          <div className="invoice_header">
            <Heading color="var(--primary2)" size={mobile ? 18 : 32}>
              Invoices
            </Heading>
            <Text size={mobile ? 12 : 16}>
              Create invoices and track their progress
            </Text>
          </div>

          {role === USER_ROLES.CLIENT_USER && (
            <div ref={ref} className="client-invoice__new_button">
              {mobile ? (
                <MobileResponsiveButton
                  onClick={handlePopupClicked}
                  paddingRes=".6px 8px"
                >
                  <CreateDropdown />
                </MobileResponsiveButton>
              ) : (
                <CreateNewContainer onClick={handlePopupClicked}>
                  <CreateDropdown />
                </CreateNewContainer>
              )}
              {visible && (
                <PopupContent
                  list={[InvoiceTypes.External, InvoiceTypes.Internal]}
                  handleClick={(e) => handleCreate(e)}
                  top={mobile ? '170px' : '210px'}
                  left={mobile ? '0' : '180px'}
                />
              )}
            </div>
          )}
        </div>
      </InvoiceTopBanner>
      <FlexedDiv
        justifyContent="flex-end"
        style={{ padding: '27px 20px 0 20px' }}
      >
        <CurrenciesDropdown
          selected={selectedCurrency}
          setSelected={setSelectedCurrency}
        />
      </FlexedDiv>
      <div style={{ padding: '0 20px 27px 20px' }}>
        <InvoiceCarousel
          pendingCount={pendingCount}
          approvedCount={approvedCount}
          declinedCount={declinedCount}
          totalCount={totalCount}
          changesRequested={changeRequestCount}
        />

        <SimpleSearchContainer>
          <SearchComponent
            startDate={startDate}
            endDate={endDate}
            organization={organization}
            setOrganization={(value) =>
              setState((prevState) => ({ ...prevState, organization: value }))
            }
            setSearchInput={(value) =>
              setState((prevState) => ({ ...prevState, searchInput: value }))
            }
            setEndDate={(value) =>
              setState((prevState) => ({ ...prevState, endDate: value }))
            }
            setStartDate={(value) =>
              setState((prevState) => ({ ...prevState, startDate: value }))
            }
            setPayment={(value) =>
              setState((prevState) => ({
                ...prevState,
                invoicePaymentStatus: value,
              }))
            }
            isInvoice={true}
          />
        </SimpleSearchContainer>
      </div>
      <div style={{ padding: '0 20px 27px 20px' }}>
        <InvoiceList
          invoices={invoices}
          pendingCount={pendingCount}
          approvedCount={approvedCount}
          declinedCount={declinedCount}
          changeRequestCount={changeRequestCount}
          totalCount={totalCount}
          loading={isLoading}
          pageSize={pageSize}
          paginationServer
          setSearchInput={(value) =>
            setState((prevState) => ({ ...prevState, searchInput: value }))
          }
          statusInput={statusInput}
          setStatusInput={(value) =>
            setState((prevState) => ({ ...prevState, statusInput: value }))
          }
          paginationTotalRows={totalCount}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          setPayment={(value) =>
            setState((prevState) => ({
              ...prevState,
              invoicePaymentStatus: value,
            }))
          }
          state={state}
        />
      </div>
    </section>
  );
};

export default InvoiceView;
