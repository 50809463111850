import React from 'react';
import { BsSearch } from 'react-icons/bs';
import 'react-datepicker/dist/react-datepicker.css';
import { USER_ROLES } from 'store/reducers/auth/utils';

// Custom Components
import {
  CustomTableSearch,
  MobileSearchComponentContainer,
  SearchComponentContainer,
} from 'app/components/atoms/divs/div.styles';
import OrganizationSearchComponent from '../organizationSearch/organization-search.component';
import { useCheckAdmin, useViewport } from 'hooks';
import { useState } from 'react';
import RecurTypeSearchComponent from '../recurTypeSearch/recur-type-search-component';
import Sort from 'app/components/molecules/sort/sort.component';
import ReceiptTypeComponent from '../receieptType/receipt-type.component';
import { isEmpty } from 'lodash';
import Text from 'app/components/atoms/typography';
import Popup from 'reactjs-popup';
import Card from 'app/components/atoms/card/card.component';
import { invoiceSelectors } from 'store/reducers/invoice/invoiceSlice';
import { useSelector } from 'react-redux';
import { NoStyleButton } from 'app/components/atoms/button/button.styles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants/constants.utils';
const ReferenceButton = () => {
  const selectedInvoices = useSelector(invoiceSelectors.selectedInvoices);
  const navigate = useNavigate();

  const handleClick = () => {
    if (isEmpty(selectedInvoices)) {
      navigate(ROUTES.PROFORMA.HOME);
    } else {
      navigate(ROUTES.PROFORMA.GENERATE, { state: selectedInvoices });
    }
  };
  return (
    <Popup
      trigger={
        <CustomTableSearch
          as={'button'}
          style={{ borderColor: 'var(--primary)', textAlign: 'center' }}
        >
          <NoStyleButton
            disabled={isEmpty(selectedInvoices)}
            onClick={handleClick}
          >
            <Text size={'16px'} weight="700" color="var(--primary)">
              {isEmpty(selectedInvoices)
                ? 'Proforma Invoices'
                : 'Generate Payment Reference'}
            </Text>
          </NoStyleButton>
        </CustomTableSearch>
      }
      position={'bottom right'}
      on={['hover', 'focus']}
    >
      <Card width="15.625rem">
        Select at least one invoice to generate a payment reference
      </Card>
    </Popup>
  );
};
const SearchComponent = ({
  setSearchInput,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setOrganization,
  setPayment,
  setRecurType,
  setReceiptType,
  isInvoice = false,
  isReciept = false,
  isRecurring = false,
}) => {
  const { role } = useCheckAdmin();
  const [input, setInput] = useState();
  const { mobile } = useViewport();

  const handleStartDateChange = (date) => {
    if (date && !date.includes('undefined')) {
      const dates = date.split(' - ');
      setStartDate(new Date(dates[0]).toISOString());
      setEndDate(new Date(dates[1]).toISOString());
    }
  };

  const searchComponents = [
    isRecurring
      ? {
          order: 1,
          component: <RecurTypeSearchComponent setRecurType={setRecurType} />,
          gridRow: '2',
          name: 'recurType',
          parent: 'recurType',
        }
      : null,

    role === USER_ROLES.CLIENT_USER
      ? {
          order: 2,
          component: (
            <OrganizationSearchComponent setOrganization={setOrganization} />
          ),
          gridRow: '2',
          name: 'organization',
          parent: 'organization',
        }
      : null,

    {
      order: 3,
      component: (
        <Sort
          filter={'date-range'}
          dateRange={`${new Date(startDate).toLocaleDateString()} - ${new Date(
            endDate
          ).toLocaleDateString()}`}
          setDateRange={handleStartDateChange}
        />
      ),
      gridRow: '2',
      name: 'startDateComponent',
      parent: 'startDateComponent',
    },

    isReciept && role !== USER_ROLES.CLIENT_USER
      ? {
          order: 4,
          component: <ReceiptTypeComponent setReceiptType={setReceiptType} />,
          gridRow: '2',
          name: 'recieptType',
          parent: 'recieptType',
        }
      : null,
    isInvoice && role === USER_ROLES.CLIENT_USER
      ? {
          order: 5,
          component: (
            <CustomTableSearch>
              <input
                type="text"
                placeholder="Search"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <BsSearch
                onClick={() => setSearchInput(input)}
                className="search-icon"
              />
            </CustomTableSearch>
          ),
          style: {
            marginLeft: 'auto',
          },
          name: 'searchInput',
          parent: 'searchInput',
        }
      : null,
    role !== USER_ROLES.CLIENT_USER && isInvoice
      ? {
          order: 5,
          component: <ReferenceButton />,
          style: {
            marginLeft: 'auto',
          },
          name: 'searchInput',
          parent: 'searchInput',
        }
      : null,
  ].filter((componentProps) => componentProps !== null);

  const mobileSearchComponents = [
    {
      order: 1,
      component: (
        <Sort
          filter={'date-range'}
          dateRange={`${new Date(startDate).toLocaleDateString()} - ${new Date(
            endDate
          ).toLocaleDateString()}`}
          setDateRange={handleStartDateChange}
        />
      ),
    },

    isRecurring
      ? {
          order: 2,
          component: <RecurTypeSearchComponent setRecurType={setRecurType} />,
        }
      : null,
    isReciept && role !== USER_ROLES.CLIENT_USER
      ? {
          order: 2,
          component: <ReceiptTypeComponent setReceiptType={setReceiptType} />,
          gridRow: '2',
          name: 'recieptType',
          parent: 'recieptType',
        }
      : null,
    role === USER_ROLES.CLIENT_USER
      ? {
          order: 3,
          component: (
            <OrganizationSearchComponent setOrganization={setOrganization} />
          ),
        }
      : null,
    role === USER_ROLES.CLIENT_USER && isInvoice
      ? {
          order: 4,
          component: (
            <CustomTableSearch>
              <input
                type="text"
                placeholder="Search"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <BsSearch
                onClick={() => setSearchInput(input)}
                className="search-icon"
              />
            </CustomTableSearch>
          ),
        }
      : null,
    role !== USER_ROLES.CLIENT_USER && isInvoice
      ? {
          order: 4,
          component: <ReferenceButton />,
        }
      : null,
  ].filter((componentProps) => componentProps !== null);

  return mobile ? (
    <MobileSearchComponentContainer>
      {mobileSearchComponents
        .sort((prop) => prop.order)
        .map((componentProps, index) => {
          return (
            <div key={index} style={{ gridColumn: 2, gridRow: index + 1 }}>
              {componentProps.component}
            </div>
          );
        })}
    </MobileSearchComponentContainer>
  ) : (
    <SearchComponentContainer>
      {searchComponents
        .sort((prop) => prop.order)
        .map((componentProps, index) => {
          return (
            <div key={index} style={componentProps.style}>
              {componentProps.component}
            </div>
          );
        })}
    </SearchComponentContainer>
  );
};

export default SearchComponent;
