import Button from 'app/components/atoms/button/button.component';
import { MobileResponsiveButton } from 'app/components/atoms/divs/div.styles';
import Text, { Heading } from 'app/components/atoms/typography';
import { useCheckAdmin, useViewport } from 'hooks';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import ChargeMobileView from './charge.mobile';
import { TableContainer, TableHeader } from '../reminder/clientsTable';
import Card from 'app/components/atoms/card/card.component';
import { useEffect, useState } from 'react';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { BsSearch } from 'react-icons/bs';
import Table from 'app/components/molecules/table/table.component';
import { NoStyleButton } from 'app/components/atoms/button/button.styles';
import ModalHouse from 'app/components/molecules/modals/modalHouse';
import { NewChargeModal } from './newCharge';
import {
  CurrencyActions,
  CurrencySelectors,
} from 'store/reducers/currency/currencySlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChargeActions,
  ChargeSelectors,
} from 'store/reducers/charge/chargeSlice';
const ChargeContainer = styled.div`
  padding: 25px 11px;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;
const Charge = () => {
  const dispatch = useDispatch();
  const { mobile } = useViewport();
  const { role } = useCheckAdmin();
  const [searchInput, setSearchInput] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [data, setData] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [openCreate, setOpenCreate] = useState(false);
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const { getCurrencies } = CurrencyActions;
  const { getCharges, getUnitTypes } = ChargeActions;
  const currencies = useSelector(CurrencySelectors.currencies);
  const charges = useSelector(ChargeSelectors.charges);
  const isLoading = useSelector(ChargeSelectors.isLoading);

  const handleEdit = (row) => {
    setData(row);
    setOpenCreate(true);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPageSize(newPerPage);
    setPage(page);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const columns = [
    {
      name: 'Charge',
      selector: (row) => `${row.serviceCharge}${row.isPercentage ? '%' : ''}`,
    },
    {
      name: 'Charge Type',
      selector: (row) => row.serviceChargeTypeName,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Currency',
      selector: (row) => row.currency,
    },
    {
      name: 'Min Amount',
      selector: (row) => row.minimumAmount,
      grow: 2,
      sortable: true,
    },
    {
      name: 'Max Amount',
      selector: (row) => row.maximumAmount,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Cap',
      selector: (row) => row.cap,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <NoStyleButton onClick={() => handleEdit(row)}>
          <Text
            color="var(--primary)"
            size="14px"
            weight="700"
            style={{ textDecoration: 'underline' }}
          >
            Edit
          </Text>
        </NoStyleButton>
      ),
      button: true,
    },
  ];
  useEffect(() => {
    dispatch(getCharges());
    dispatch(getUnitTypes());
    setReload(false);
  }, [reload]);
  useEffect(() => {
    if (currencies?.length <= 0) {
      dispatch(getCurrencies());
    }
  }, [currencies]);
  const handleClose = () => {
    setOpenCreate(false);
    setData(null);
  };
  return (
    <>
      <ChargeContainer>
        <Heading color="var(--primary2)" size={mobile ? 18 : 32}>
          Charges
        </Heading>

        {mobile ? (
          <MobileResponsiveButton onClick={() => setOpenCreate(true)}>
            <FaPlus />
            <Text color="#fff" weight={700} margin="0 0 0 6px !important">
              Add Charge
            </Text>
          </MobileResponsiveButton>
        ) : (
          <Button
            padding="16px 24px"
            maxWidth="max-content"
            onClick={() => setOpenCreate(true)}
          >
            <FaPlus size={'20px'} />
            <Text color="#fff" weight={700} margin="0 0 0 8px !important">
              Add Charge
            </Text>
          </Button>
        )}

        {mobile ? (
          <ChargeMobileView
            setFilterInput={setFilterInput}
            charges={charges}
            handleEdit={handleEdit}
            loading={isLoading}
            role={role}
            USER_ROLES={USER_ROLES}
            onChangePage={handlePageChange}
            page={page}
            setPage={setPage}
            pageCount={Math.ceil(charges.length / pageSize)}
          />
        ) : (
          <TableContainer>
            <Card>
              <div>
                <TableHeader>
                  <Heading color=" var(--primary2)" level={2}>
                    Charges
                  </Heading>

                  <div className="search field">
                    <BsSearch
                      onClick={() => {
                        setFilterInput(searchInput);
                      }}
                    />
                    <input
                      type="text"
                      style={{ outline: 'none' }}
                      placeholder="Search"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                </TableHeader>

                <Text
                  style={{
                    borderBottom: '2px solid #3A3E47',
                    width: 'fit-content',
                    padding: '8px',
                    marginBottom: '8px',
                  }}
                  color="#3A3E47"
                >
                  All ({charges.length})
                </Text>
                <Table
                  title={
                    <Text
                      style={{
                        borderBottom: '2px solid #3A3E47',
                        width: 'fit-content',
                        padding: '8px',
                      }}
                      color="#3A3E47"
                    >
                      All (charges.length)
                    </Text>
                  }
                  columns={columns}
                  data={charges}
                  totalCount={charges.length}
                  selectableRows={false}
                  pagination
                  progressPending={isLoading}
                  actions={<></>}
                  paginationServer
                  paginationTotalRows={charges.length}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </div>
            </Card>
          </TableContainer>
        )}
      </ChargeContainer>
      <ModalHouse
        className="modal modal__extra"
        close={handleClose}
        isOpen={openCreate}
        form={
          <NewChargeModal
            currencies={currencies}
            reload={reload}
            setReloaad={setReload}
            close={handleClose}
            data={data}
          />
        }
        title={data ? 'Edit Charge' : 'New Charge'}
        height="500px"
      />
    </>
  );
};

export default Charge;
