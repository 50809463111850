import styled from 'styled-components';

export const DropDownContainerStyle = styled.div`
  position: absolute;
  left: ${({ left }) => left || '0px'};
  right: ${({ right }) => right || '0px'};
  top: ${({ top }) => top || '50px'};
  z-index: 1;
  background: ${({ bg }) => bg || '#fff'};
  display: flex;
  flex-direction: column;
  text-align: center;
  width: ${({ width }) => width || '100%'};
  border: ${({ border }) => border};
  border-radius: ${({ radius }) => radius || '12px'};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height || '300px'};
  overflow-y: auto;
  box-shadow: ${({ shadow }) => shadow || '0px 4px 4px rgba(38, 50, 56, 0.5)'};
  > .content {
    cursor: pointer;
    width: 100%;
    border-top: 1px solid var(--grey5);
    padding: 1rem;
    &:hover {
      background-color: var(--grey5);
    }
  }
  .select {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      border-top: 1px solid var(--grey5);
    }
  }
  /* .dropdown_text{
    border-bottom:1px solid red;
  } */
`;
