import React, { useState } from 'react';
import { useOnClickOutside } from 'hooks';
import DropdownComponent from 'app/components/molecules/drop-down/drop-down.component';
import { RECEIPT_TYPES } from 'utils/constants/constants.utils';
import { authSelectors } from 'store/reducers/auth/authSlice';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { useSelector } from 'react-redux';

const ReceiptType = ({ setReceiptType, ...props }) => {
  const { visible, setVisible, ref } = useOnClickOutside(false);
  const userRole = useSelector(authSelectors.userRole);

  const [selectedLabel, setSelectedLabel] = useState(
    userRole === USER_ROLES.CLIENT_USER
      ? RECEIPT_TYPES.option[0]?.label
      : RECEIPT_TYPES.option[1]?.label
  );

  const toggleVisible = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  return (
    <div ref={ref}>
      <DropdownComponent
        visible={visible}
        toggleVisible={toggleVisible}
        options={RECEIPT_TYPES.option}
        selectName={RECEIPT_TYPES.label}
        height={'max-content'}
        onSelect={(value) => {
          setReceiptType(value);
          setSelectedLabel(
            RECEIPT_TYPES.option.find((option) => option.value === value)
              ?.label || ''
          );
        }}
        label={selectedLabel}
        {...props}
      />
    </div>
  );
};

export default React.memo(ReceiptType);
