import Text, { Heading } from 'app/components/atoms/typography';
import { useEffect } from 'react';
import { useState } from 'react';
import ReceiptDesktop from './receiptDesktop';
import ReceiptMobile from './receiptMobile';
import { ListContainer } from './receipt.style';
import { useDispatch, useSelector } from 'react-redux';
import {
  receiptActions,
  receiptSelectors,
} from 'store/reducers/receipt/receiptSlice';
import { authSelectors } from 'store/reducers/auth/authSlice';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { MOBILE_WINDOW_SIZE } from 'utils/constants/constants.utils';
import {
  SimpleSearchContainer,
  TopBanner,
} from 'app/components/atoms/divs/div.styles';
import SearchComponent from 'app/components/cell/searchComponent/searchComponent';

const ReceiptContent = () => {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState('');
  const [organization, setOrganization] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const receipts = useSelector(receiptSelectors.receipts);
  const totalCount = useSelector(receiptSelectors.totalCount);
  const pageCount = useSelector(receiptSelectors.pageCount);
  const isLoading = useSelector(receiptSelectors.isLoading);
  const userRole = useSelector(authSelectors.userRole);

  const [receiptType, setReceiptType] = useState(
    userRole === USER_ROLES.CLIENT_USER ? 1 : 2
  );

  const { search } = receiptActions;
  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      search({
        page,
        pageSize: newPerPage,
        startDate,
        endDate,
        filter: searchInput,
        organizationId: organization,

        receiptType,
      })
    );
    setPageSize(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(
      search({
        page: page,
        pageSize,
        startDate: startDate,
        endDate: endDate,
        filter: searchInput,
        organizationId: organization,
        receiptType,
      })
    );

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, searchInput, organization, page, receiptType]);
  return (
    <ListContainer>
      <TopBanner>
        <div className="dashboard-container client-invoice__new">
          <div>
            <Heading color="#002776">Receipts</Heading>
            <Text>Manage your receipts and transactions</Text>
          </div>
        </div>
      </TopBanner>
      <SimpleSearchContainer>
        <SearchComponent
          startDate={startDate}
          endDate={endDate}
          organization={organization}
          setOrganization={setOrganization}
          setSearchInput={setSearchInput}
          setEndDate={setEndDate}
          setReceiptType={setReceiptType}
          setStartDate={setStartDate}
          isReciept={true}
        />
      </SimpleSearchContainer>
      <div style={{ paddingInline: '1rem' }}>
        {windowWidth < MOBILE_WINDOW_SIZE && (
          <ReceiptMobile
            view={receiptType === 1 ? 'invoices' : 'bills'}
            receipts={receipts}
            page={page}
            pageCount={pageCount}
            loading={isLoading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            paginationServer
            paginationTotalRows={totalCount}
            isClient={userRole === USER_ROLES.CLIENT_USER}
            onChangePage={handlePageChange}
          />
        )}

        {windowWidth >= MOBILE_WINDOW_SIZE && (
          <ReceiptDesktop
            view={receiptType === 1 ? 'invoices' : 'bills'}
            receipts={receipts}
            totalCount={totalCount}
            loading={isLoading}
            paginationServer
            paginationTotalRows={totalCount}
            isClient={userRole === USER_ROLES.CLIENT_USER}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        )}
      </div>
    </ListContainer>
  );
};

export default ReceiptContent;
