export function extractInvoicesAndReferences(inputString) {
  const regex = /INV-[A-Za-z0-9]+ --> [A-Za-z0-9]+/g;

  const matches = inputString.match(regex);

  const groupedInvoices = {};

  if (matches) {
    matches.forEach((match) => {
      const [invoice, reference] = match.split(' --> ');

      if (!groupedInvoices[reference]) {
        groupedInvoices[reference] = [];
      }
      groupedInvoices[reference].push(invoice);
    });

    return Object.keys(groupedInvoices).map((reference) => ({
      invoices: groupedInvoices[reference],
      reference: reference,
    }));
  }

  return [];
}
