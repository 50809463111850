// Import necessary components and libraries
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'store/reducers/auth/authSlice';
import { createOptionsObject } from 'utils/helpers/createOptionsObject/createOptionsObject';
import { invoiceActions } from 'store/reducers/invoice/invoiceSlice';
import { recurringInvoiceActions } from 'store/reducers/recurringInvoice/recurringInvoiceSlice';
import { useCheckAdmin, useOnClickOutside, useViewport } from 'hooks';
import { USER_ROLES } from 'store/reducers/auth/utils';
import InputCard from 'app/components/atoms/input-card/input-card.component';
import Search from '../search/search.component';
import Text from 'app/components/atoms/typography';
import { DropDownContainerStyle } from 'app/components/atoms/dropdown/dropdow.styles';
import { BsFillTagFill } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { FiPlus } from 'react-icons/fi';
import { GrClear } from 'react-icons/gr';
import ReactDatePicker from 'react-datepicker';
import { FaRegCalendarAlt } from 'react-icons/fa';

const Sort = ({
  setStatusInput,
  statusInput,
  items,
  placeholder,
  size,
  filter,
  dateRange,
  setDateRange,
  setSearchInput,
  searchParams,
  setSearchParams,
  children,
  ...props
}) => {
  const [clientShowDrop, setClientShowDrop] = useState(false);
  const organizations = useSelector(authSelectors.organizations);
  const [setOrg, setSetOrg] = useState('');
  const { advanceSearch } = invoiceActions;
  const { role } = useCheckAdmin();
  const { searchRecurringInvoices } = recurringInvoiceActions;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const organizationsObj = createOptionsObject(
    organizations,
    'id',
    'businessName'
  );
  const { visible, setVisible, ref } = useOnClickOutside(false);
  const { mobile } = useViewport();
  const handleSelect = (ranges) => {
    const [startDate, endDate] = ranges;
    setState({ startDate, endDate });
    setDateRange(
      `${startDate?.toLocaleDateString()} - ${
        endDate?.toLocaleDateString() ?? startDate?.toLocaleDateString()
      }`
    );
  };
  const handleSetClient = (client) => {
    setSetOrg(client.fullName);
    setSearchInput(client);
  };
  return (
    <div ref={ref}>
      {filter !== 'date-range' && (
        <InputCard
          {...props}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {children}
          {filter === 'status' && (
            <>
              <BsFillTagFill />
              {statusInput}
              {visible && (
                <DropDownContainerStyle>
                  {items.option?.map((item, i) => (
                    <div
                      className="content"
                      key={i}
                      onClick={(e) => {
                        if (items.label === 'Status') {
                          dispatch(
                            advanceSearch({
                              ...searchParams,
                              status: item.value,
                            })
                          );
                        } else {
                          dispatch(
                            searchRecurringInvoices({
                              ...searchParams,
                              status: item.value,
                            })
                          );
                        }
                        setStatusInput(item.label);
                      }}
                    >
                      {item.label ? item.label : ''}
                    </div>
                  ))}
                </DropDownContainerStyle>
              )}
              <RiArrowDropDownLine
                style={{ marginLeft: '20px' }}
                color="var(--grey2)"
                size={'20px'}
              />
            </>
          )}
          {filter === 'clients' && (
            <>
              {role !== USER_ROLES.ORGANIZATION_ADMIN &&
                role !== USER_ROLES.ORGANIZATION_USER && <FiPlus />}
              <span style={{ whiteSpace: 'nowrap' }}>
                {setOrg.length === 0 ? 'All Clients' : setOrg}
              </span>
              {visible && (
                <DropDownContainerStyle
                  top="50px"
                  background="none"
                  width="max-content"
                >
                  <Search
                    style={{
                      borderRadius: '12px',
                      boxShadow: 'none',
                      backgroundColor: 'white',
                    }}
                    onClick={(e) => e.stopPropagation()}
                    placeholder="Client"
                    size={mobile ? '12px' : ''}
                    setSearchInput={setSearchInput}
                  />
                  {role !== USER_ROLES.ORGANIZATION_ADMIN &&
                    role !== USER_ROLES.ORGANIZATION_USER && (
                      <>
                        <Text> Or</Text>
                        <InputCard
                          className="select"
                          onClick={(e) => {
                            setClientShowDrop(!clientShowDrop);
                            e.stopPropagation();
                          }}
                        >
                          {setOrg.length > 0 && (
                            <GrClear
                              style={{
                                width: '40px',
                                height: '40px',
                              }}
                              onClick={(e) => {
                                setSetOrg('');
                                setSearchInput('');
                                dispatch(
                                  advanceSearch({
                                    ...searchParams,
                                    startDate: dateRange[0].startDate,
                                    endDate: dateRange[0].endDate,
                                  })
                                );
                                e.stopPropagation();
                              }}
                              color="var(--accent)"
                            />
                          )}

                          {setOrg.length === 0 ? (
                            <Text weight="700">Select Org</Text>
                          ) : (
                            setOrg
                          )}
                          {clientShowDrop && (
                            <DropDownContainerStyle>
                              {organizationsObj?.map(({ value, label }) => (
                                <div
                                  className="content"
                                  key={value}
                                  onClick={() => {
                                    dispatch(
                                      advanceSearch({
                                        ...searchParams,
                                        organizationId: value,
                                        startDate: dateRange[0]?.startDate,
                                        endDate: dateRange[0]?.endDate,
                                      })
                                    );
                                    setSetOrg(label);
                                    setSearchInput(label);
                                  }}
                                >
                                  {label}
                                </div>
                              ))}
                            </DropDownContainerStyle>
                          )}
                          <RiArrowDropDownLine
                            style={{
                              marginLeft: '20px',
                              width: '40px',
                              height: '40px',
                            }}
                            color="var(--accent)"
                          />
                        </InputCard>
                      </>
                    )}

                  {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                    role === USER_ROLES.ORGANIZATION_USER) &&
                    items?.map((item) => (
                      <div
                        className="content"
                        key={item.id}
                        onClick={() => handleSetClient(item)}
                        style={{
                          whiteSpace: 'nowrap',
                          minWidth: 'max-content',
                        }}
                      >
                        {item.fullName}
                      </div>
                    ))}
                </DropDownContainerStyle>
              )}
              <RiArrowDropDownLine
                style={{ marginLeft: '10px', flexShrink: 0 }}
                color="var(--grey2)"
              />
            </>
          )}
        </InputCard>
      )}

      {filter === 'date-range' && (
        <>
          <InputCard
            {...props}
            onClick={() => {
              setVisible(true);
            }}
            size={18}
          >
            <FaRegCalendarAlt size={20} color="var(--accent)" />
            {dateRange ?? 'Date Range'}

            <RiArrowDropDownLine
              style={{ marginLeft: '10px' }}
              color="var(--grey2)"
              size={30}
            />
            {visible && (
              <DropDownContainerStyle
                width={'15.2rem'}
                top="100%"
                height="auto"
                background="none"
              >
                <ReactDatePicker
                  selected={state.startDate}
                  onChange={handleSelect}
                  startDate={state.startDate}
                  endDate={state.endDate}
                  selectsRange
                  inline
                />
              </DropDownContainerStyle>
            )}
          </InputCard>
        </>
      )}
    </div>
  );
};

export default Sort;
