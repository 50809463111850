import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { USER_ROLES } from './utils';
import { encryptData } from 'utils/helpers/encryptionHelper/encryptor';

const entity = 'auth';

export const initialState = {
  id: '',
  /**
   * @type {USER_ROLES}
   */
  role: null,
  fullName: '',
  email: '',
  notificationCount: 0,
  token: '',
  refreshToken: '',
  businessName: '',
  logo: '',
  profilePicture: null,
  plan: null,
  organizations: [],
  loading: false,
  isAdmin: false,
  sessionTimeout: false,
};

const authenticateUser = createAsyncThunk(
  `${entity}/login`,
  async (payload) => {
    const res = await api.auth.login({ credentials: encryptData(payload) });
    return res.data.data;
  }
);

const deAuthenticate = createAsyncThunk(`${entity}/logout`, async () => {
  await api.auth.logout();
});

const refreshUserToken = createAsyncThunk(
  `${entity}/refresh-token`,
  async (payload) => {
    const res = await api.auth.refreshToken(payload);
    return res.data.data;
  }
);

const googleSignIn = createAsyncThunk(
  `${entity}/google-sign-in`,
  async (payload) => {
    const res = await api.auth.googleLogin(payload);
    return res.data;
  }
);

const facebookSignIn = createAsyncThunk(
  `${entity}/facebook-sign-in`,
  async (payload) => {
    const res = await api.auth.facebookLogin(payload);
    return res.data;
  }
);

const microsoftSignIn = createAsyncThunk(
  `${entity}/microsoft-sign-in`,
  async (payload) => {
    const res = await api.auth.microsoftLogin(payload);
    return res.data;
  }
);

const organizationSignUp = createAsyncThunk(
  `${entity}/organization-signup`,
  async (payload) => {
    const res = await api.auth.organizationSignup(payload);
    return res.data;
  }
);

const clientUserSignUp = createAsyncThunk(
  `${entity}/client-user-signup`,
  async (payload) => {
    const res = await api.auth.clientUserSignup(payload);
    return res.data;
  }
);

const forgotPassword = createAsyncThunk(
  `${entity}/forgot-password`,
  async (email) => {
    const res = await api.auth.forgotPassword(email);
    return res.data.data;
  }
);

const resetPassword = createAsyncThunk(
  `${entity}/reset-password`,
  async (payload) => {
    const res = await api.auth.resetPassword(payload);
    return res.data;
  }
);

const confirmInvite = createAsyncThunk(
  `${entity}/confirm-invite`,
  async (payload) => {
    const res = await api.auth.confirmInvite(payload);
    return res.data;
  }
);

const confirmEmail = createAsyncThunk(
  `${entity}/confirm-email`,
  async (payload) => {
    const res = await api.auth.confirmEmail(payload);
    return res.data;
  }
);

const changePassword = createAsyncThunk(
  `${entity}/change-password`,
  async (payload) => {
    //const payloadEnc = encryptData(payload)
    const res = await api.auth.changePassword(payload);
    return res.data;
  }
);

const authSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    checkAdmin: (state) => {
      state.isAdmin = state.role === USER_ROLES.EASEI_ADMIN;
    },
    setSessionTimeout: (state) => {
      state.sessionTimeout = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(authenticateUser.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(googleSignIn.fulfilled, (state, action) => {
        return { ...state, ...action.payload?.data, loading: false };
      })
      .addCase(googleSignIn.pending, (state, action) => {
        return { ...state, ...action.payload?.data, loading: true };
      })
      .addCase(googleSignIn.rejected, (state, action) => {
        return { ...state, ...action.payload?.data, loading: false };
      });
    builder
      .addCase(facebookSignIn.fulfilled, (state, action) => {
        return { ...state, ...action.payload?.data, loading: false };
      })
      .addCase(facebookSignIn.pending, (state, action) => {
        return { ...state, ...action.payload?.data, loading: true };
      })
      .addCase(facebookSignIn.rejected, (state, action) => {
        return { ...state, ...action.payload?.data, loading: false };
      });
    builder
      .addCase(microsoftSignIn.fulfilled, (state, action) => {
        return { ...state, ...action.payload?.data, loading: false };
      })
      .addCase(microsoftSignIn.pending, (state, action) => {
        return { ...state, ...action.payload?.data, loading: true };
      })
      .addCase(microsoftSignIn.rejected, (state, action) => {
        return { ...state, ...action.payload?.data, loading: false };
      });
    builder
      .addCase(organizationSignUp.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(organizationSignUp.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(organizationSignUp.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(clientUserSignUp.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(clientUserSignUp.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(clientUserSignUp.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(forgotPassword.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(forgotPassword.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(resetPassword.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(resetPassword.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(resetPassword.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(confirmEmail.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(confirmEmail.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(confirmInvite.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(confirmInvite.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(confirmInvite.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(changePassword.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(changePassword.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(changePassword.rejected, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      });
    builder
      .addCase(refreshUserToken.fulfilled, (state, action) => {
        if (action.payload?.accessToken && action.payload?.refreshToken) {
          state.token = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        }
        state.loading = false;
      })
      .addCase(refreshUserToken.pending, (state, action) => {
        return { ...state, ...action.payload, loading: true };
      })
      .addCase(refreshUserToken.rejected, () => {
        return { loading: false };
      });
  },
});

export const authActions = {
  ...authSlice.actions,
  deAuthenticate,
  authenticateUser,
  organizationSignUp,
  clientUserSignUp,
  resetPassword,
  forgotPassword,
  googleSignIn,
  facebookSignIn,
  microsoftSignIn,
  confirmEmail,
  confirmInvite,
  ChangePassword: changePassword,
  refreshUserToken,
};

export const authSelectors = {
  isAuthenticated: (state) => state.auth.token,
  fullName: (state) => state.auth.fullName,
  email: (state) => state.auth.email,
  userName: (state) => state.auth.fullName.split(' ')[0],
  userCompanyName: (state) => state.auth.businessName,
  userId: (state) => state.auth.id,
  userRole: (state) => state.auth.role,
  isPlanActive: (state) => state.auth.plan?.isActive,
  allowedFeatures: (state) => state.auth.plan?.allowedFeatures,
  organizations: (state) => state.auth.organizations,
  profilePicture: (state) => state.auth.profilePicture,
  organizationsForDropdown: (state) =>
    state?.auth?.organizations?.map(function (item) {
      return {
        ...item,
        value: item.id,
        label: item.businessName,
        address:
          item.street === null || item.state === null || item.country === null
            ? ''
            : item?.street + ' ' + item?.state + ' ' + item?.country,
      };
    }),
  isLoading: (state) => state.auth.loading,
  isAdmin: (state) => state.auth.isAdmin,
  sessionTimeout: (state) => state.auth.sessionTimeout,
};

export default authSlice.reducer;
