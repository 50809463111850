import styled from 'styled-components';

export const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  .left,
  .right {
    position: absolute;
    top: 2em;
    background-color: var(--primary1);
    border-radius: 100%;
    color: var(--accent);
    padding: 5px;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }

  @media screen and (min-width: 850px) {
    top: 3.8em;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  p {
    text-align: left;
  }

  .slide {
    flex: 0 0 auto;
    width: ${({ width }) => width || '100%'};
    margin-right: 10px;
  }
  .stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    &__inner {
      justify-content: flex-start;
      * + * {
        margin-left: 10px;
      }
      p {
        display: flex;
        align-items: center;
      }
    }
  }
`;
export const CarouselNavigation = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--primary1);
  border-radius: 100%;
`;
