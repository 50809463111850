import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';

const entity = 'Settings';

export const initialState = {
  data: [],
  users: [],
  bankList: [],
  totalCount: 0,
  organization: {},
  user: {},
  account: {},
  pageCount: 0,
  loading: false,
  url: '',
  featureTypes: [],
};

const searchUsers = createAsyncThunk(
  `${entity}/search-users`,

  async (input) => {
    const { page = 1, pageSize = 10, role, filter } = input;
    const res = await api.settings.search(page, pageSize, role, filter);
    return res.data;
  }
);

const uploadFile = createAsyncThunk(`${entity}/upload-file`, async (input) => {
  const res = await api.settings.uploadFile(input);
  return res.data;
});

const updateProfilePicture = createAsyncThunk(
  `${entity}/upload-user-image`,
  async (input) => {
    const res = await api.settings.updateProfilePicture(input);
    return res.data;
  }
);

const onboardMerchantAdmin = createAsyncThunk(
  `${entity}/onboardMerchantAdmin`,
  async (input) => {
    const res = await api.settings.onboardMerchantAdmin(input);
    return res.data;
  }
);

const closeAccount = createAsyncThunk(
  `${entity}/initiate-account-closure`,
  async (input) => {
    const res = await api.settings.closeAccount(input);
    return res.data;
  }
);

const verifyOTP = createAsyncThunk(`${entity}/verifyOTP`, async (input) => {
  const res = await api.otp.verifyOTP(input);
  return res.data;
});

const updateOrganizationDetails = createAsyncThunk(
  `${entity}/updateOrganizationDetails`,
  async (input) => {
    const res = await api.settings.updateOrganizationDetails(input);
    return res.data;
  }
);

const getOrganizationDetails = createAsyncThunk(
  `${entity}/getOrganizationDetails`,
  async () => {
    const res = await api.settings.getOrganizationDetails();
    return res.data;
  }
);
const updateAccountDetails = createAsyncThunk(
  `${entity}/updateAccountDetails`,
  async (input) => {
    const res = await api.settings.updateAccountDetails(input);
    return res.data;
  }
);
const updateUserDetails = createAsyncThunk(
  `${entity}/updateUserDetails`,
  async (input) => {
    const res = await api.settings.updateUserDetails(input);
    return res.data;
  }
);

const getBankList = createAsyncThunk(`${entity}/getBankList`, async () => {
  const res = await api.settings.getBankList();
  return res.data?.data;
});

const getUserDetails = createAsyncThunk(
  `${entity}/getUserDetails`,
  async () => {
    const res = await api.settings.getUserDetails();
    return res.data;
  }
);
const getAccountDetails = createAsyncThunk(
  `${entity}/getAccountDetails`,
  async () => {
    const res = await api.settings.getAccountDetails();
    return res.data;
  }
);

const inviteUsers = createAsyncThunk(`${entity}/inviteUsers`, async (input) => {
  const res = await api.settings.inviteUsers(input);
  return res.data;
});

const uploadUsersCsv = createAsyncThunk(
  `${entity}/upload-users-csv`,
  async (input) => {
    const res = await api.settings.uploadUsersCsv(input);
    return res.data;
  }
);

const userActivation = createAsyncThunk(
  `${entity}/user-activation`,
  async (input) => {
    const res = await api.settings.userActivation(input);
    return res.data;
  }
);

const getFeatureTypes = createAsyncThunk(`${entity}/planFeatures`, async () => {
  const res = await api.settings.getFeatureTypes();
  return res.data;
});

const settingsSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.pageCount = action.payload.pageCount;
        state.loading = false;
      })
      .addCase(searchUsers.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(searchUsers.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getOrganizationDetails.fulfilled, (state, action) => {
        state.organization = action.payload.data;
        state.loading = false;
      })
      .addCase(getOrganizationDetails.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getOrganizationDetails.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.loading = false;
      })
      .addCase(getUserDetails.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getUserDetails.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getAccountDetails.fulfilled, (state, action) => {
        state.account = action.payload.data;
        state.loading = false;
      })
      .addCase(getAccountDetails.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getAccountDetails.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.url = action.payload.data;
        state.loading = false;
      })
      .addCase(uploadFile.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(uploadFile.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(updateProfilePicture.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(updateProfilePicture.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updateProfilePicture.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(onboardMerchantAdmin.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(onboardMerchantAdmin.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(onboardMerchantAdmin.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(closeAccount.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(closeAccount.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(closeAccount.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(verifyOTP.fulfilled, (state, action) => {
        return { ...state, loading: false };
      })
      .addCase(verifyOTP.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(verifyOTP.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(updateOrganizationDetails.fulfilled, (state, action) => {
        return { ...state, loading: false };
      })
      .addCase(updateOrganizationDetails.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updateOrganizationDetails.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        return { ...state, loading: false };
      })
      .addCase(updateUserDetails.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updateUserDetails.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(updateAccountDetails.fulfilled, (state, action) => {
        return { ...state, loading: false };
      })
      .addCase(updateAccountDetails.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updateAccountDetails.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(inviteUsers.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(inviteUsers.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(inviteUsers.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(uploadUsersCsv.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(uploadUsersCsv.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(uploadUsersCsv.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(userActivation.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(userActivation.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(userActivation.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getFeatureTypes.fulfilled, (state, action) => {
        state.featureTypes = action.payload;
        state.loading = false;
      })
      .addCase(getFeatureTypes.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getFeatureTypes.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getBankList.fulfilled, (state, action) => {
        state.bankList = action.payload?.map((bank) => ({
          ...bank,
          label: bank?.bankName,
          value: bank?.bankName,
        }));
        state.loading = false;
      })
      .addCase(getBankList.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getBankList.rejected, (state) => {
        return { ...state, loading: false };
      });
  },
});

export const SettingsActions = {
  ...settingsSlice.actions,
  searchUsers,
  uploadFile,
  onboardMerchantAdmin,
  getOrganizationDetails,
  updateOrganizationDetails,
  inviteUsers,
  uploadUsersCsv,
  userActivation,
  getUserDetails,
  getAccountDetails,
  getBankList,
  updateUserDetails,
  updateAccountDetails,
  closeAccount,
  verifyOTP,
  getFeatureTypes,
  updateProfilePicture,
};

export const SettingsSelectors = {
  isLoading: (state) => state.settings.loading,
  users: (state) => state.settings.users,
  totalCount: (state) => state.settings.totalCount,
  pageCount: (state) => state.settings.pageCount,
  organization: (state) => state.settings.organization,
  user: (state) => state.settings.user,
  account: (state) => state.settings.account,
  url: (state) => state.settings.url,
  featureTypes: (state) => state.settings.featureTypes,
  bankList: (state) => state.settings.bankList,
};

export default settingsSlice.reducer;
