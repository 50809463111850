import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import OrganizationSelectDropdown from 'app/components/molecules/organization/organization-dropdown';
import {
  appDataActions,
  appdataSelector,
} from 'store/reducers/appData/appDataSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants/constants.utils';
import BaseCreateBill from './base-create-bill.component';
import { billActions } from 'store/reducers/bills/billsSlice';

const CreateBill = () => {
  const { createBill } = billActions;
  const { resetPreviewBillState, setPreviewBillState, setHeaderText } =
    appDataActions;

  const [organization, setOrganization] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiResponse } = useHandleApiResponse();

  const bill = useSelector(appdataSelector.previewBill);

  useEffect(() => {
    dispatch(setHeaderText({ value: 'Create Bill', type: 'setHeaderText' }));
    // eslint-disable-next-line
  }, []);

  const handleSave = (data, isPreview) => {
    if (isPreview) {
      dispatch(setPreviewBillState(data));
      navigate(ROUTES.PREVIEW_BILL);
    } else {
      const { orgList, ...rest } = data;
      const payload = {
        ...rest,
      };
      dispatch(createBill(payload)).then((res) => {
        dispatch(resetPreviewBillState());
        handleApiResponse(res, ROUTES.BILLS);
      });
    }
  };

  return (
    <BaseCreateBill
      ClientDropDown={
        <OrganizationSelectDropdown
          invoice={bill}
          label={'Customer Name'}
          organization={organization}
          setOrganization={setOrganization}
        />
      }
      setOrganization={setOrganization}
      organization={organization}
      handleSave={handleSave}
    />
  );
};
export default CreateBill;
