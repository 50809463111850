import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as MyLine } from 'app/assets/svg/line.svg';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import { InvertedButton } from 'app/components/atoms/button/button.styles';
import { ReactComponent as BannerImage } from 'app/assets/svg/BannerImage.svg';
import { CreateAccountAdSection } from './banner.styles';
import { ROUTES } from 'utils/constants/constants.utils';
import { useViewport } from 'hooks';
import { motion } from 'framer-motion';

const Banner = () => {
  let navigate = useNavigate();

  const createAccountHandler = () => {
    navigate(ROUTES.SIGN_UP.CLIENT_USER);
  };
  const { mobile } = useViewport();

  return (
    <CreateAccountAdSection>
      <div className="container" name="Home">
        <motion.div
          initial={{
            position: 'relative',
            left: '50%',
            opacity: 0,
          }}
          animate={{ left: '0', opacity: 1 }}
          transition={{ duration: 3 }}
        >
          <Heading size={mobile ? 24 : 42} maxWidth={'33.875rem'}>
            Easei Way to{' '}
            <Span color="var(--accent)" display="inline!important">
              Create & Manage your Invoices
            </Span>
          </Heading>
          <MyLine />
          {mobile ? (
            <Text size={12} maxWidth={'274px'} style={{ margin: '0 auto' }}>
              Professional and easy-to-use invoicing software for business
              owners, clients and vendors.
            </Text>
          ) : (
            <Text size={18} maxWidth={'26.75rem'}>
              All your invoicing and receipting processes in one place.
            </Text>
          )}
          <div className="banner-signup-button">
            <InvertedButton onClick={createAccountHandler}>
              Get Started
            </InvertedButton>
            <InvertedButton as={Link} to="/contact-us">
              Book A Demo
            </InvertedButton>
          </div>
        </motion.div>
        <motion.div
          initial={{
            scale: 1.2,
          }}
          animate={{ scale: 1 }}
          transition={{ duration: 3 }}
        >
          <BannerImage width="100%" className="image" />
        </motion.div>
      </div>
    </CreateAccountAdSection>
  );
};

export default Banner;
