import { easeiRequestTwo } from 'api/requests';
import { toast } from 'react-toastify';
import { authActions } from 'store/reducers/auth/authSlice';
import '../../../src/index.css';

const excludeRoutes = ['/login'];

export const requestV2MiddleWare =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    // pass the action down the middleware pipeline
    next(action);
    const { logout, refreshUserToken } = authActions;

    const requestConfiguration = (config) => {
      const token = getState()?.auth?.token;

      if (!excludeRoutes.includes(config.url)) {
        return {
          ...config,
          headers: {
            Authorization: `Bearer ${token}`,
            ...config.headers,
          },
        };
      }
      return config;
    };

    const responseErrorHandler = async (error) => {
      const { token, refreshToken } = getState()?.auth;

      switch (error.code) {
        case 'ECONNABORTED':
          error.response = {
            data: { errors: ['Server connection timed out.'] },
            message: 'Server connection timed out.',
            status: 408,
          };
          break;
        default:
          break;
      }

      const originalRequest = error.config;

      if ([401].includes(error?.response?.status)) {
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            if (getState()?.auth?.loading === true) {
              setTimeout(() => {
                const newConfig = {
                  ...originalRequest,
                  headers: {
                    Authorization: `Bearer ${getState()?.auth?.token}`,
                  },
                };
                return easeiRequestTwo.request(newConfig);
              }, 2000);
            } else {
              await dispatch(
                refreshUserToken({ accessToken: token, refreshToken })
              );

              const newConfig = {
                ...originalRequest,
                headers: {
                  Authorization: `Bearer ${getState()?.auth?.token}`,
                },
              };
              return easeiRequestTwo.request(newConfig);
            }
          } catch (refreshError) {
            dispatch(logout({ _return: true }));
          }
        }
      }

      toast.warning(
        error?.response?.data?.message || error?.response?.data?.errors[0],
        {
          toastId: 1,
          position: toast.POSITION.TOP_RIGHT,
          className: 'custom-toast',
        }
      );

      return Promise.reject(error);
    };

    easeiRequestTwo.interceptors.request.use(requestConfiguration, (error) => {
      // Do something with request error
      return Promise.reject(error);
    });

    easeiRequestTwo.interceptors.response.use((response) => {
      return response;
    }, responseErrorHandler);
  };
