import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';

const entity = 'payment';

export const initialState = {
  loading: false,
  receiptId: '',
  transactions: null,
  transaction: null,
  transactionDetails: null,
  successDetails: null,
};

const getTransactions = createAsyncThunk(
  `${entity}/transactions`,
  async (input) => {
    const res = await api.payment.transactions(input);
    return res.data;
  }
);

const getTransactionByReference = createAsyncThunk(
  `${entity}/transactionRef`,
  async (input, { rejectWithValue }) => {
    try {
      const res = await api.payment.transactionRef(input);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initiateInvoicePayment = createAsyncThunk(
  `${entity}/initiateInvoicePayment`,
  async (input) => {
    const res = await api.payment.initiateInvoicePayment(input);
    return res.data;
  }
);

const updateInvoicePaymentStatus = createAsyncThunk(
  `${entity}/updateInvoicePaymentStatus`,
  async (input) => {
    const res = await api.payment.UpdateInvoicePaymentStatus(input);
    return res.data.data;
  }
);

const getTransactionDetailsByReference = createAsyncThunk(
  `${entity}/transactionsDetails`,
  async (input) => {
    const res = await api.payment.transactionsDetails(input);
    return res.data.data;
  }
);

const confirmTransactionDetailsByReference = createAsyncThunk(
  `${entity}/confirm-transaction-payment`,
  async (input) => {
    const res = await api.payment.confirmTransactionsDetails(input);
    return res.data.message;
  }
);

const paymentSlice = createSlice({
  name: entity,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(initiateInvoicePayment.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(initiateInvoicePayment.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(initiateInvoicePayment.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(updateInvoicePaymentStatus.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(updateInvoicePaymentStatus.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updateInvoicePaymentStatus.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          transactions: { ...action.payload },
        };
      })
      .addCase(getTransactions.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getTransactions.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getTransactionByReference.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          transaction: { ...action.payload },
        };
      })
      .addCase(getTransactionByReference.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getTransactionByReference.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(getTransactionDetailsByReference.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          transactionDetails: { ...action.payload },
        };
      })
      .addCase(getTransactionDetailsByReference.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getTransactionDetailsByReference.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(
        confirmTransactionDetailsByReference.fulfilled,
        (state, action) => {
          return {
            ...state,
            loading: false,
            successDetails: action.payload,
          };
        }
      )
      .addCase(confirmTransactionDetailsByReference.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(
        confirmTransactionDetailsByReference.rejected,
        (state, action) => {
          return { ...state, loading: false };
        }
      );
  },
});

export const paymentActions = {
  ...paymentSlice.actions,
  initiateInvoicePayment,
  updateInvoicePaymentStatus,
  getTransactions,
  getTransactionByReference,
  getTransactionDetailsByReference,
  confirmTransactionDetailsByReference,
};

export const paymentSelectors = {
  isLoading: (state) => state.payment.loading,
  receiptId: (state) => state.payment.receiptId,
  transactions: (state) => state.payment.transactions,
  transaction: (state) => state.payment.transaction,
  transactionDetails: (state) => state.payment.transactionDetails,
  successDetails: (state) => state.payment.successDetails,
};

export default paymentSlice.reducer;
