import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authActions, authSelectors } from 'store/reducers/auth/authSlice';
import { invoiceActions } from 'store/reducers/invoice/invoiceSlice';
import { RiArrowDropDownFill } from 'react-icons/ri';
import PageMessage from 'app/components/molecules/page-message/page-message.component';
import ClientDashboard from './admin-dashboard.component';
import OrganizationDashboard from './org-dashboard.component';

import { GreetingsContainer } from './dashboard.styles';
import { useCheckAdmin, useOnClickOutside, useViewport } from 'hooks';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { Heading } from 'app/components/atoms/typography';
import { GrClear } from 'react-icons/gr';
import styled from 'styled-components';
import { MONTH_NAMES } from 'utils/constants/constants.utils';
import { MonthPicker } from '../reminder/reminder.styles';
import { DateSelectInput } from 'app/components/atoms/divs/div.styles';
import CurrenciesDropdown from 'app/components/molecules/currenciesDropDown/currencies-dropdown.component';

const DashboardSelect = styled(DateSelectInput)`
  flex-direction: row;
  justify-content: flex-end;
  border: none;
  /* width:fit-content; */
  gap: 35px;
  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`;

const Dashboard = () => {
  const dispatch = useDispatch();
  const { mobile } = useViewport();
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const userName = useSelector(authSelectors.userName);
  const { role } = useCheckAdmin();
  const { getPendingInvoices } = invoiceActions;
  const { checkAdmin } = authActions;
  const [date, setDate] = useState(null);
  const [active, setActive] = useState(false);
  const [selectedMonth] = useState(
    date ? date.getMonth() : new Date().getMonth()
  );
  const [year, setYear] = useState(
    date
      ? new Date(date.getFullYear(), selectedMonth, 1)
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [timeInput, setTimeInput] = useState({
    day: date ? date.getDate() : '',
    month: date ? date.getMonth() + 1 : year.getMonth() + 1,
    year: year.getFullYear(),
    weekly: active,
    currency:
      role !== USER_ROLES.EASEI_ADMIN &&
      role !== USER_ROLES.EASEI_USER &&
      selectedCurrency?.label,
  });

  const {
    visible: monthVisible,
    setVisible: setMonthVisible,
    ref: monthRef,
  } = useOnClickOutside(false);

  const {
    visible: yearVisible,
    setVisible: setYearVisible,
    ref: yearRef,
  } = useOnClickOutside(false);

  let month = year?.getMonth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(checkAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getPendingInvoices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedCurrency?.label &&
      role !== USER_ROLES.EASEI_ADMIN &&
      role !== USER_ROLES.EASEI_USER
    ) {
      setTimeInput((prev) => ({
        ...prev,
        currency: selectedCurrency?.label,
      }));
    }
  }, [selectedCurrency, role]);

  const startYear = 1900; // Change this to adjust the start year
  const endYear = new Date().getFullYear();
  const options = [];
  for (let year = startYear; year <= endYear; year++) {
    options.push(year);
  }
  return (
    <div className="dashboard-container">
      <GreetingsContainer>
        <PageMessage
          color={'var(--primary2)'}
          heading={`Welcome ${userName} 👋`}
          text={"Let's handle your invoicing needs hassle-free!"}
          size={mobile ? 18 : 32}
          tSize={mobile ? 12 : 16}
        />
        <div>
          <DashboardSelect style={{ padding: '1em' }}>
            {date && (
              <GrClear
                onClick={(e) => {
                  setDate(null);
                  setTimeInput({
                    day: '',
                    month: year.getMonth() + 1,
                    year: year.getFullYear(),
                    weekly: active,
                  });
                }}
                color="var(--accent)"
                // width="20px"
              />
            )}
            {role !== USER_ROLES.EASEI_ADMIN &&
              role !== USER_ROLES.EASEI_USER && (
                <CurrenciesDropdown
                  selected={selectedCurrency}
                  setSelected={setSelectedCurrency}
                />
              )}
            {(role === USER_ROLES.EASEI_ADMIN ||
              role === USER_ROLES.EASEI_USER) && (
              <>
                <div ref={monthRef}>
                  {monthVisible ? (
                    <MonthPicker style={{ gap: 0 }}>
                      {MONTH_NAMES.map((name, key) => {
                        return (
                          <div style={{ zIndex: 10 }}>
                            <Heading
                              level={3}
                              key={name}
                              className="select"
                              onClick={(e) => {
                                setYear(
                                  new Date(
                                    new Date(year.setMonth(key)).setDate(1)
                                  )
                                );
                                setMonthVisible(false);
                                setTimeInput({
                                  day: date?.getDate() || '',
                                  month: MONTH_NAMES.indexOf(name) + 1,
                                  year: year.getFullYear(),
                                  weekly: active,
                                });
                              }}
                            >
                              {name}
                            </Heading>
                          </div>
                        );
                      })}
                    </MonthPicker>
                  ) : (
                    <Heading
                      level={3}
                      style={{
                        cursor: 'pointer',
                        border: '1px solid var(--accent)',
                        borderRadius: '8px',
                        padding: mobile ? '1px 5px' : '10px 20px',
                      }}
                      onClick={(e) => {
                        setMonthVisible(true);
                      }}
                    >
                      {MONTH_NAMES[month]}
                      <RiArrowDropDownFill size={30} />
                    </Heading>
                  )}
                </div>
                <div ref={yearRef}>
                  {yearVisible ? (
                    <MonthPicker>
                      {options.reverse().map((name) => {
                        return (
                          <div style={{ zIndex: 10 }}>
                            <Heading
                              level={3}
                              key={name}
                              className="select"
                              onClick={(e) => {
                                setYear(
                                  new Date(
                                    new Date(year.setFullYear(name)).setDate(1)
                                  )
                                );
                                setYearVisible(false);

                                setTimeInput({
                                  day: date?.getDate() || '',
                                  month: year.getMonth() + 1,
                                  year: year.getFullYear(),
                                  weekly: active,
                                });
                              }}
                            >
                              {name}
                            </Heading>
                          </div>
                        );
                      })}
                    </MonthPicker>
                  ) : (
                    <Heading
                      level={3}
                      style={{
                        cursor: 'pointer',
                        border: '1px solid var(--accent)',
                        borderRadius: '8px',
                        padding: mobile ? '1px 5px' : '10px 20px',
                      }}
                      onClick={(e) => {
                        setYearVisible(true);
                      }}
                    >
                      {year.getFullYear()}
                      <RiArrowDropDownFill size={30} />
                    </Heading>
                  )}
                </div>
              </>
            )}
          </DashboardSelect>
        </div>
      </GreetingsContainer>
      {role === USER_ROLES.CLIENT_USER ||
      role === USER_ROLES.ORGANIZATION_ADMIN ||
      role === USER_ROLES.ORGANIZATION_USER ? (
        <OrganizationDashboard
          timeInput={timeInput}
          active={active}
          setTimeInput={setTimeInput}
          setActive={setActive}
        />
      ) : (
        <ClientDashboard
          active={active}
          timeInput={timeInput}
          setTimeInput={setTimeInput}
          setActive={setActive}
        />
      )}
    </div>
  );
};

export default Dashboard;
