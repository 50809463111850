import { authSelectors } from 'store/reducers/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useEffect } from 'react';
import {
  CustomSelect,
  InputDataHouse,
  Label,
} from 'app/components/atoms/generalInvoiceStyles/general.sytlyes';
import { billActions, billSelectors } from 'store/reducers/bills/billsSlice';

const OrganizationSelectDropdown = ({
  organization,
  setOrganization,
  isDisabled = false,
  invoice,
  label,
}) => {
  const dispatch = useDispatch();
  const { getBillingClients } = billActions;
  const organizations = useSelector(
    label
      ? billSelectors.billingClientsForDropdown
      : authSelectors.organizationsForDropdown
  );
  useEffect(() => {
    if (_.isEmpty(organizations) && label) {
      dispatch(getBillingClients());
    }
  }, []);
  useEffect(() => {
    if (!_.isEmpty(invoice)) {
      const org = organizations.filter((item) =>
        label
          ? item.value === invoice?.clients[0]?.id
          : item.value === invoice.organizationId
      )[0];
      setOrganization(org);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <InputDataHouse>
      <Label>{label ?? 'Customer'}</Label>
      <CustomSelect
        isDisabled={isDisabled}
        placeholder="Select Customer"
        className="react-select-container"
        classNamePrefix="react-select"
        options={organizations}
        onChange={(organization) => setOrganization(organization)}
        value={organization}
      />
    </InputDataHouse>
  );
};

export default OrganizationSelectDropdown;
