import { combineReducers } from 'redux';
import authSlice from './reducers/auth/authSlice';
import chartSlice from './reducers/chart/chartSlice';
import currencySlice from './reducers/currency/currencySlice';
import formSlice from './reducers/form/formSlice';
import invoiceSlice from './reducers/invoice/invoiceSlice';
import merchantConfigurationSlice from './reducers/merchant-configuration/merchant-configuration.slice';
import merchantSlice from './reducers/merchant/merchantSlice';
import notificationSlice from './reducers/notification/notificationSlice';
import organizationSlice from './reducers/organization/organizationSlice';
import peopleSlice from './reducers/people/peopleSlice';
import planSlice from './reducers/plan/planSlice';
import featureSlice from './reducers/feature/featureSlice';
import recurringInvoiceSlice from './reducers/recurringInvoice/recurringInvoiceSlice';
import settingsSlice from './reducers/settings/settingsSlice';
import externalUserSlice from './reducers/externalUser/externalUserSlice';
import supportSlice from './reducers/support/supportSlice';
import invoiceReminderSlice from './reducers/invoiceReminder/invoiceReminderSlice';
import chatSlice from './reducers/chat/chatSlice';
import dashboardSlice from './reducers/dashboard/dashboardSlice';
import adminDashboardSlice from './reducers/dashboard/adminDashboardSlice';
import paymentSlice from './reducers/payment/paymentSlice';
import receiptSlice from './reducers/receipt/receiptSlice';
import appDataSlice from './reducers/appData/appDataSlice';
import billSlice from './reducers/bills/billsSlice';
import chargeSlice from './reducers/charge/chargeSlice';

const appReducer = combineReducers({
  auth: authSlice,
  people: peopleSlice,
  invoice: invoiceSlice,
  chart: chartSlice,
  plan: planSlice,
  feature: featureSlice,
  form: formSlice,
  notification: notificationSlice,
  recurringInvoices: recurringInvoiceSlice,
  merchants: merchantSlice,
  merchantConfigurations: merchantConfigurationSlice,
  settings: settingsSlice,
  currency: currencySlice,
  organization: organizationSlice,
  externalUser: externalUserSlice,
  support: supportSlice,
  invoiceReminder: invoiceReminderSlice,
  chat: chatSlice,
  dashboard: dashboardSlice,
  adminDashboard: adminDashboardSlice,
  payment: paymentSlice,
  receipt: receiptSlice,
  appData: appDataSlice,
  bill: billSlice,
  charge: chargeSlice,
});

export const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }

  return appReducer(state, action);
};
