import Tab from 'app/components/atoms/tab/tab.component';
import { useViewport } from 'hooks';
import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';

const mainNavLabelStyle = css`
  border-bottom: 0.25rem solid var(--primary2);
`;

const MainHorizontalNav = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  border-bottom: ${({ mainNav }) => (mainNav ? '0.0625rem solid #B8C6E4' : '')};
  box-shadow: ${({ mainNav }) =>
    mainNav || '0px 0.25rem 0.25rem rgba(38, 50, 56, 0.05)'};
  @media screen and (max-width: 700px) {
    border-bottom: none;
    gap: 0.75rem;
  }
`;

const NavBarLink = styled(NavLink)`
  height: 4rem;
  display: flex;
  align-items: center;
  text-align: start;
  text-decoration: none;
  text-justify: left;
  color: var(--white);
  padding: 1rem;
  @media screen and (max-width: 1405px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 699px) {
    height: 1.75rem;
    border-radius: 1.25rem;
    border: 1px solid var(--grey4);
    text-align: center;
  }
  p {
    color: #7b8599;
    white-space: nowrap;
    @media screen and (max-width: 1405px) {
      font-size: 1.2rem;
    }
    @media screen and (max-width: 1236px) {
      font-size: 1rem;
    }
    @media screen and (max-width: 1123px) {
      font-size: 0.9rem;
    }
    @media screen and (max-width: 699px) {
      font-size: 12px;
    }
    @media screen and (max-width: 480px) {
      font-size: 1rem;
    }
  }
  &.active {
    ${({ mainNav }) => mainNav && mainNavLabelStyle};
    background-color: #ffffff;
    p {
      color: var(--primary2);
    }
    @media screen and (max-width: 699px) {
      background-color: var(--grey4);
      border: 1px solid var(--grey3);
    }
  }
`;

const HorizontalNav = ({ menuItems, isMain, textWeight }) => {
  const { mobile } = useViewport();

  return (
    <MainHorizontalNav mainNav={isMain} className="no-scrollbar">
      {menuItems?.map((item, index) => {
        const { url, name } = item;
        return (
          <NavBarLink
            size={mobile ? 2 : ''}
            to={url}
            mainNav={isMain}
            key={index}
          >
            <Tab text={name} key={index} textWeight={textWeight} />
          </NavBarLink>
        );
      })}
    </MainHorizontalNav>
  );
};

export default HorizontalNav;
