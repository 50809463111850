import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { INVOICE_STATUS } from 'utils/constants/constants.utils';

const entity = 'invoice';

export const initialState = {
  invoices: [],
  selectedInvoices: [],
  invoice: {},
  pendingInvoices: [],
  pendingCount: 0,
  approvedCount: 0,
  declinedCount: 0,
  changeRequestCount: 0,
  totalCount: 0,
  statusList: [],
  loading: false,
  paymentDetails: null,
};

const searchInvoices = createAsyncThunk(`${entity}/search`, async (payload) => {
  const res = await api.invoice.search(payload);
  return res.data.data;
});

const advanceSearch = createAsyncThunk(
  `${entity}/advance-search`,

  async (input) => {
    const {
      status,
      startDate,
      endDate,
      client,
      organizationId,
      page = 1,
      pageSize = 20,
    } = input;

    const res = await api.invoice.advanceSearch(
      page,
      pageSize,
      status,
      startDate,
      endDate,
      client,
      organizationId
    );
    return res.data.data;
  }
);

const getDetails = createAsyncThunk(`${entity}/getDetails`, async (id) => {
  const res = await api.invoice.getById(id);
  return res.data.data;
});

const updateStatus = createAsyncThunk(
  `${entity}/update-status`,
  async (input) => {
    const res = await api.invoice.updateStatus(input);
    return res.data;
  }
);

const createExternal = createAsyncThunk(`${entity}/external`, async (input) => {
  const res = await api.invoice.createExternal(input);
  return res.data;
});

const createInternal = createAsyncThunk(`${entity}/internal`, async (input) => {
  const res = await api.invoice.createInternal(input);
  return res.data;
});

const update = createAsyncThunk(`${entity}/update`, async (input) => {
  const res = await api.invoice.update(input);
  return res.data;
});

const generatePaymentReference = createAsyncThunk(
  `${entity}/generatePaymentReference`,
  async (input, { rejectWithValue }) => {
    try {
      const res = await api.invoice.generatePaymentReference(input);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const invoiceSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    resetState: (state) => {
      state.invoice = {};
    },
    getPendingInvoices: (state) => {
      const list = state.invoices
        ?.map((invoice) => {
          return { select: false, ...invoice };
        })
        .filter(
          (invoice) =>
            invoice.status === INVOICE_STATUS.PENDING ||
            invoice.status === INVOICE_STATUS.CHANGE_REQUESTED
        );
      state.pendingInvoices = list;
    },
    getInvoices: (state) => {
      const list = state.invoices?.map((invoice) => {
        return { select: false, ...invoice };
      });
      state.invoices = list;
    },
    setSelectedInvoices: (state, action) => {
      state.selectedInvoices = action.payload;
    },
    selectAllPendingInvoices: (state, action) => {
      state.pendingInvoices = state.pendingInvoices?.map((invoice) => {
        invoice.select = action.payload;
        return invoice;
      });
    },
    selectById: (state, action) => {
      const { id, checked } = action.payload;
      state.invoices = state.invoices?.map((inv) => {
        if (inv.id === id) {
          inv.select = checked;
        }
        return inv;
      });
    },
    selectPendingInvoiceById: (state, action) => {
      const { id, checked } = action.payload;
      state.pendingInvoices = state.pendingInvoices?.map((inv) => {
        if (inv.id === id) {
          inv.select = checked;
        }
        return inv;
      });
    },
    getInvoiceCount: (state) => {
      state.totalCount = state.invoices.length;
    },
    getStatusList: (state) => {
      const statusList = [];
      state.invoices?.map((invoice) => {
        if (!statusList.includes(invoice.status)) {
          statusList.push(invoice.status);
        }
        return invoice;
      });
      state.statusList = statusList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchInvoices.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(searchInvoices.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(searchInvoices.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(advanceSearch.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(advanceSearch.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(advanceSearch.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getDetails.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.loading = false;
      })
      .addCase(getDetails.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getDetails.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(updateStatus.fulfilled, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateStatus.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateStatus.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(createExternal.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(createExternal.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createExternal.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(createInternal.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(createInternal.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(createInternal.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(update.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(update.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(update.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(generatePaymentReference.fulfilled, (state, action) => {
        return { ...state, loading: false, paymentDetails: action.payload };
      })
      .addCase(generatePaymentReference.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(generatePaymentReference.rejected, (state) => {
        return { ...state, loading: false };
      });
  },
});

export const invoiceActions = {
  ...invoiceSlice.actions,
  searchInvoices,
  advanceSearch,
  getDetails,
  updateStatus,
  createExternal,
  createInternal,
  update,
  generatePaymentReference,
};

export const invoiceSelectors = {
  isLoading: (state) => state.invoice.loading,
  pendingInvoices: (state) => state.invoice.pendingInvoices,
  invoices: (state) => state.invoice.invoices,
  selectedInvoices: (state) => state.invoice.selectedInvoices,
  paymentDetails: (state) => state.invoice.paymentDetails,
  invoice: (state) => state.invoice.invoice,
  pendingInvoiceCount: (state) => state.invoice.pendingCount,
  changeRequestCount: (state) => state.invoice.changeRequested,
  approvedInvoiceCount: (state) => state.invoice.approvedCount,
  declinedInvoiceCount: (state) => state.invoice.declinedCount,
  totalInvoiceCount: (state) => state.invoice.totalCount,
  statusList: (state) => state.invoice.statusList,
};

export default invoiceSlice.reducer;
