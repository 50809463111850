import {
  BILL_STATUS,
  BOOLEAN_STATUS,
  INVOICE_STATUS,
} from 'utils/constants/constants.utils';

export const getInvoiceStatusCount = (
  status,
  pendingCount,
  approvedCount,
  declinedCount,
  changeRequestCount,
  totalCount
) => {
  let count = 0;
  switch (status) {
    case INVOICE_STATUS.PENDING:
      count = pendingCount;
      break;
    case INVOICE_STATUS.APPROVED:
      count = approvedCount;
      break;
    case INVOICE_STATUS.DECLINED:
      count = declinedCount;
      break;
    case INVOICE_STATUS.CHANGE_REQUESTED:
      count = changeRequestCount;
      break;
    default:
      count = totalCount;
      break;
  }
  return count;
};

export const getActiveStatusCount = (
  status,
  activeCount,
  inActiveCount,
  totalCount
) => {
  let count = 0;
  switch (status) {
    case BOOLEAN_STATUS.True:
      count = activeCount;
      break;
    case BOOLEAN_STATUS.False:
      count = inActiveCount;
      break;
    default:
      count = totalCount;
      break;
  }
  return count;
};

export const getBillStatusCount = (
  status,
  unpaidCount,
  paidCount,
  totalCount
) => {
  let count = 0;
  switch (status) {
    case BILL_STATUS.UNPAID:
      count = unpaidCount;
      break;
    case BILL_STATUS.PAID:
      count = paidCount;
      break;
    default:
      count = totalCount;
      break;
  }
  return count;
};
