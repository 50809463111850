import { NoStyleButton } from 'app/components/atoms/button/button.styles';
import { ReactComponent as FilterIcon } from 'app/assets/svg/filterIcon.svg';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { AccordionItem } from 'app/components/atoms/accordion-item/accordion-item.component';
import {
  INVOICE_PAYMENT_STATUS_DROPDOWN_DATA,
  INVOICE_STATUS_DROPDOWN_DATA,
} from 'utils/constants/constants.utils';

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"

  // use your custom style for ".popup-content"
  &-content {
  }
`;
const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #7d7d7d;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;

  li:hover {
    background: #e5e5e5;
  }

  li {
    padding: 6px 20px;
    list-style-type: none;
    .accordion-header {
      width: 100%;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
const Filter = ({ setPayment, setStatusInput, state }) => {
  return (
    <StyledPopup
      trigger={() => (
        <NoStyleButton>
          <FilterIcon />
        </NoStyleButton>
      )}
      position="bottom right"
      closeOnDocumentClick
    >
      <PopupContainer>
        <ul>
          <AccordionItem title="Payment Status">
            {INVOICE_PAYMENT_STATUS_DROPDOWN_DATA.option.map((item) => (
              <label
                key={item.label}
                htmlFor={item.label}
                style={{ cursor: 'pointer', display: 'block' }}
              >
                <input
                  type="checkbox"
                  id={item.label}
                  style={{ marginRight: '4px' }}
                  checked={item.value === state.invoicePaymentStatus}
                  onChange={(e) =>
                    e.target.checked ? setPayment(item.value) : setPayment(null)
                  }
                />
                {item.label}
              </label>
            ))}
          </AccordionItem>
          <AccordionItem title="Invoice Status">
            {INVOICE_STATUS_DROPDOWN_DATA.option.map((item) => (
              <label
                key={item.label}
                htmlFor={item.label}
                style={{ cursor: 'pointer', display: 'block' }}
              >
                <input
                  type="checkbox"
                  id={item.label}
                  style={{ marginRight: '4px' }}
                  checked={item.value === state.statusInput}
                  onChange={(e) =>
                    e.target.checked
                      ? setStatusInput(item.value)
                      : setStatusInput(null)
                  }
                />
                {item.label}
              </label>
            ))}
          </AccordionItem>
        </ul>
      </PopupContainer>
    </StyledPopup>
  );
};

export default Filter;
