import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import Text from '../atoms/typography';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
const StyledProgressCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  div {
    width: 10px;
    height: 10px;
    background-color: ${({ color }) => color || '#011747'};
    border-radius: 50%;
  }
`;
const CircularProgressWithChildren = ({ values, strokeWidth, children }) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (values) {
      const total = values.total.value;
      const remaining = values.remaining.value;
      setPercentage(100 - (remaining / total) * 100);
    }
  }, [values]);
  return (
    <StyledProgressCard>
      <div style={{ width: 145, height: 145 }}>
        <CircularProgressbarWithChildren
          value={percentage}
          strokeWidth={strokeWidth ?? 12}
          styles={buildStyles({
            textColor: 'red',
            pathColor: `${values?.remaining?.color}`,
            trailColor: `${values?.total?.color}`,
          })}
        >
          {children}
        </CircularProgressbarWithChildren>
      </div>
      <div>
        <LegendItem color={values?.total?.color}>
          <div />
          <Text color="var(--grey3)" size="11px" margin="0">
            {values.total.label} {100 - percentage.toFixed(0)}%
          </Text>
        </LegendItem>
        <LegendItem color={values?.remaining?.color}>
          <div />
          <Text color="var(--grey3)" size="11px" margin="0">
            {values.remaining.label} {100 - (100 - percentage.toFixed(0))}%
          </Text>
        </LegendItem>
      </div>
    </StyledProgressCard>
  );
};

export default CircularProgressWithChildren;
