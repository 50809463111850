import Text from 'app/components/atoms/typography';
import styled from 'styled-components';

const TabContainer = styled.div`
  padding: ${({ p }) => p || ''};

  p {
    margin: 0;
  }
`;

const Tab = ({ text, ...props }) => {
  const { padding } = props;
  return (
    <TabContainer p={padding}>
      <Text weight="700" size="24px">
        {text}
      </Text>
    </TabContainer>
  );
};

export default Tab;
