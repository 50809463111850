export const easeiIcons = {
  arrow_right: () => (
    <svg
      width="47"
      height="48"
      viewBox="0 0 47 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5273 8.15984L30.2957 21.1998C31.8036 22.7398 31.8036 25.2598 30.2957 26.7998L17.5273 39.8398"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  arrow_left: () => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2344 39.5803L17.5175 26.6521C16.0156 25.1253 16.0156 22.6269 17.5175 21.1001L30.2344 8.17188"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
