import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';

const entity = 'bill';

export const initialState = {
  bills: [],
  billingClients: [],
  bill: {},
  paidCount: 0,
  unPaidCount: 0,
  totalCount: 0,
  statusList: [],
  loading: false,
};

const searchBills = createAsyncThunk(`${entity}/search`, async (payload) => {
  const res = await api.bill.search(payload);
  return res.data.data;
});

const advanceSearch = createAsyncThunk(
  `${entity}/advance-search`,

  async (input) => {
    const {
      status,
      startDate,
      endDate,
      client,
      organizationId,
      page = 1,
      pageSize = 20,
    } = input;

    const res = await api.bill.advanceSearch(
      page,
      pageSize,
      status,
      startDate,
      endDate,
      client,
      organizationId
    );
    return res.data.data;
  }
);

const getDetails = createAsyncThunk(`${entity}/getDetails`, async (id) => {
  const res = await api.bill.getById(id);
  return res.data.data;
});

const getBillingClients = createAsyncThunk(
  `${entity}/getBillingClients`,
  async (id) => {
    const res = await api.bill.getBillingClients();
    return res.data.data;
  }
);

const updateStatus = createAsyncThunk(
  `${entity}/update-status`,
  async (input) => {
    const res = await api.bill.updateStatus(input);
    return res.data;
  }
);

const createBill = createAsyncThunk(`${entity}/create-bill`, async (input) => {
  const res = await api.bill.create(input);
  return res.data;
});

const billSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    resetState: (state) => {
      state.bill = {};
    },
    getBills: (state) => {
      const list = state.bills?.map((bill) => {
        return { select: false, ...bill };
      });
      state.bills = list;
    },
    selectAll: (state, action) => {
      state.bills = state.bills?.map((bill) => {
        bill.select = action.payload;
        return bill;
      });
    },
    selectAllPendingInvoices: (state, action) => {
      state.pendingInvoices = state.pendingInvoices?.map((bill) => {
        bill.select = action.payload;
        return bill;
      });
    },
    selectById: (state, action) => {
      const { id, checked } = action.payload;
      state.bills = state.bills?.map((bill) => {
        if (bill.id === id) {
          bill.select = checked;
        }
        return bill;
      });
    },
    selectPendingInvoiceById: (state, action) => {
      const { id, checked } = action.payload;
      state.pendingInvoices = state.pendingInvoices?.map((bill) => {
        if (bill.id === id) {
          bill.select = checked;
        }
        return bill;
      });
    },
    getInvoiceCount: (state) => {
      state.totalCount = state.bills.length;
    },
    getStatusList: (state) => {
      const statusList = [];
      state.bills?.map((bill) => {
        if (!statusList.includes(bill.status)) {
          statusList.push(bill.status);
        }
        return bill;
      });
      state.statusList = statusList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchBills.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(searchBills.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(searchBills.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(advanceSearch.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(advanceSearch.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(advanceSearch.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getDetails.fulfilled, (state, action) => {
        state.bill = action.payload;
        state.loading = false;
      })
      .addCase(getDetails.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getDetails.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getBillingClients.fulfilled, (state, action) => {
        state.billingClients = action.payload;
        state.loading = false;
      })
      .addCase(getBillingClients.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getBillingClients.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(updateStatus.fulfilled, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateStatus.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateStatus.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(createBill.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(createBill.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createBill.rejected, (state) => {
        return { ...state, loading: false };
      });
  },
});

export const billActions = {
  ...billSlice.actions,
  searchBills,
  advanceSearch,
  getDetails,
  getBillingClients,
  updateStatus,
  createBill,
};

export const billSelectors = {
  isLoading: (state) => state.bill.loading,
  bills: (state) => state.bill.bills,
  bill: (state) => state.bill.bill,
  billingClients: (state) => state.bill.billingClients,
  billingClientsForDropdown: (state) =>
    state?.bill?.billingClients?.map(function (item) {
      return {
        value: item.id,
        label: item.fullName,
        ...item,
        address: item.address ?? 'No 9, Vincent Street, VI, Lagos',
      };
    }),
  paidBillCount: (state) => state.bill.paidCount,
  unpaidBillCount: (state) => state.bill.unPaidCount,
  totalBillCount: (state) => state.bill.totalCount,
  billStats: (state) => {
    return [
      {
        name: 'TOTAL',
        value: state.bill.totalAmount,
        invoices: state.bill.totalCount,
      },
      {
        name: 'PAID',
        value: state.bill.paidAmount,
        invoices: state.bill.paidCount,
      },
      {
        name: 'UNPAID',
        value: state.bill.unPaidAmount,
        invoices: state.bill.unPaidCount,
      },
    ];
  },
  statusList: (state) => state.bill.statusList,
};

export default billSlice.reducer;
