// User Actions
export const LOGOUT = 'logout';

// Tables
export const TABLES_TYPE = {
  CHECK: 'checkbox',
  REGULAR: 'regular',
};

// Boolean Status
export const BOOLEAN_STATUS = {
  True: 'Active',
  False: 'InActive',
};

// Activation Status

export const ACTIVATION_STATUS = {
  Deactivate: 'Deactivate',
  Activate: 'Activate',
};

// Invoice Status
export const INVOICE_STATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING: 'Pending',
  CHANGE_REQUESTED: 'ChangesRequested',
};

// Invoice Payment Status
export const INVOICE_PAYMENT_STATUS = {
  UNPAID: 'UnPaid',
  VALIDATIONREQUESTED: 'ValidationRequested',
  PAID: 'Paid',
};

// Invoice Type
export const INVOICE_TYPE = {
  External: 'External',
  Internal: 'Internal',
};

// Invoice Actions
export const INVOICE_ACTIONS = {
  APPROVE: 'Approve',
  DECLINE: 'Decline',
  REQUEST_CHANGE: 'Request Change',
  PENDING: 'Pending',
};

//Invoice Barchart
export const INVOICE_BAR_CHART_TYPE = {
  WEEKLY: 'day',
  MONTHLY: 'month',
  YEARLY: 'year',
};

// Bill Status
export const BILL_STATUS = {
  PAID: 'Paid',
  UNPAID: 'Unpaid',
};

export const RecurTypeData = {
  label: 'RecurType',
  options: [
    {
      value: 1,
      label: 'Daily',
    },
    {
      value: 2,
      label: 'Weekly',
    },
    {
      value: 3,
      label: 'Monthly',
    },
  ],
};

export const UniT_TYPES = [
  {
    value: 0,
    label: 'Quantity',
  },
  {
    value: 1,
    label: 'Duration',
  },
];

export const INVOICE_REMINDER_TEMPLATES = [
  {
    value: 0,
    label: 'Simple_Reminder',
  },
  {
    value: 1,
    label: 'Classic_Reminder',
  },
  {
    value: 2,
    label: 'Loud_Reminder',
  },
];

export const INVOICE_REMINDER_SCHEDULES = [
  {
    value: 1,
    label: 'Weekly',
  },
  {
    value: 2,
    label: 'Monthly',
  },
];

export const PAYMENT_PROVIDERS = [
  {
    value: 1,
    label: 'Paystack',
    img: '',
  },
  {
    value: 2,
    label: 'Stripe',
    img: '',
  },
];

export const REMINDER_DIRECTION = {
  left: 'left',
  right: 'right',
};

export const REMINDER_SCHEDULE_TEXT = {
  Month: 'Month',
  Week: 'Week',
};

export const REMINDER_SCHEDULE = {
  Weekly: 1,
  Monthly: 2,
};

export const WELCOME_TO_EASEI =
  'Welcome to Easei, your simple invoicing solution.';

// PlanTypes
export const PLAN_TYPE = {
  ORGANIZATION: 'Organization',
  CLIENT: 'Client',
};

// PlanTypes
export const PLAN_TYPE_NUMBER = {
  ORGANIZATION: 1,
  CLIENT: 2,
};

export const InvoiceTypes = {
  Internal: 'Internal Invoice',
  External: 'External Invoice',
  External_Recurring: 'External Recurring',
  Internal_Recurring: 'Internal Recurring',
};
//otp source
export const OTP_SOURCE = {
  ACCOUNT_SETUP: 3,
};

export const ROUTES = {
  // Public
  HOME: '/',
  RESET_PASSWORD: '/reset-password/:email/:token',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_EMAIL: '/confirm-email/:email/:token',
  CONFIRM_INVITE: '/confirm-invite/:email/:token',
  VERIFY_EMAIL: '/verify-email',
  CHECK_EMAIL: '/check-email',
  CONTACT_US: '/contact-us',
  SIGN_UP: {
    ORGANIZATION: '/organization-signup',
    ORGANIZATION_USER: '/organization-user-signup',
    CLIENT_USER: '/client-user-signup',
  },
  PRIVACY: '/privacy',
  PAYMENT_POLICY: '/payment-policy',
  SECURITY: '/security',
  GDPR: '/gdpr',
  TERMS: '/terms',
  FAQ: '/faq',

  // Protected
  DASHBOARD: '/dashboard',
  BILLS: '/bills',
  CREATE_BILLS: '/bills/create',
  PREVIEW_BILL: '/preview-bill',
  BILL_DETAILS: '/bills/:id',
  INVOICE: '/invoices',
  PREVIEW_INVOICE: '/preview-invoice',
  CREATE_INVOICE: '/invoices/create',
  CREATE_EXTERNAL_INVOICE: '/invoices/create/external',

  EDIT_INTERNAL_INVOICE: '/invoices/edit/internal/:id',
  EDIT_INTERNAL_INVOICE_ROUTE_HEAD: '/invoices/edit/internal',
  PREVIEW_ROUTE: 'preview',

  CREATE_INTERNAL_RECURRING_INVOICE: '/recurringinvoice/create/internal',
  CREATE_EXTERNAL_RECURRING_INVOICE: '/recurringinvoice/create/external',
  PREVIEW_RECURRING_INVOICE: '/preview-recurring-invoice',
  EDIT_INTERNAL_RECURRING_INVOICE: '/recurringinvoice/edit/internal/:id',
  EDIT_INTERNAL_RECURRING_INVOICE_ROUTE_HEAD: '/recurringinvoice/edit/internal',

  EDIT_EXTERNAL_RECURRING_INVOICE: '/recurringinvoice/edit/external/:id',
  EDIT_EXTERNAL_RECURRING_INVOICE_ROUTE_HEAD: '/recurringinvoice/edit/external',

  INVOICE_DETAILS: '/invoices/:id',
  RECURRING_INVOICE_DETAIL: '/recurringinvoice/:id',
  PEOPLE: '/people',
  RECEIPT: '/receipts',
  PLANS: '/plans',
  REMINDER: '/reminder',
  RECEIPT_DETAILS: '/receipts/:id',
  PLANS_DETAILS: '/plans/details',
  CREATE_PLAN: '/plans/create',
  EDIT_PLAN: '/plans/:id',

  RECURRINGINVOICE: '/recurringinvoice',
  CHARGE: '/charge',
  SIGN_IN: '/sign-in',
  PROFORMA: {
    HOME: '/proforma',
    GENERATE: '/proforma/generate',
    VIEW: '/proforma/:id',
  },
  REMITAPAY: {
    PAY: '/remitapay/:id',
  },
  SETTINGS: {
    SETTINGS: '/settings',
    ORGANIZATION: 'organization',
    BILL: 'bill',
    INVOICE: 'invoice',
    ACCOUNT: 'account',
    PAYMENT: 'payment',
    VERIFY_PAYMENT: 'verify-payment',
  },
  PROFILE_SETTINGS: {
    SETTINGS: '/settings/profile',
    PERSONAL_INFORMATION: 'personal-information',
    SECURITY_AND_PASSWORD: 'security-password',
    CLOSE_YOUR_ACCOUNT: 'close-your-account',
  },
  MERCHANT: '/merchant',
  MERCHANT_SETTINGS: {
    SETTINGS: '/merchant-settings',
    DETAILS: 'details',
    INVITE_ADMIN: 'admin-invite',
  },
  MERCHANT_CONFIGURATION: '/merchantConfiguration',
  ORGANIZATION: '/organization',
  ORGANIZATION_DETAILS: '/organization/:id',

  // Errors
  ERROR: {
    NOTFOUND: '/404',
    FORBIDDEN: '/oh-lost-one',
  },
};

export const PLAN_FEATURES = {
  Reminder: 1,
  UnlimitedClients: 2,
  UnlimitedOwners: 3,
  Max5Clients: 11,
  Max2Owners: 12,
  UnlimitedClientInvoices: 14,
  RecurringInvoices: 21,
  UnlimitedExternalUsers: 22,
  ExternalReceipts: 23,
  Max5ExternalUsers: 31,
  UnlimitedInvoices: 32,
  EmailNotification: 91,
  InsightsAndAnalytics: 92,
  PdfDownload: 93,
  Invoicing: 94,
  Receipting: 95,
};

export const INVOICE_STATUS_DROPDOWN_DATA = {
  label: 'Status',
  option: [
    {
      label: 'All',
      value: null,
    },
    {
      label: INVOICE_STATUS.APPROVED,
      value: 1,
    },
    {
      label: INVOICE_STATUS.PENDING,
      value: 0,
    },
    {
      label: INVOICE_STATUS.DECLINED,
      value: 2,
    },
    {
      label: INVOICE_STATUS.CHANGE_REQUESTED,
      value: 3,
    },
  ],
};

export const BILL_STATUS_DROPDOWN_DATA = {
  label: 'Status',
  option: [
    {
      label: 'All',
      value: null,
    },
    {
      label: BILL_STATUS.PAID,
      value: BILL_STATUS.PAID,
    },
    {
      label: BILL_STATUS.UNPAID,
      value: BILL_STATUS.UNPAID,
    },
  ],
};

export const Active_STATUS_DROPDOWN_DATA = {
  label: 'Status',
  option: [
    {
      label: 'All',
      value: null,
    },
    {
      label: BOOLEAN_STATUS.True,
      value: true,
    },
    {
      label: BOOLEAN_STATUS.False,
      value: false,
    },
  ],
};

export const INVOICE_PAYMENT_STATUS_DROPDOWN_DATA = {
  label: 'Payment',
  option: [
    {
      label: INVOICE_PAYMENT_STATUS.PAID,
      value: 2,
    },
    {
      label: INVOICE_PAYMENT_STATUS.VALIDATIONREQUESTED,
      value: 1,
    },
    {
      label: INVOICE_PAYMENT_STATUS.UNPAID,
      value: 0,
    },
  ],
};

export const RECEIPT_TYPES = {
  label: 'Receipt Type',
  option: [
    {
      value: 1,
      label: 'Invoice',
    },
    {
      value: 2,
      label: 'Bills',
    },
  ],
};

export const RECUR_TYPES = {
  Label: 'Recur Type',
  Options: [
    {
      value: 1,
      label: 'Daily',
    },
    {
      value: 2,
      label: 'Weekly',
    },
    {
      value: 3,
      label: 'Monthly',
    },
  ],
};

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SHORT_MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
export const MOBILE_WINDOW_SIZE = 768;

export const INVOICE_REGEX = /^\/invoices\/[a-zA-Z0-9]+$/;
export const INVOICE_EDIT_REGEX = /^\/invoices\/edit\/[a-z]/;
export const INVOICE_CREATE_REGEX = /^\/invoices\/create\/[a-z]+$/;
export const PREVIEW_INVOICE_REGEX = /^\/preview-invoice/;
export const RECEIPT_REGEX = /^\/receipts\/[a-zA-Z0-9]+$/;
export const PLAN_REGEX = /^\/plans\/[0-9]+$/;
export const PLAN_CREATE_REGEX = /^\/plans\/create$/;
export const RECURRING_INVOICE_CREATE_REGEX =
  /^\/recurringinvoice\/create\/[a-z]+$/;
export const RECURRING_INVOICE_REGEX = /^\/recurringinvoice\/[a-zA-Z0-9]+$/;
export const RECURRING_INVOICE_EDIT_REGEX = /^\/recurringinvoice\/edit\/[a-z]/;
export const ORGANIZATION_REGEX = /^\/organization\/[a-zA-Z0-9]+$/;
export const PREVIEW_RECURRING_INVOICE_REGEX = /^\/preview-recurring-invoice/;
export const PLAN_DETAILS_REGEX = /^\/plans\/details/;
