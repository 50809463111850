export const pruneReminders = (remindersArr) => {
  let count = 0;
  let remniders = [];
  for (let index = 0; index < remindersArr?.length; index++) {
    if (count >= 8) {
      break;
    } else {
      let i = 0;
      let reminderData = [];
      while (count < 8 && i < remindersArr[index]?.data?.length) {
        if (count > 8) {
          break;
        }
        reminderData.push(remindersArr[index]?.data[i]);

        count++;
        i++;
      }
      let newreminder = {
        ...remindersArr[index],
        data: reminderData,
      };
      remniders.push(newreminder);
    }
  }
  return remniders;
};
