import React, { useRef } from 'react';
import Table from 'app/components/molecules/table/table.component';
import Card from 'app/components/atoms/card/card.component';
import Text, { Heading } from 'app/components/atoms/typography';
import ActionMenu from 'app/components/molecules/action-menu/action-menu.component';
import { TableHeader } from 'app/pages/reminder/clientsTable';

const ReceiptList = ({
  receipts,
  totalCount,
  loading,
  paginationServer,
  isClient,
  ...rest
}) => {
  const componentRef = useRef();

  const basicMenu = (row) => (
    <ActionMenu
      popupList={['View', 'Download']}
      row={row}
      downloadFileName={row.receiptNo}
      downloadLink={row.receiptPdfUrl}
    />
  );

  const columns = [
    {
      name: 'Receipt No ',
      selector: (row) => row.receiptNo,
    },
    {
      name: 'Invoice No ',
      selector: (row) => row.invoiceNo,
    },
    {
      name: 'Client',
      selector: (row) => (isClient ? row.billTo : row.from),
      sortable: true,
    },
    {
      name: 'Total Amount',
      selector: (row) =>
        row.currency +
        ' ' +
        (row.totalAmount + row.totalTax)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      sortable: true,
    },
    {
      name: 'Date Created',
      selector: (row) => row.receiptCreatedDate,
      format: (row) => new Date(row.receiptCreatedDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => basicMenu(row),
      button: true,
    },
  ];

  return (
    <div>
      <Card>
        <div ref={componentRef}>
          <TableHeader
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0,
            }}
          >
            <div style={{ borderBottom: '1px solid #F2F6FF', width: '100%' }}>
              <Heading color=" var(--primary2)" level={2}>
                Receipt Log
              </Heading>
              <Text color="var(--grey2)">
                View the receipts of your paid {rest?.view}
              </Text>
            </div>
          </TableHeader>
          <Table
            columns={columns}
            data={receipts}
            pagination
            progressPending={loading}
            actions={<></>}
            paginationServer
            paginationTotalRows={totalCount}
            {...rest}
          />
        </div>
      </Card>
    </div>
  );
};

export default ReceiptList;
