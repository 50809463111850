import React, { useRef } from 'react';
import Table from 'app/components/molecules/table/table.component';
import ActionMenu from 'app/components/molecules/action-menu/action-menu.component';
import {
  createStatusStyle,
  getStatusByValue,
  getStatusName,
} from 'utils/helpers/createStatusStyle/createStatusStyle';

const ProformaList = ({
  transactions,
  totalCount,
  loading,
  paginationServer,
  isClient,
  ...rest
}) => {
  const componentRef = useRef();

  const basicMenu = (row) => (
    <ActionMenu
      popupList={['View', 'Download']}
      row={row}
      downloadFileName={row.receiptNo}
      downloadLink={row.receiptPdfUrl}
    />
  );

  const columns = [
    {
      name: 'Payment Ref',
      selector: (row) => row.transactionReference,
    },
    {
      name: 'Currency ',
      selector: (row) => row.currency,
    },
    {
      name: 'Payment status',
      selector: (row) => row.status,
      sortable: true,
      grow: 2,
      format: (row) => (
        <p
          style={{
            color: createStatusStyle(getStatusByValue(row.status)),
          }}
        >
          {getStatusName(getStatusByValue(row.status))}
        </p>
      ),
    },
    {
      name: 'Date',
      selector: (row) => row.dateInitiated,
      format: (row) => new Date(row.dateInitiated).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => basicMenu(row),
      button: true,
    },
  ];

  return (
    <div ref={componentRef}>
      <Table
        columns={columns}
        data={transactions}
        pagination
        progressPending={loading}
        actions={<></>}
        paginationServer
        paginationTotalRows={totalCount}
        {...rest}
      />
    </div>
  );
};

export default ProformaList;
