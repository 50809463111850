import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Flex } from '../dashboard/dashboard.styles';
import Text, { Heading } from 'app/components/atoms/typography';
import { RiArrowLeftSFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseButton } from 'app/components/atoms/button/button.styles';
import { FiDownload } from 'react-icons/fi';
import { convertCurrencyToSign } from 'utils/helpers/convertCurrencyToSign/convertCurrencyToSign';
import { setDecimalPlaces } from 'utils/helpers/setDecimalPlaces/setDecimalPlaces';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import { TbError404 } from 'react-icons/tb';
import {
  FlexReceiptButton,
  ResponsiveBaseButton,
  ResponsiveInvertedButton,
  ReceiptFooter,
} from '../receipt/receipt.style';
import { useDispatch, useSelector } from 'react-redux';
import { Vortex } from 'react-loader-spinner';
import { appDataActions } from 'store/reducers/appData/appDataSlice';
import { MOBILE_WINDOW_SIZE } from 'utils/constants/constants.utils';
import {
  FlexedDiv,
  InvoiceDetailsContent,
  TableTotalContainer,
  VeryFlexedDiv,
} from 'app/components/atoms/divs/div.styles';
import Card from 'app/components/atoms/card/card.component';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import { SettingsSelectors } from 'store/reducers/settings/settingsSlice';
import {
  paymentActions,
  paymentSelectors,
} from 'store/reducers/payment/paymentSlice';
import { format } from 'date-fns';
import {
  createStatusStyle,
  getStatusByValue,
} from 'utils/helpers/createStatusStyle/createStatusStyle';
import { unwrapResult } from '@reduxjs/toolkit';

const ProformaView = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [error, setError] = useState('');
  const transaction = useSelector(paymentSelectors.transaction)?.data;
  const loading = useSelector(paymentSelectors.isLoading);
  const organization = useSelector(SettingsSelectors.organization);
  const { getTransactionByReference } = paymentActions;
  const { handleDownload } = useHandleApiResponse();
  const { setHeaderText, resetState } = appDataActions;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef();
  const { id } = useParams();

  const handlePrint = () => {};

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(getTransactionByReference({ transactionReference: id }))
      .then(unwrapResult)
      .then((res) => {
        dispatch(
          setHeaderText({
            value: res?.payload?.data?.transactionReference,
            type: 'setHeaderText',
          })
        );
      })
      .catch((err) => {
        setError(err.message);
      });

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      dispatch(resetState());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {windowWidth > MOBILE_WINDOW_SIZE && (
        <Card>
          <Flex textAlign="left">
            <div>
              <Heading color=" var(--primary2)">
                {transaction?.transactionReference}
              </Heading>
              <VeryFlexedDiv justifyContent="flex-start" gap="10px">
                <Text
                  color={createStatusStyle(
                    getStatusByValue(transaction?.status)
                  )}
                >
                  {getStatusByValue(transaction?.status)}
                </Text>
                <Text>{format(new Date(), 'dd MMM yyyy')}</Text>
                <Text>
                  {transaction?.currency}{' '}
                  {addCommasToNumber(transaction?.totalAmount)}
                </Text>
              </VeryFlexedDiv>
            </div>
            <BaseButton width="true" onClick={() => navigate(-1)}>
              <RiArrowLeftSFill color="white" /> <Text color="white">Back</Text>
            </BaseButton>
          </Flex>
        </Card>
      )}

      {windowWidth < MOBILE_WINDOW_SIZE && (
        <Text
          color={createStatusStyle(getStatusByValue(transaction?.status))}
          margin="15px "
        >
          {getStatusByValue(transaction?.status)}
        </Text>
      )}

      <div className="dashboard-container">
        <div>
          <FlexReceiptButton>
            {transaction?.transactionReference && (
              <ResponsiveBaseButton
                width="true"
                marginRight="15px"
                onClick={() =>
                  window.open(
                    `${window.location.origin}/remitapay/${transaction.transactionReference}`,
                    '_blank'
                  )
                }
              >
                Pay
              </ResponsiveBaseButton>
            )}
            {transaction?.pdfUrl && (
              <div
                className="invoice-details-download-button"
                style={{ marginLeft: 'auto' }}
                onClick={handlePrint}
              >
                <ResponsiveInvertedButton
                  padding="0 5px"
                  mobilePadding="8px 7px"
                  onClick={() => {
                    transaction.receiptPdfUrl &&
                      handleDownload(
                        transaction.receiptPdfUrl,
                        transaction.receiptNo
                      );
                  }}
                >
                  <FiDownload style={{ marginRight: '10px' }} />
                  <Text>Download Bill</Text>
                </ResponsiveInvertedButton>
              </div>
            )}
          </FlexReceiptButton>

          {loading || (_.isEmpty(transaction) && !error) ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <Vortex
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperClass="vortex-wrapper"
                colors={['red', 'blue', 'yellow', '#fe9900']}
              />
            </div>
          ) : error ? (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '50vh',
              }}
            >
              <TbError404 size={150} />
              {error}
            </div>
          ) : (
            <InvoiceDetailsContent
              ref={componentRef}
              repeat={'3'}
              marginTop="40px"
              bgcolor={organization?.billingHeaderColor ?? '#542AC1'}
            >
              <Card className="card">
                <div className="card__header">
                  {organization?.logo && (
                    <img src={organization?.logo} alt="" />
                  )}
                  <Heading size="48px" color="#f2f6ff" responsiveFontSize={24}>
                    {transaction.transactionReference}
                  </Heading>
                  <Text color="#B5CCFE" size="18px" responsiveFontSize={14}>
                    Reference Number
                  </Text>
                </div>

                <div className="card__body">
                  <div className="flex">
                    <div>
                      <Text responsiveFontSize={14}>Invoice Number</Text>
                      {transaction.paymentBeneficiaryInformation.map(
                        (beneficiary) => (
                          <Text
                            weight="500"
                            size="18px"
                            responsiveFontSize={14}
                            key={beneficiary.id}
                          >
                            {beneficiary.id}
                          </Text>
                        )
                      )}
                    </div>
                    <div className="right">
                      <Text responsiveFontSize={14}>Pay to:</Text>
                      <div>
                        {transaction.paymentBeneficiaryInformation.map(
                          (beneficiary) => (
                            <Text
                              weight="500"
                              size="18px"
                              responsiveFontSize={14}
                              key={beneficiary.id}
                            >
                              {beneficiary.accountName}
                            </Text>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="grid__header proforma">
                      <Text responsiveFontSize={12} weight="500">
                        Invoice Number
                      </Text>
                      <Text responsiveFontSize={12} weight="500">
                        Client Name
                      </Text>
                      <Text responsiveFontSize={12} weight="500">
                        Amount
                      </Text>
                    </div>

                    <div>
                      {transaction?.paymentBeneficiaryInformation?.map(
                        ({ id, accountName, amount }, key) => (
                          <div className="grid__body" key={key}>
                            <Text responsiveFontSize={12}>{id}</Text>
                            <Text responsiveFontSize={12}>{accountName}</Text>
                            <Text responsiveFontSize={12}>
                              {addCommasToNumber(setDecimalPlaces(amount))}
                            </Text>
                          </div>
                        )
                      )}
                    </div>

                    <TableTotalContainer
                      style={{
                        fontWeight: '700',
                        fontSize: '20px',
                        borderTop: '1px solid #a4b0c9',
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 'auto',
                          width: '100%',
                          maxWidth: '510px',
                        }}
                      >
                        <FlexedDiv
                          style={{
                            padding: '16px 10px',
                          }}
                        >
                          <Text size="16px" responsiveFontSize={12}>
                            Sub Total:
                          </Text>
                          <Text size="16px" responsiveFontSize={12}>
                            {convertCurrencyToSign(transaction.currency)}
                            {addCommasToNumber(
                              setDecimalPlaces(transaction.totalAmount)
                            )}
                          </Text>
                        </FlexedDiv>
                        <FlexedDiv
                          style={{
                            padding: '16px 10px',
                          }}
                        >
                          <Text size="16px" responsiveFontSize={12}>
                            Service Charge:{' '}
                          </Text>
                          <Text size="16px" responsiveFontSize={12}>
                            {convertCurrencyToSign(transaction.currency)}
                            {addCommasToNumber(
                              setDecimalPlaces(transaction.serviceCharge)
                            )}
                          </Text>
                        </FlexedDiv>
                        <FlexedDiv
                          style={{
                            backgroundColor: 'var(--primary8)',
                            padding: '16px 10px',
                          }}
                        >
                          <Text size="20px" responsiveFontSize={12}>
                            Total
                          </Text>
                          <Text size="20px" responsiveFontSize={12}>
                            {convertCurrencyToSign(transaction.currency)}
                            {addCommasToNumber(
                              setDecimalPlaces(
                                transaction.totalAmount +
                                  transaction.serviceCharge
                              )
                            )}
                          </Text>
                        </FlexedDiv>
                      </div>
                    </TableTotalContainer>
                  </div>
                  <ReceiptFooter>
                    Powered by Easei
                    <img
                      src="https://easeitestac8f.blob.core.windows.net/uploadblob-test/easeiInvoiceLogo.png"
                      alt=""
                      width="18"
                      height="18"
                      style={{ marginLeft: '13px' }}
                    />
                  </ReceiptFooter>
                </div>
              </Card>
            </InvoiceDetailsContent>
          )}
        </div>
      </div>
    </>
  );
};

export default ProformaView;
