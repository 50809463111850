import styled from 'styled-components';

export const CreateAccountAdSection = styled.section`
  background-color: var(--primary2);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 69px 34px;

  svg {
    display: none;
  }

  p,
  h1 {
    color: white;
  }
  span {
    display: block;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 62px 0;
    text-align: center;
  }

  .banner-signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 80%;
    margin: 20px auto;
    gap: 1rem;
  }

  @media screen and (min-width: 650px) {
    padding-top: 10px;
    .banner-signup-button {
      flex-direction: row;
    }
  }

  @media screen and (min-width: 768px) {
    svg {
      display: flex;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 60px;
      padding-bottom: 60px;
      text-align: left;
      gap: 0 60px;
    }

    .banner-signup-button {
      justify-content: flex-start;
      margin: 0;
    }
  }
`;
