import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import 'react-circular-progressbar/dist/styles.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from 'styled-components';
import { ViewportProvider } from './providers/viewport.provider';
import { authSelectors } from 'store/reducers/auth/authSlice';
import Home from 'app/pages/home/home.component';
import Dashboard from 'app/pages/dashboard';
import { useCheckAdmin } from 'hooks';
import People from 'app/pages/people/people.component';
import NotFound from 'app/pages/error/notfound.component';
import SettingsLayout from 'app/layouts/settings.layout';
import OrganizationSettings from 'app/pages/settings/organization.settings.component';
import InvoiceSettings from 'app/pages/settings/invoice.settings.component';
import ProfileSettings from 'app/pages/settings/profile-settings';
import PersonalInfoSettings from 'app/pages/settings/profile-settings/profile-settings.personal-info.component';
import LoadingDots from 'app/components/atoms/loading-dots/loading-dots.component';
import {
  OrganizationSignUp,
  OrganizationUserSignUp,
  ClientUserSignUp,
  ResetPassword,
  EmailVerification,
  CheckYourEmail,
  ConfirmRegistration,
  ForgotPassword,
  Login,
} from 'app/pages/authentication/index';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { MOBILE_WINDOW_SIZE, ROUTES } from 'utils/constants/constants.utils';
import Organization from 'app/pages/organization/organization.component';
import ConfirmInvite from 'app/pages/authentication/confirm-invite/confirm-invite.component';
import Policy from 'app/pages/about/policy/policy.component';
import Security from 'app/pages/about/security/security.component';
import GDPR from 'app/pages/about/gdpr/gdpr.component';
import ContactUs from 'app/pages/about/contact/contact.component';
import Terms from 'app/pages/about/terms/terms.component';
import Reminder from 'app/pages/reminder/reminder';
import AccountSettings from 'app/pages/settings/accountSettings';
import PaymentSettings from 'app/pages/settings/paymentSettings';
import SecurityPassword from 'app/pages/settings/securityPassword.component';
import CloseAccount from 'app/pages/settings/closeAccount.component';
import FAQ from 'app/pages/FAQ/faq.component';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { microsoftConfig } from 'utils/helpers/microsoftConfiguration/microsoftConfiguration';
import Receipt from 'app/pages/receipt/receipt-component';
import ReceiptView from 'app/pages/receipt/receiptView';
import PlansComponent from 'app/pages/plans/plans-component';
import MobilePlanContent from 'app/pages/plans/plan.mobile.content';
import BaseCreatePlan from 'app/pages/plans/create-plan/base.create.plan';
import PaymentConfirmation from 'app/pages/settings/paymentConfirmation';
import AccessDenied from 'app/components/molecules/access-denied/accessDenied';
import OrganizationDetails from 'app/components/molecules/organization/organisation-details';
import Invoice from 'app/pages/invoice/invoice.component';
import CreateExternalInvoice from 'app/pages/invoice/createInvoice/create-external-invoice';
import CreateInternalInvoice from 'app/pages/invoice/createInvoice/create-internal-invoice';
import PreviewInvoice from 'app/pages/invoice/viewInvoice/PreviewInvoice';
import ViewInvoice from 'app/pages/invoice/viewInvoice/ViewInvoice';
import EditInternalInvoice from 'app/pages/invoice/editInvoice/edit-internal-invoice';
import RecurringInvoice from 'app/pages/recurringInvoice/recurring-invoice';
import CreateInternalRecurringInvoice from 'app/pages/recurringInvoice/create/create-internal';
import CreateExternalRecurringInvoice from 'app/pages/recurringInvoice/create/create-external';
import EditExternalRecurringInvoice from 'app/pages/recurringInvoice/edit/edit-external';
import EditInternalRecurringInvoice from 'app/pages/recurringInvoice/edit/edit-internal';
import PreviewRecurringInvoice from 'app/pages/recurringInvoice/view/preview';
import ViewRecurringInvoice from 'app/pages/recurringInvoice/view/view';
import PaymentPolicy from 'app/pages/about/paymentPolicy/paymentPolicy.component';
import BillSettings from 'app/pages/settings/bills.settings.component';
import Bills from 'app/pages/bills/bills.component';
import CreateBill from 'app/pages/bills/createBill/create-bill';
import PreviewBill from 'app/pages/bills/viewBill/PreviewBill';
import ViewBIll from 'app/pages/bills/viewBill/ViewBill';
import GeneratePaymentReference from 'app/pages/proforma/generateReference/generate';
import Proforma from 'app/pages/proforma/proforma';
import ProformaView from 'app/pages/proforma/proformaView';
import RemitaPay from 'app/pages/remitapay/remitapay.component';
import Charge from 'app/pages/charge/charge.component';

const DashboardLayout = lazy(() => import('app/layouts/dashboard.layout'));
const RemitaPayLayout = lazy(() => import('app/layouts/remitapay.layout'));
const Easei = lazy(() => import('app/pages/home'));

const theme = {
  colors: {
    header: '#F2F6FF',
    body: '#fff',
    footer: '#F2F6FF',
  },
  mobile: '768px',
  tab: '1024',
  mainPadding: '20px 100px',
};

const PrivateRoute = ({ isAllowed, redirectPath = '/' }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

const PaymentRoute = ({ isAllowed, redirectPath = '/' }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return (
    <RemitaPayLayout>
      <Outlet />
    </RemitaPayLayout>
  );
};

const ProtectedRoute = () => {
  const currentUser = useSelector(authSelectors.isAuthenticated);
  if (currentUser) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  return <Login />;
};

function App() {
  const currentUser = useSelector(authSelectors.isAuthenticated);
  const { role } = useCheckAdmin();

  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const msalInstance = new PublicClientApplication(microsoftConfig);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ViewportProvider>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          <MsalProvider instance={msalInstance}>
            <Suspense fallback={<LoadingDots />}>
              <Routes>
                <Route path={ROUTES.HOME} element={<Easei />}>
                  <Route index element={<Home />} />
                  <Route path={ROUTES.PRIVACY} element={<Policy />} />
                  <Route path={ROUTES.SECURITY} element={<Security />} />
                  <Route path={ROUTES.GDPR} element={<GDPR />} />
                  <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
                  <Route path={ROUTES.TERMS} element={<Terms />} />
                  <Route
                    path={ROUTES.PAYMENT_POLICY}
                    element={<PaymentPolicy />}
                  />
                  <Route path={ROUTES.FAQ} element={<FAQ />} />
                </Route>
                <Route
                  path={ROUTES.SIGN_UP.ORGANIZATION}
                  element={<OrganizationSignUp />}
                />
                <Route
                  path={ROUTES.SIGN_UP.ORGANIZATION_USER}
                  element={<OrganizationUserSignUp />}
                />
                <Route
                  path={ROUTES.SIGN_UP.CLIENT_USER}
                  element={<ClientUserSignUp />}
                />
                <Route element={<ProtectedRoute />}>
                  <Route path={ROUTES.SIGN_IN} element={<Login />} />
                </Route>
                <Route
                  path={ROUTES.FORGOT_PASSWORD}
                  element={<ForgotPassword />}
                />
                <Route
                  path={ROUTES.RESET_PASSWORD}
                  element={<ResetPassword />}
                />
                <Route
                  path={ROUTES.CONFIRM_EMAIL}
                  element={<ConfirmRegistration />}
                />
                <Route
                  path={ROUTES.CONFIRM_INVITE}
                  element={<ConfirmInvite />}
                />
                <Route
                  path={ROUTES.VERIFY_EMAIL}
                  element={<EmailVerification />}
                />
                <Route path={ROUTES.CHECK_EMAIL} element={<CheckYourEmail />} />
                <Route path={ROUTES.ERROR.NOTFOUND} element={<NotFound />} />
                <Route
                  element={
                    <PrivateRoute
                      isAllowed={!!currentUser}
                      redirectPath={ROUTES.SIGN_IN}
                    />
                  }
                >
                  <Route
                    path={ROUTES.ERROR.FORBIDDEN}
                    element={<AccessDenied />}
                  />

                  <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />

                  {role !== USER_ROLES.EASEI_ADMIN && (
                    <Route
                      path={ROUTES.RECURRINGINVOICE}
                      element={<RecurringInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.CREATE_INTERNAL_RECURRING_INVOICE}
                      element={<CreateInternalRecurringInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.CREATE_EXTERNAL_RECURRING_INVOICE}
                      element={<CreateExternalRecurringInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.EDIT_EXTERNAL_RECURRING_INVOICE}
                      element={<EditExternalRecurringInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.EDIT_INTERNAL_RECURRING_INVOICE}
                      element={<EditInternalRecurringInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.PREVIEW_RECURRING_INVOICE}
                      element={<PreviewRecurringInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.RECURRING_INVOICE_DETAIL}
                      element={<ViewRecurringInvoice />}
                    />
                  )}
                  {/* Profroma */}
                  {role !== USER_ROLES.CLIENT_USER && (
                    <Route path={ROUTES.PROFORMA.HOME} element={<Proforma />} />
                  )}
                  {role !== USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.PROFORMA.GENERATE}
                      element={<GeneratePaymentReference />}
                    />
                  )}
                  {role !== USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.PROFORMA.VIEW}
                      element={<ProformaView />}
                    />
                  )}
                  {role !== USER_ROLES.EASEI_ADMIN && (
                    <Route path={ROUTES.INVOICE} element={<Invoice />} />
                  )}
                  {/* TODO ADD APPROPRIATE ROLE ACCESS */}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.CREATE_EXTERNAL_INVOICE}
                      element={<CreateExternalInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.CREATE_INVOICE}
                      element={<CreateInternalInvoice />}
                    />
                  )}

                  {role === USER_ROLES.CLIENT_USER && (
                    <Route
                      path={ROUTES.PREVIEW_INVOICE}
                      element={<PreviewInvoice />}
                    />
                  )}

                  {role !== USER_ROLES.EASEI_ADMIN && (
                    <Route
                      path={ROUTES.INVOICE_DETAILS}
                      element={<ViewInvoice />}
                    />
                  )}

                  {role !== USER_ROLES.EASEI_ADMIN && (
                    <Route
                      path={ROUTES.EDIT_INTERNAL_INVOICE}
                      element={<EditInternalInvoice />}
                    />
                  )}

                  {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                    role === USER_ROLES.ORGANIZATION_USER) && (
                    <Route path={ROUTES.BILLS} element={<Bills />} />
                  )}

                  {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                    role === USER_ROLES.ORGANIZATION_USER) && (
                    <Route
                      path={ROUTES.CREATE_BILLS}
                      element={<CreateBill />}
                    />
                  )}
                  {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                    role === USER_ROLES.ORGANIZATION_USER) && (
                    <Route
                      path={ROUTES.PREVIEW_BILL}
                      element={<PreviewBill />}
                    />
                  )}

                  {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                    role === USER_ROLES.ORGANIZATION_USER) && (
                    <Route path={ROUTES.BILL_DETAILS} element={<ViewBIll />} />
                  )}

                  {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                    role === USER_ROLES.ORGANIZATION_USER) && (
                    <Route path={ROUTES.REMINDER} element={<Reminder />} />
                  )}

                  {(role === USER_ROLES.EASEI_ADMIN ||
                    role === USER_ROLES.EASEI_USER) && (
                    <Route path={ROUTES.CHARGE} element={<Charge />} />
                  )}

                  {(role === USER_ROLES.EASEI_ADMIN ||
                    role === USER_ROLES.EASEI_USER) && (
                    <Route
                      path={ROUTES.ORGANIZATION}
                      element={<Organization />}
                    />
                  )}

                  {(role === USER_ROLES.EASEI_ADMIN ||
                    role === USER_ROLES.EASEI_USER) && (
                    <Route
                      path={ROUTES.ORGANIZATION_DETAILS}
                      element={<OrganizationDetails />}
                    />
                  )}

                  <Route path={ROUTES.PEOPLE} element={<People />} />
                  <Route path={ROUTES.RECEIPT} element={<Receipt />} />
                  <Route
                    path={ROUTES.RECEIPT_DETAILS}
                    element={<ReceiptView />}
                  />
                  {(role === USER_ROLES.EASEI_ADMIN ||
                    role === USER_ROLES.EASEI_USER) && (
                    <Route path={ROUTES.PLANS} element={<PlansComponent />} />
                  )}
                  {(role === USER_ROLES.EASEI_ADMIN ||
                    role === USER_ROLES.EASEI_USER) && (
                    <Route
                      path={ROUTES.EDIT_PLAN}
                      element={<BaseCreatePlan />}
                    />
                  )}
                  {(role === USER_ROLES.EASEI_ADMIN ||
                    role === USER_ROLES.EASEI_USER) && (
                    <Route
                      path={ROUTES.CREATE_PLAN}
                      element={<BaseCreatePlan />}
                    />
                  )}
                  {(role === USER_ROLES.EASEI_ADMIN ||
                    role === USER_ROLES.EASEI_USER) &&
                    (windowWidth < MOBILE_WINDOW_SIZE ? (
                      <Route
                        path={ROUTES.PLANS_DETAILS}
                        element={<MobilePlanContent />}
                      />
                    ) : (
                      <Route
                        path={ROUTES.PLANS_DETAILS}
                        element={<PlansComponent />}
                      />
                    ))}

                  {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                    role === USER_ROLES.CLIENT_USER) && (
                    <Route
                      path={ROUTES.SETTINGS.VERIFY_PAYMENT}
                      element={<PaymentConfirmation />}
                    />
                  )}

                  <Route
                    path={ROUTES.SETTINGS.SETTINGS}
                    element={<SettingsLayout />}
                  >
                    {role === USER_ROLES.ORGANIZATION_ADMIN && (
                      <Route
                        index
                        element={<Navigate to={ROUTES.SETTINGS.ORGANIZATION} />}
                      />
                    )}
                    {role === USER_ROLES.CLIENT_USER && (
                      <Route
                        index
                        element={<Navigate to={ROUTES.SETTINGS.ACCOUNT} />}
                      />
                    )}
                    {(role === USER_ROLES.ORGANIZATION_USER ||
                      role === USER_ROLES.EASEI_USER ||
                      role === USER_ROLES.EASEI_ADMIN) && (
                      <Route
                        index
                        element={
                          <Navigate to={ROUTES.PROFILE_SETTINGS.SETTINGS} />
                        }
                      />
                    )}

                    {role === USER_ROLES.ORGANIZATION_ADMIN && (
                      <Route
                        path={ROUTES.SETTINGS.ORGANIZATION}
                        element={<OrganizationSettings />}
                      />
                    )}

                    {role === USER_ROLES.ORGANIZATION_ADMIN && (
                      <Route
                        path={ROUTES.SETTINGS.BILL}
                        element={<BillSettings />}
                      />
                    )}
                    <Route
                      path={ROUTES.SETTINGS.INVOICE}
                      element={<InvoiceSettings />}
                    />
                    {role === USER_ROLES.CLIENT_USER && (
                      <Route
                        path={ROUTES.SETTINGS.ACCOUNT}
                        element={<AccountSettings />}
                      />
                    )}
                    {(role === USER_ROLES.ORGANIZATION_ADMIN ||
                      role === USER_ROLES.CLIENT_USER) && (
                      <Route
                        path={ROUTES.SETTINGS.PAYMENT}
                        element={<PaymentSettings />}
                      />
                    )}
                    <Route
                      path={ROUTES.PROFILE_SETTINGS.SETTINGS}
                      element={<ProfileSettings />}
                    >
                      <Route
                        index
                        element={
                          <Navigate
                            to={ROUTES.PROFILE_SETTINGS.PERSONAL_INFORMATION}
                          />
                        }
                      />
                      <Route
                        path={ROUTES.PROFILE_SETTINGS.PERSONAL_INFORMATION}
                        element={<PersonalInfoSettings />}
                      />
                      <Route
                        path={ROUTES.PROFILE_SETTINGS.SECURITY_AND_PASSWORD}
                        element={<SecurityPassword />}
                      />
                      <Route
                        path={ROUTES.PROFILE_SETTINGS.CLOSE_YOUR_ACCOUNT}
                        element={<CloseAccount />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <PaymentRoute
                      isAllowed={!!currentUser}
                      redirectPath={'/'}
                    />
                  }
                >
                  <Route
                    path={ROUTES.ERROR.FORBIDDEN}
                    element={<AccessDenied />}
                  />

                  <Route path={ROUTES.REMITAPAY.PAY} element={<RemitaPay />} />
                </Route>
                <Route path="*" element={<Login />} />
              </Routes>
            </Suspense>
          </MsalProvider>
        </GoogleOAuthProvider>
      </ViewportProvider>
    </ThemeProvider>
  );
}

export default App;
