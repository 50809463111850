import {
  ContentWrapper,
  Div,
  InvoiceInputDataHouse,
  InvoiceSubjectInput,
} from 'app/components/atoms/generalInvoiceStyles/general.sytlyes';
import Table from 'app/components/molecules/table/table.component';
import { Label } from 'app/pages/plans/plan.style';
import { TableHeader } from 'app/pages/reminder/clientsTable';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { InvertedButton } from 'app/components/atoms/button/button.styles';
import Button from 'app/components/atoms/button/button.component';
import { FlexedDiv } from 'app/components/atoms/divs/div.styles';
import { toast } from 'react-toastify';
const AddMultipleOrganizations = ({
  orgList,
  setOrgList,
  clientList,
  organization,
  closeModal,
}) => {
  const [address, setAddress] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [tempOrgList, setTempOrgList] = useState([...orgList]);
  const [clientPhonenumber, setClientPhoneNumber] = useState('');

  const columns = React.useMemo(
    () => [
      {
        name: 'Client name',
        selector: (row) => row.label ?? row.fullName,
        grow: 1.5,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: 'Address',
        selector: (row) => row.address,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Phone Number',
        selector: (row) => row.phoneNumber,
      },
      {
        Name: 'Action',
        button: true,
        grow: 1,
        selector: (row) => {
          return (
            <span
              style={{
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
              }}
              onClick={() => {
                // ES6 Syntax use the rvalue if your data is an array.
                let dataCopy = [...tempOrgList];
                // It should not matter what you name tableProps. It made the most sense to me.
                dataCopy = dataCopy.filter((item) => item.label !== row.label);
                setTempOrgList(dataCopy);
              }}
            >
              remove
            </span>
          );
        },
      },
    ],
    [tempOrgList]
  );
  useEffect(() => {
    if (organization) {
      setClientEmail(organization.email);
      setClientPhoneNumber(organization.phoneNumber);
      setAddress(organization.address);
    }
  }, [organization]);
  const handleSave = () => {
    const isExistingOrganization = tempOrgList.find(
      (org) =>
        org.label === organization.label ||
        org.fullName === organization.fullName ||
        org.email === organization.email
    );
    if (_.isEmpty(isExistingOrganization)) {
      let newOrganization = {
        ...organization,
        address,
        phoneNumber: clientPhonenumber,
        email: clientEmail,
      };

      if (newOrganization.__isNew__) {
        newOrganization = {
          ...newOrganization,
          id: 0,
          value: 0,
          fullName: organization?.label,
        };
      }
      setTempOrgList([...tempOrgList, newOrganization]);
      setClientEmail('');
      setClientPhoneNumber('');
      setAddress('');
    } else {
      toast.error('Organization already exists!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleAddClient = () => {
    setOrgList([...tempOrgList]);
    closeModal();
  };
  return (
    <section>
      <TableHeader>
        <Table columns={columns} data={tempOrgList} actions={<p>remove</p>} />
      </TableHeader>
      {tempOrgList.length < 10 && (
        <>
          <ContentWrapper repeat="2">
            {clientList}
            <InvoiceInputDataHouse>
              <Label>Email Address</Label>
              <InvoiceSubjectInput
                placeholder="Client email"
                type="email"
                value={clientEmail}
                disabled={organization?.email}
                onChange={(e) => setClientEmail(e.target.value)}
              />
            </InvoiceInputDataHouse>
            <InvoiceInputDataHouse>
              <Label>Address</Label>
              <InvoiceSubjectInput
                placeholder="Client Address"
                value={address}
                disabled={organization?.address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </InvoiceInputDataHouse>
            <InvoiceInputDataHouse>
              <Label>Phone Number</Label>
              <InvoiceSubjectInput
                placeholder="Client phone number"
                value={clientPhonenumber}
                disabled={organization?.phoneNumber}
                onChange={(e) => setClientPhoneNumber(e.target.value)}
              />
            </InvoiceInputDataHouse>
          </ContentWrapper>
          {organization && clientEmail && address && clientPhonenumber && (
            <Div justifyContent="flex-end">
              <button onClick={handleSave}>Save</button>
            </Div>
          )}
        </>
      )}

      <FlexedDiv justifyContent="center" style={{ paddingTop: '60px' }}>
        <InvertedButton
          margin="0 10px 0 0"
          maxWidth="246px"
          onClick={(e) => closeModal()}
        >
          Cancel
        </InvertedButton>
        {/* {tempOrgList.length > 0 && ( */}
        <Button
          isLoading={false}
          disabled={false}
          maxWidth="246px"
          onClick={handleAddClient}
        >
          Add Client
        </Button>
        {/* )} */}
      </FlexedDiv>
    </section>
  );
};

export default AddMultipleOrganizations;
