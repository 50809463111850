import Text from 'app/components/atoms/typography';
import { USER_ROLES } from 'store/reducers/auth/utils';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import { setDecimalPlaces } from 'utils/helpers/setDecimalPlaces/setDecimalPlaces';
import { convertCurrencyToSign } from 'utils/helpers/convertCurrencyToSign/convertCurrencyToSign';
import {
  AdditionalNoteArea,
  TableTotalContainer,
} from 'app/components/atoms/divs/div.styles';
import { TextArea } from 'app/components/atoms/textArea/textArea';
import { Div } from 'app/components/atoms/generalInvoiceStyles/general.sytlyes';

export const InvoiceRecordSummary = ({
  summaryDisplayCondition,
  totalAmount,
  subTotal,
  vat,
  currency,
  comment,
  role,
  placeholder,
  setComment,
  preview = false,
  bill = false,
}) => {
  return (
    <>
      <Div justifyContent="flex-end">
        {' '}
        {!preview && summaryDisplayCondition && (
          <TableTotalContainer>
            <div className="sub">
              <Text color="#3A3E47" style={{ whiteSpace: 'nowrap' }}>
                Sub Total:
              </Text>
              <Text margin="0" color="#3A3E47">
                {convertCurrencyToSign(currency)}{' '}
                {addCommasToNumber(setDecimalPlaces(subTotal))}
              </Text>
            </div>
            <div className="sub">
              <Text color="#3A3E47">Tax: </Text>
              <Text color="#3A3E47">
                {convertCurrencyToSign(currency)}{' '}
                {addCommasToNumber(setDecimalPlaces(vat))}
              </Text>
            </div>
            <div className="sub">
              <Text color="#3A3E47">Total</Text>
              <Text color="#3A3E47">
                {convertCurrencyToSign(currency)}{' '}
                {addCommasToNumber(setDecimalPlaces(totalAmount))}
              </Text>
            </div>
          </TableTotalContainer>
        )}
        {preview && summaryDisplayCondition && (
          <TableTotalContainer>
            <div className="sub">
              <Text color="#6C98F3" style={{ whitespace: 'nowrap' }}>
                {' '}
                Sub Total:
              </Text>
              <Text>
                {' '}
                {convertCurrencyToSign(currency)}{' '}
                {addCommasToNumber(setDecimalPlaces(subTotal))}
              </Text>
            </div>
            <div className="sub">
              <Text color="#6C98F3"> Tax:</Text>
              <Text>
                {' '}
                {convertCurrencyToSign(currency)}{' '}
                {addCommasToNumber(setDecimalPlaces(vat))}
              </Text>
            </div>
            <div className="sub">
              <Text color="#6C98F3"> Total:</Text>
              <Text>
                {convertCurrencyToSign(currency)}{' '}
                {addCommasToNumber(setDecimalPlaces(totalAmount))}
              </Text>
            </div>
          </TableTotalContainer>
        )}
      </Div>
      {!preview && role && !bill && (
        <AdditionalNoteArea>
          <Text margin="15px" weight={700}>
            Additional notes
          </Text>
          {(role === USER_ROLES.ORGANIZATION_ADMIN ||
            role === USER_ROLES.ORGANIZATION_USER) && (
            <TextArea value={comment || ''} width="70%" readOnly></TextArea>
          )}
          {role === USER_ROLES.CLIENT_USER && (
            <TextArea
              placeholder={placeholder}
              className="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></TextArea>
          )}
        </AdditionalNoteArea>
      )}
    </>
  );
};
