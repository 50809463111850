import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './root-reducer';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { requestMiddleWare } from './middleware/request.middleware';
import { requestV2MiddleWare } from './middleware/requestv2.middleware';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'invoice'],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'settingsSlice.uploadFile',
        ],
      },
    }).concat(requestMiddleWare, requestV2MiddleWare),
});

export const persistor = persistStore(store);

export default store;
