import Text, { Heading } from 'app/components/atoms/typography';
import { Flex } from 'app/pages/dashboard/dashboard.styles';
import React, { useEffect, useRef, useState } from 'react';
import { HiLink } from 'react-icons/hi';
import Image from '../../../assets/img/manage-client-img.png';
import {
  OrganisationDetailsContainer,
  OrganisationFlex,
  OrganisationInfo,
  OrganizationDetailsWrapper,
  TextTitle,
} from './organization.styles';
import {
  OrganizationActions,
  organizationSelectors,
} from 'store/reducers/organization/organizationSlice';
import { appDataActions } from 'store/reducers/appData/appDataSlice';
import { useViewport } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Vortex } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseButton } from 'app/components/atoms/button/button.styles';
import { RiArrowLeftSFill } from 'react-icons/ri';
import { MOBILE_WINDOW_SIZE } from 'utils/constants/constants.utils';
import { CardStyle } from 'app/components/atoms/card/card.component';

const dateFormat = (date) => {
  return new Date(date).toLocaleDateString('en-us', {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  });
};

const OrganizationDetails = () => {
  const { mobile } = useViewport();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const organization = useSelector(organizationSelectors.organization);
  const loading = useSelector(organizationSelectors.isLoading);
  const { getById } = OrganizationActions;
  const { setHeaderText, resetState } = appDataActions;
  const navigate = useNavigate();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(getById(id)).then(() => {
      dispatch(setHeaderText({ value: 'Organization', type: 'setHeaderText' }));
    });

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      dispatch(resetState());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {windowWidth > MOBILE_WINDOW_SIZE && (
        <Flex justifyContent="flex-end" margin="31px 116px 0 0">
          <BaseButton width="fit-content" onClick={() => navigate(-1)}>
            {' '}
            <RiArrowLeftSFill />
            BACK
          </BaseButton>
        </Flex>
      )}
      <OrganizationDetailsWrapper>
        <CardStyle
          radius={mobile ? '20px' : '12px'}
          padding="30px"
          margin={mobile ? '60px 30px' : '0px 15px 0 '}
          height="fit-content"
          width={mobile ? 'calc(100%-30px)' : '80%'}
        >
          {loading || organization === undefined || organization === null ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <Vortex
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperClass="vortex-wrapper"
                colors={['red', 'blue', 'yellow', '#fe9900']}
              />
            </div>
          ) : (
            <OrganisationDetailsContainer ref={componentRef}>
              <Flex justifyContent="flex-start" gap="10px">
                <div className="organization_img">
                  <img src={Image} alt="" />
                </div>
                <div>
                  <Text
                    size={mobile ? 16 : 20}
                    color="var(--primary)"
                    weight={500}
                  >
                    {organization?.businessName}
                  </Text>
                  <Text
                    size={mobile ? 14 : 16}
                    color="var(--primary1)"
                    weight={500}
                  >
                    {organization.numberOfEmployees}{' '}
                    {organization.numberOfEmployees <= 1
                      ? 'employee'
                      : 'employees'}{' '}
                    onboarded
                  </Text>
                  <Text
                    as="a"
                    href={`mailto:${organization.businessEmail}`}
                    size={mobile ? 14 : 16}
                    color="var(--primary)"
                    weight={500}
                  >
                    <HiLink size={20} />
                    {organization.businessEmail}
                  </Text>
                </div>
              </Flex>
              <div style={{ marginTop: '25px' }}>
                <Heading size={mobile ? 14 : 20} margin="0 0 20px 0">
                  Organization Information
                </Heading>
                <OrganisationInfo>
                  <div>
                    <OrganisationFlex>
                      <TextTitle>Organization Sector:</TextTitle>
                      <Text size={mobile ? 14 : 16}>
                        {organization.organizationSector
                          ? organization.organizationSector
                          : ''}
                      </Text>
                    </OrganisationFlex>
                    <OrganisationFlex>
                      <TextTitle>Industry:</TextTitle>
                      <Text size={mobile ? 14 : 16}>
                        {organization.industry}
                      </Text>
                    </OrganisationFlex>
                    <OrganisationFlex>
                      <TextTitle>Plan:</TextTitle>
                      <Text size={mobile ? 14 : 16}>
                        {organization.planName}
                      </Text>
                    </OrganisationFlex>
                  </div>
                  <div>
                    <OrganisationFlex>
                      <TextTitle>No. of Employees:</TextTitle>
                      <Text size={mobile ? 14 : 16}>
                        {organization.numberOfEmployees}
                      </Text>
                    </OrganisationFlex>
                    <OrganisationFlex>
                      <TextTitle>Location:</TextTitle>
                      <Text size={mobile ? 14 : 16}>
                        {(organization?.organizationAddress?.street ?? '') +
                          ' ' +
                          (organization?.organizationAddress?.state ?? '') +
                          ' ' +
                          (organization?.organizationAddress?.country ?? '')}
                      </Text>
                    </OrganisationFlex>
                    <OrganisationFlex>
                      <TextTitle>Plan Expiry:</TextTitle>
                      <Text size={mobile ? 14 : 16}>
                        {dateFormat(organization.planEndDate)}
                      </Text>
                    </OrganisationFlex>
                  </div>
                </OrganisationInfo>
              </div>
              <hr />

              <div>
                <Heading size={mobile ? 14 : 20} margin="20px 0 20px 0">
                  Admin Information
                </Heading>
                <OrganisationFlex>
                  <TextTitle>Name:</TextTitle>
                  <Text size={mobile ? 14 : ''}>
                    {organization.businessName}
                  </Text>
                </OrganisationFlex>
                <OrganisationFlex>
                  <TextTitle>Email address:</TextTitle>
                  <Text size={mobile ? 14 : ''}>
                    {organization.businessEmail}
                  </Text>
                </OrganisationFlex>
                <OrganisationFlex>
                  <TextTitle>Plan:</TextTitle>
                  <Text size={mobile ? 14 : ''}>{organization.planName}</Text>
                </OrganisationFlex>
                <OrganisationFlex>
                  <TextTitle>Phone Number:</TextTitle>
                  <Text size={mobile ? 14 : ''}>
                    {organization.businessPhoneNumber}
                  </Text>
                </OrganisationFlex>
              </div>
            </OrganisationDetailsContainer>
          )}
        </CardStyle>
      </OrganizationDetailsWrapper>
    </>
  );
};

export default OrganizationDetails;
