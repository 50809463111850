import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import FormInput from 'app/components/atoms/form-input/form-input.component';
import Button from 'app/components/atoms/button/button.component';
import {
  GeneralValidator,
  NumberValidator,
} from 'utils/validation/validation.utils';

import { useDispatch, useSelector } from 'react-redux';
import { FormInputLable } from 'app/components/atoms/form-input/form-input.styles';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import Text from 'app/components/atoms/typography';
import FormSelect from 'app/components/atoms/form-select/form-select.component';
import { Flex } from '../dashboard/dashboard.styles';
import { ChargeActions } from 'store/reducers/charge/chargeSlice';

const FormContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  gap: 20px;

  > div {
    width: 100%;
  }
`;

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  max-width: 197px;
  margin-inline: auto;
`;

const validate = Yup.object({
  chargeType: NumberValidator,
  serviceCharge: NumberValidator,
  cap: NumberValidator,
  minimumAmount: NumberValidator,
  maximumAmount: NumberValidator,
  currency: GeneralValidator,
});

export const NewChargeModal = ({
  reload,
  currencies,
  setReloaad,
  close,
  data,
}) => {
  const defaultFormFields = {
    chargeType: data?.chargeType ?? '',
    serviceCharge: data?.serviceCharge ?? '',
    cap: data?.cap ?? '',
    isPercentage: data?.isPercentage,
    minimumAmount: data?.minimumAmount ?? '',
    maximumAmount: data?.maximumAmount ?? '',
    currency: data?.currency ?? '',
  };

  const { handleApiResponse } = useHandleApiResponse();
  const dispatch = useDispatch();
  const { createCharge, editCharge } = ChargeActions;

  const handleSubmit = (values, actions) => {
    if (data) {
      let id = data.id;
      dispatch(editCharge({ ...values, id })).then((res) => {
        handleApiResponse(res, null);
        setReloaad(!reload);
        close(false);
        actions.setSubmitting(false);
      });
    } else {
      dispatch(createCharge(values)).then((res) => {
        handleApiResponse(res, null);
        setReloaad(!reload);
        close(false);
        actions.setSubmitting(false);
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={defaultFormFields}
        enableReinitialize={true}
        validationSchema={validate}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Text textAlign="left" padding="0 20px">
              {data ? 'Edit ' : 'Add new '} charge on client's settlement
            </Text>
            <FormContainer>
              <div>
                <FormSelect
                  label={'Charge Type'}
                  placeholder="selete charge type"
                  type="text"
                  name="chargeType"
                  required
                  onChange={(value) => {
                    props.setFieldValue('chargeType', value.value);
                  }}
                  options={[
                    { value: 0, label: 'Invoice Settlement' },
                    { value: 1, label: 'Bill Settlement' },
                  ]}
                  height="40px"
                />
              </div>
              <div>
                <FormInput
                  label={'Charge'}
                  placeholder="10"
                  type="text"
                  name="serviceCharge"
                  required
                />
              </div>
              <div>
                <FormInputLable>Minimum Amount</FormInputLable>
                <FormInput
                  placeholder="10,000"
                  type="text"
                  name="minimumAmount"
                  required
                />
              </div>
              <div>
                <FormInputLable>Maximum Amount</FormInputLable>
                <FormInput
                  placeholder="100,000"
                  type="text"
                  name="maximumAmount"
                  required
                />
              </div>
              <div>
                <FormInput
                  label={'Cap'}
                  placeholder="250"
                  type="text"
                  name="cap"
                  required
                />
              </div>
              <div>
                <FormSelect
                  label={'Currency'}
                  placeholder="Select Currency"
                  type="text"
                  name="currency"
                  required
                  options={currencies.map((item) => ({
                    ...item,
                    value: item.label,
                  }))}
                  onChange={(value) => {
                    props.setFieldValue('currency', value.label);
                  }}
                  height="40px"
                />
              </div>
              <Flex>
                <FormInputLable htmlFor="isPercentage">
                  Is Percentage
                </FormInputLable>
                <input
                  type="checkbox"
                  id="isPercentage"
                  name="isPercentage"
                  defaultChecked={props.values.isPercentage}
                  onChange={() => {
                    props.setFieldValue(
                      'isPercentage',
                      !props.values.isPercentage
                    );
                  }}
                  style={{ marginRight: '4px' }}
                />
              </Flex>
            </FormContainer>
            <AddButtonContainer>
              <Button
                margin="15px 0"
                isLoading={props.isSubmitting}
                disabled={props.isSubmitting}
                type="submit"
              >
                {data ? 'Save' : 'Add'}
              </Button>
            </AddButtonContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};
