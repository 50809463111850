import { useEffect, useRef } from 'react';
import Text, { Heading } from 'app/components/atoms/typography';
import Card from 'app/components/atoms/card/card.component';
import {
  INVOICE_TYPE,
  MOBILE_WINDOW_SIZE,
} from 'utils/constants/constants.utils';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import { setDecimalPlaces } from 'utils/helpers/setDecimalPlaces/setDecimalPlaces';
import { useCheckAdmin, useViewport } from 'hooks';
import React, { useState } from 'react';
import { RiArrowLeftSFill } from 'react-icons/ri';
import { externalUserSelectors } from 'store/reducers/externalUser/externalUserSlice';
import { authSelectors } from 'store/reducers/auth/authSlice';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { convertCurrencyToSign } from 'utils/helpers/convertCurrencyToSign/convertCurrencyToSign';
import {
  getInvoiceTotalAmount,
  getInvoiceTotalCost,
  getInvoiceTotalTax,
} from 'utils/helpers/getInvoiceCalculations/getInvoiceCalculations';
import { BaseButton } from 'app/components/atoms/button/button.styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AccountDetails,
  AdditionalInfo,
  AdditionalNoteArea,
  InvoiceDetailsContent,
  VeryFlexedDiv,
} from 'app/components/atoms/divs/div.styles';
import { InvoiceHeader } from '../invoice.styles';
import { InvoiceRecordSummary } from 'app/components/cell/invoiceRecordSummary/InvoiceRecordSummary';
import LoadingDots from 'app/components/atoms/loading-dots/loading-dots.component';
import {
  createStatusStyle,
  getStatusName,
} from 'utils/helpers/createStatusStyle/createStatusStyle';
import { dateFormat } from 'utils/helpers/formatDate/formatDate';

const BaseInvoiceDetails = ({
  invoice,
  loading,
  backRoute,
  extraComponent,
}) => {
  const {
    date,
    subject,
    billTo,
    services = [],
    additionalInformation,
    currency,
    typeName,
    externalUserId,
    organizationId,
    bankName,
    accountName,
    accountNumber,
    sortCode,
  } = invoice;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { mobile } = useViewport();
  const { role } = useCheckAdmin();
  const { id } = useParams();
  const navigate = useNavigate();

  const componentRef = useRef();

  const externalUsersForDropdown = useSelector(
    externalUserSelectors.externalUsersForDropdown
  );

  const organizationsForDropdown = useSelector(
    authSelectors.organizationsForDropdown
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const orgDetails = () => {
    if (typeName === INVOICE_TYPE.Internal || organizationId > 0) {
      return organizationsForDropdown.find(function (org) {
        return org.value === organizationId;
      });
    } else if (typeName === INVOICE_TYPE.External || externalUserId > 0) {
      return externalUsersForDropdown.find(function (ext) {
        return ext.value === externalUserId;
      });
    }

    return null;
  };

  const handleGetBillTo = () => {
    if (billTo) {
      return billTo;
    } else {
      return orgDetails()?.label;
    }
  };

  const handleNavigateBack = (e) => {
    e.preventDefault();
    navigate(backRoute);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line
  }, [invoice]);

  if (loading || _.isEmpty(services)) {
    return <LoadingDots text={'Hold on'} />;
  }

  return (
    <div>
      <div className="invoice-details__close">
        {windowWidth > MOBILE_WINDOW_SIZE && (
          <Card padding="32px 40px 0">
            <InvoiceHeader>
              <Heading>{invoice?.invoiceNo}</Heading>
              <BaseButton width="true" onClick={handleNavigateBack}>
                <RiArrowLeftSFill color="white" />{' '}
                <Text color="white">Back</Text>
              </BaseButton>
            </InvoiceHeader>
            <VeryFlexedDiv
              margin="14px 0"
              justifyContent="true"
              className="invoice-details__banner"
            >
              <Text color={createStatusStyle(invoice?.statusName)}>
                {getStatusName(
                  invoice?.statusName ? invoice?.statusName : 'Status',
                  invoice?.invoicePaymentStatusName
                )}
              </Text>

              <Text
                borderL="1px solid var(--grey1)"
                borderR="1px solid var(--grey1)"
                padding="0 5px"
              >
                {dateFormat(invoice?.date || new Date())}
              </Text>

              {id ? (
                <Text>
                  {convertCurrencyToSign(invoice?.currency)}
                  {addCommasToNumber(invoice?.totalAmount + invoice?.totalTax)}
                </Text>
              ) : (
                <Text>
                  {convertCurrencyToSign(invoice?.currency)}
                  {addCommasToNumber(getInvoiceTotalAmount(invoice?.services))}
                </Text>
              )}
            </VeryFlexedDiv>
          </Card>
        )}
        {windowWidth < MOBILE_WINDOW_SIZE && (
          <VeryFlexedDiv
            margin={mobile ? '5px' : '5px 40px'}
            justifyContent="true"
            className="invoice-details__banner"
          >
            <Text color={createStatusStyle(invoice?.statusName)}>
              {getStatusName(
                invoice?.statusName ? invoice?.statusName : 'Status',
                invoice?.invoicePaymentStatusName
              )}
            </Text>
            <Text
              borderL="1px solid var(--grey1)"
              borderR="1px solid var(--grey1)"
              padding="0 5px"
            >
              {dateFormat(invoice?.date || new Date())}
            </Text>

            {id ? (
              <Text>
                {convertCurrencyToSign(invoice?.currency)}
                {addCommasToNumber(invoice?.totalAmount + invoice?.totalTax)}
              </Text>
            ) : (
              <Text>
                {convertCurrencyToSign(invoice?.currency)}
                {addCommasToNumber(getInvoiceTotalAmount(invoice?.services))}
              </Text>
            )}
          </VeryFlexedDiv>
        )}

        <div style={{ margin: mobile ? '20px 10px' : '64px 40px 0' }}>
          {extraComponent}
        </div>
      </div>

      <div style={{ margin: mobile ? '5px' : '20px 40px' }}>
        <InvoiceDetailsContent
          ref={componentRef}
          bgcolor={
            invoice?.headerColor ?? orgDetails()?.billingHeaderColor ?? ''
          }
        >
          <Card className="card">
            <div className="card__header">
              <Heading size={mobile ? 18 : ''} color="#f2f6ff">
                {subject ? subject : invoice?.invoiceNo}
              </Heading>
              <Text color="#B5CCFE" size={mobile ? 12 : 18}>
                {dateFormat(date || new Date())}
              </Text>
            </div>

            <div className="card__body">
              <div className="flex">
                <div>
                  <Text
                    size={mobile ? 12 : ''}
                    color="var(--grey2)"
                    weight="700"
                  >
                    Invoice Number
                  </Text>
                  <Text size={mobile ? 12 : ''} weight="700">
                    {invoice?.invoiceNo ? invoice?.invoiceNo : 'Preview'}
                  </Text>
                </div>
                <div className="right">
                  <Text
                    size={mobile ? 10 : ''}
                    color="var(--grey1)"
                    weight="700"
                  >
                    Bill To
                  </Text>
                  <Text
                    size={mobile ? 14 : ''}
                    color="var(--grey1)"
                    weight="700"
                  >
                    {' '}
                    {handleGetBillTo()}
                  </Text>
                </div>
              </div>

              <div className="grid">
                <div className="grid__header">
                  <Text style={{ fontSize: '14px' }}>Service</Text>
                  <Text style={{ fontSize: '14px' }}>Description</Text>
                  <Text style={{ fontSize: '14px' }}>Units</Text>
                  <Text style={{ fontSize: '14px' }}>Unit Price</Text>
                  <Text style={{ fontSize: '14px' }}>Unit Tax</Text>
                </div>
                <div>
                  {services?.map(
                    (
                      { serviceName, description, itemUnit, cost, tax },
                      key
                    ) => (
                      <div className="grid__body" key={key}>
                        <Text weight="400">{serviceName}</Text>
                        <Text>{description}</Text>
                        <Text>{itemUnit}</Text>
                        <Text>
                          {convertCurrencyToSign(currency)}
                          {addCommasToNumber(setDecimalPlaces(cost))}
                        </Text>
                        <Text>
                          {convertCurrencyToSign(currency)}
                          {addCommasToNumber(setDecimalPlaces(tax))}
                        </Text>
                      </div>
                    )
                  )}
                </div>
                <InvoiceRecordSummary
                  summaryDisplayCondition={Object.keys(invoice)?.length !== 0}
                  totalAmount={getInvoiceTotalAmount(services)}
                  subTotal={getInvoiceTotalCost(services)}
                  vat={getInvoiceTotalTax(services)}
                  currency={currency}
                  comment={additionalInformation}
                  role={role}
                  preview={true}
                />
              </div>
            </div>
            <div className="card__footer">
              <AccountDetails>
                <div className="sub">
                  <Text style={{ minWidth: '90px' }}>Account Name:</Text>
                  <Text>{_.isEmpty(accountName) ? 'N/A' : accountName}</Text>
                </div>
                <div className="sub">
                  <Text style={{ minWidth: '90px' }}>Account No:</Text>
                  <Text>
                    {_.isEmpty(accountNumber) ? 'N/A' : accountNumber}
                  </Text>
                </div>
                <div className="sub">
                  <Text style={{ minWidth: '90px' }}>Bank Name:</Text>
                  <Text>{_.isEmpty(bankName) ? 'N/A' : bankName}</Text>
                </div>
                <div className="sub">
                  <Text style={{ minWidth: '90px' }}>Sort Code:</Text>
                  <Text>{_.isEmpty(sortCode) ? 'N/A' : sortCode}</Text>
                </div>
              </AccountDetails>

              <AdditionalNoteArea>
                <Text
                  margin="5px 0"
                  size={mobile ? 10 : ''}
                  color="var(--grey1)"
                  weight="700"
                >
                  Additional notes
                </Text>
                <AdditionalInfo style={{ fontSize: '15px' }}>
                  {additionalInformation}
                </AdditionalInfo>
              </AdditionalNoteArea>
              <div className="footer">
                Powered by Easei
                <img
                  src="https://easeitestac8f.blob.core.windows.net/uploadblob-test/easeiInvoiceLogo.png"
                  alt=""
                  width={mobile ? 11 : 18}
                  height={mobile ? 11 : 18}
                />
              </div>
            </div>
          </Card>
        </InvoiceDetailsContent>
      </div>
    </div>
  );
};

export default BaseInvoiceDetails;
