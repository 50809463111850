import Button from 'app/components/atoms/button/button.component';
import Text, { Span } from 'app/components/atoms/typography';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from 'utils/constants/constants.utils';

const Cookies = () => {
  const [isClosed, setIsClosed] = useState(() =>
    window.localStorage.getItem('cookieModal') === 'closed' ? true : false
  );
  useEffect(() => {
    if (isClosed) {
      window?.localStorage?.setItem('cookieModal', 'closed');
    }
  }, [isClosed]);
  const CookieContainer = styled.div`
    background-color: #dbe7ff;
    display: ${isClosed ? 'none' : 'block'};
    position: fixed;
    bottom: 0;
    z-index: 1000;
    padding: 0.6875rem 1.25rem;
    width: 100%;
    .content {
      font-size: 10px;
      display: flex;
      align-items: flex-end;
      gap: 40px;
      flex-wrap: wrap;
      & > div.left {
        flex-grow: 1;
        .headding {
          display: flex;
          justify-content: space-between;
          a {
            color: #c99a0a;
          }
        }
      }
    }
    @media screen and (min-width: 768px) {
      padding: 3.4375rem 2.5rem;
      .content {
        flex-wrap: nowrap;
      }
    }
  `;

  return (
    <CookieContainer>
      <section className="content">
        <div className="left">
          <Text size="20px" weight="bold" className="headding">
            <Span>We use cookies</Span>
            <Link color="#C99A0A" to={ROUTES.PRIVACY}>
              Privacy Policy
            </Link>
          </Text>
          <Text size="20px">
            Essential cookies are required to provide you with basic functions
            while using the website. Essential cookies are required to provide
            you with basic functions while using the website.
          </Text>
        </div>
        <div className="button">
          {' '}
          <Button onClick={() => setIsClosed(true)}>Close</Button>
        </div>
      </section>
    </CookieContainer>
  );
};

export default Cookies;
