import Text, { Span } from '../typography';

import styled from 'styled-components';
import { ErrorMessage, useField } from 'formik';
import { ErrorText } from '../form-input-two/form-input-two.component';
import { useViewport } from 'hooks';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants/constants.utils';

const AgreementCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  p {
    font-size: 14px;
  }
`;

const AgreementCheckbox = ({ setClicked, fields }) => {
  const [field] = useField(fields);
  const { mobile } = useViewport();

  return (
    <>
      <AgreementCheckboxContainer>
        <input
          type="checkbox"
          id={fields?.name}
          {...field}
          onClick={setClicked}
        ></input>
        <Text as={'label'} htmlFor={fields?.name} $size={mobile ? 10 : 20}>
          <Span display={'inline-block'}>I agree to the </Span>{' '}
          <Link to={ROUTES.TERMS} className="link">
            Terms of Service
          </Link>{' '}
          <Span display={'inline-block'}>and</Span>{' '}
          <Link to={ROUTES.PRIVACY} className="link">
            Privacy Policy.
          </Link>
        </Text>
      </AgreementCheckboxContainer>
      <ErrorMessage name={field?.name}>
        {(msg) => <ErrorText id="text">{msg}</ErrorText>}
      </ErrorMessage>
    </>
  );
};

export default AgreementCheckbox;
