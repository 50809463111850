import ContentContainer, {
  ContentCardsContainer,
  Header,
} from 'app/components/atoms/about-content-layout/ContentContainer.component';
import { CardContainer } from 'app/components/atoms/card/card.component';
import List from 'app/components/atoms/list/list.component';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import './paymentPolicy.styles.css';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants/constants.utils';
const PaymentPolicy = () => {
  return (
    <ContentContainer>
      <div className="container">
        <Header>
          <Heading weight="400" size="56">
            Payment{' '}
            <Span color="#fe9900" display="inline">
              {' '}
              Policy _
            </Span>
          </Heading>
          <Text>Last updated on January 09, 2013</Text>
        </Header>
        <ContentCardsContainer>
          <CardContainer>
            <Heading level={2}>Payment Processing</Heading>
            <Text>
              Easei is a product owned by Blazor Technologies LLC. We’re
              committed to protecting and respecting your privacy. If you have
              any questions about the storage or use of your personal
              information please{' '}
              <Link to={ROUTES.CONTACT_US} className="link-policy">
                contact us
              </Link>
              .
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>User information collection</Heading>
            <div className="info">
              <Heading level={3}>Invoicing and User Information</Heading>
              <Text>
                In order to render our services to you, we collect some of your
                data, including invoicing and user information. All the data we
                collect from you is private to you and the entities you
                associate within the application. Your information is stored on
                secure servers, and we only access it when you permit us to. We
                have employed the use of firewalls, data protection rules, and
                continuous monitoring to ensure we never compromise your data.
              </Text>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>How we use the information</Heading>
            <div>
              <Heading level={3}>
                To provide our services, we use your personal data to;
              </Heading>
              <List el={'ol'}>
                <Text>Setup your account</Text>
                <Text>Verify your unique identity</Text>
                <Text>Render our services</Text>
                <Text>
                  Communicate new features and recent changes in the app
                </Text>
                <Text>
                  Maintain a smooth relationship with you, by acknowledging your
                  queries and feedback.
                </Text>
              </List>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Consent</Heading>
            <Text>
              We recognize the importance of your data, as such, we take the
              security of every information provided to us by you and our
              third-party service providers, very seriously. We store personal
              information in our secured server managed by us and our service
              providers. The security measures include firewalls, hashing, data
              encryption, and information access authorization controls.
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Contact Us</Heading>
            <Text>
              You may contact us if you have general questions about our policy,
              terms and practices or questions about your profile or personal
              information. Please contact us{' '}
              <Link to={ROUTES.CONTACT_US} className="link-policy">
                here
              </Link>
              .
            </Text>
          </CardContainer>
        </ContentCardsContainer>
      </div>
    </ContentContainer>
  );
};

export default PaymentPolicy;
