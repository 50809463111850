export const randomColorGenerator = () => {
  const colors = [
    '#F1F4FA',
    '#FFF5E5',
    '#E5E9F1',
    '#B8C6E4',
    '#00C8F033',
    '#F2F6FF',
    '#5CBC7733',
    '#F6F6F6',
    '#E5E9F1',
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};
