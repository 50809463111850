import { FlexedDiv } from 'app/components/atoms/divs/div.styles';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { invoiceSelectors } from 'store/reducers/invoice/invoiceSlice';
import styled from 'styled-components';
const Button = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  color: #0148de;
  cursor: pointer;
`;
const AddInvoiceNumber = ({ onClick }) => {
  const invoices = useSelector(invoiceSelectors.invoices);
  const [selected, setSelected] = useState('');
  const handleChange = () => {
    onClick(JSON.parse(selected));
  };

  return (
    <FlexedDiv justifyContent="start" style={{ gap: '20px', padding: '10px' }}>
      <Form.Select
        onChange={(e) => setSelected(e.target.value)}
        size="lg"
        defaultValue={''}
        style={{ padding: '10px' }}
      >
        <option aria-disabled="true">Invoice Number</option>

        {invoices?.map((item) => (
          <option key={item?.invoiceNo} value={JSON.stringify(item)}>
            {item?.invoiceNo}
          </option>
        ))}
      </Form.Select>
      <Button onClick={handleChange} disabled={!selected}>
        <IoIosAddCircleOutline size={20} />
        Add Invoice
      </Button>
    </FlexedDiv>
  );
};

export default AddInvoiceNumber;
