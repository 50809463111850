import React, { useEffect, useState } from 'react';

import Text, { Heading } from 'app/components/atoms/typography';
import NoDataPngMobile from 'app/assets/img/admin-dashboard-mobile-no data.png';
import { OrgUserDashboardWrapper } from './dashboard.styles';
import Carousel from 'app/components/MetricCarousel';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminDashboardActions,
  adminDashboardSelectors,
} from 'store/reducers/dashboard/adminDashboardSlice';
import { TableHeader } from '../reminder/clientsTable';
import { LabelContainer, NoData } from './org-dashboard.component';
import ChartLabel from 'app/components/chart/ChartLabel';
import BarChart from 'app/components/chart';
import CircularProgressBar from 'app/components/progress-bar/CircularProgressBar';
import { useViewport } from 'hooks';
import AdminInvoiceListMobile from './admin-dashboard-mobile';
import { GraphContainer, TableItem } from './admin-dashboard-styles';
import { MOBILE_WINDOW_SIZE } from 'utils/constants/constants.utils';
import InvoiceList from 'app/components/cell/invoiceList/invoiceList';
import { CardStyle } from 'app/components/atoms/card/card.component';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import {
  getBooleanStatusColor,
  getBooleanStatusName,
} from 'utils/helpers/createStatusStyle/createStatusStyle';

const ClientDashboard = ({ timeInput }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminDashboard } = adminDashboardActions;
  const { mobile } = useViewport();

  const invoiceMetrics = useSelector(adminDashboardSelectors.invoiceMetrics);
  const isLoading = useSelector(adminDashboardSelectors.isLoading);
  const graphData = useSelector(adminDashboardSelectors.graphData);
  const clientUserInfo = useSelector(adminDashboardSelectors.clientUsersInfo);

  const topOrBottomFiveClients = useSelector(
    adminDashboardSelectors.topOrBottomFiveClients
  );
  const topOrBottomFiveOrganizations = useSelector(
    adminDashboardSelectors.topOrBottomFiveOrganizations
  );
  const organizationMetrics = useSelector(
    adminDashboardSelectors.organizationMetrics
  );

  const [listView, setListView] = useState('org');
  const [activeHeader, setActiveHeader] = useState('invoice');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isCircularGraphHovered, setIsCircularGraphHovered] = useState(false);
  const [CircularGraphhoverPosition, setCircularGraphhoverPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleCircularGraphHover = (event) => {
    setIsCircularGraphHovered(!isCircularGraphHovered);
    setCircularGraphhoverPosition({ x: event.clientX, y: event.clientY });
  };

  const handleGraphPageChange = (tab) => {
    setActiveHeader(tab);
  };
  const basicMenu = (row) => (
    <BsThreeDotsVertical
      onClick={() => {
        navigate(`/organization/${row.id}`);
      }}
    />
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(adminDashboard(timeInput));

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInput]);

  let columns = [
    {
      name: 'No',
      selector: (_, index) => index + 1,
      sortable: false,
      center: true,
    },
    {
      name: listView === 'org' ? 'Organization Name' : 'Full Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: (row) => row?.phoneNumber,
      sortable: true,
    },
    {
      name: 'User Count',
      selector: (row) => row?.clientCount,
      sortable: true,
      center: true,
    },
    {
      name: 'Invoice Count',
      selector: (row) => row?.invoiceCount,
      center: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      format: (row) => (
        <p
          style={{
            color: getBooleanStatusColor(row.status),
            border: `.5px solid ${getBooleanStatusColor(row.status)}`,
            paddingInline: '0.625rem',
            borderRadius: '0.375rem',
          }}
        >
          {getBooleanStatusName(row.status)}
        </p>
      ),
    },
    {
      name: 'Date Registered',
      selector: (row) => row.dateRegistered,
      format: (row) => new Date(row.dateRegistered).toLocaleDateString(),
      sortable: true,
    },
  ];

  if (listView === 'client') {
    columns = columns.filter((column) => column.name !== 'User Count');
  } else {
    columns.push({
      name: '',
      selector: (row) => basicMenu(row),
      button: true,
    });
  }
  return (
    <OrgUserDashboardWrapper>
      <Carousel invoiceMetrics={organizationMetrics} admin={true} />
      <Carousel invoiceMetrics={invoiceMetrics} />
      <div style={{ marginTop: '24px' }}>
        {windowWidth <= MOBILE_WINDOW_SIZE ? (
          <AdminInvoiceListMobile
            loading={isLoading}
            topOrBottomFiveOrganizations={topOrBottomFiveOrganizations}
            topOrBottomFiveClients={topOrBottomFiveClients}
            listView={listView}
            setListView={setListView}
          />
        ) : (
          <InvoiceList
            invoices={
              listView === 'org'
                ? topOrBottomFiveOrganizations
                : topOrBottomFiveClients
            }
            loading={isLoading}
            columnsOrg={columns}
            selectableRows={false}
            pagination={false}
            tableHeader={
              <TableHeader>
                <div className="admin">
                  <TableItem
                    size={mobile ? 12 : 24}
                    active={listView === 'org'}
                    onClick={() => {
                      setListView('org');
                    }}
                  >
                    Top Organizations ({topOrBottomFiveOrganizations.length})
                  </TableItem>
                  <TableItem
                    size={mobile ? 12 : 24}
                    active={listView === 'client'}
                    onClick={() => {
                      setListView('client');
                    }}
                  >
                    Top Clients ({topOrBottomFiveClients.length})
                  </TableItem>
                </div>
              </TableHeader>
            }
            noDataComponent={
              <NoData>
                <img src={NoDataPngMobile} alt="" />
                <Text
                  size={12}
                  color="var(--grey2)"
                  style={{ marginTop: '16px' }}
                >
                  We have no customer Data, marketing team wake up!!!
                </Text>
              </NoData>
            }
          />
        )}
      </div>
      {mobile ? (
        <GraphContainer>
          <CardStyle padding="15px">
            <CardStyle padding="5px">
              <div style={{ display: 'flex', gap: '33px' }}>
                <TableItem
                  padding="5px"
                  size="12px"
                  active={activeHeader === 'invoice'}
                  onClick={() => handleGraphPageChange('invoice')}
                >
                  Invoice Distribution
                </TableItem>
                <TableItem
                  padding="5px"
                  size="12px"
                  active={activeHeader === 'distributionRate'}
                  onClick={() => handleGraphPageChange('distributionRate')}
                >
                  Distribution Rate
                </TableItem>
              </div>
            </CardStyle>
            <CardStyle margin="23px 0 0">
              {activeHeader === 'invoice' && (
                <div>
                  <div>
                    <div>
                      <TableHeader padding="0" className="stats">
                        <div
                          style={{
                            borderBottom: '1px solid #F2F6FF',
                            width: 'fit-content',
                          }}
                        >
                          <Heading color=" var(--primary2)" level={2}>
                            Invoice distribution
                          </Heading>
                          <Text color="var(--grey2)">
                            Track your invoice flow for any given period
                          </Text>
                        </div>
                      </TableHeader>

                      <LabelContainer>
                        <ChartLabel
                          color={'#6C98F3'}
                          invoice="Internal Invoices"
                        />
                        <ChartLabel
                          color={'#FE9900'}
                          invoice="External Invoices"
                        />
                      </LabelContainer>
                    </div>
                    <BarChart timeData={graphData} admin={true} />
                  </div>
                </div>
              )}

              {activeHeader === 'distributionRate' && (
                <div style={{ position: 'relative', height: '400px' }}>
                  <Text
                    size={24}
                    weight={700}
                    color="#002776"
                    text="Freelancers vs Contractors"
                  />
                  <CircularProgressBar
                    count={clientUserInfo?.freelancers}
                    width={200}
                    radius={90}
                    color="#FE9900"
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%,-50%)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text size="20px" weight="700" style={{ margin: 0 }}>
                      {clientUserInfo?.freelancers +
                        clientUserInfo?.contractors || 0}
                    </Text>
                    <Text weight="700" style={{ margin: 0 }}>
                      Total
                    </Text>
                  </div>

                  <div>
                    <CircularProgressBar
                      count={clientUserInfo?.contractors}
                      width={180}
                      radius={72}
                      color="#00C8F0"
                      style={{ pointerEvents: 'none' }}
                      onMouseEnter={handleCircularGraphHover}
                    />
                    {isCircularGraphHovered && (
                      <div
                        style={{
                          position: 'fixed',
                          top: CircularGraphhoverPosition.y + 10,
                          left: CircularGraphhoverPosition.x + 10,
                          backgroundColor: '#000',
                          color: '#fff',
                          padding: '5px',
                          borderRadius: '5px',
                          display: 'inline-block',
                        }}
                      >
                        Contractors: {clientUserInfo?.contractors}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                    }}
                  >
                    <div className="label-circle">
                      <ChartLabel color={'#6C98F3'} invoice="Contractors" />
                      <ChartLabel color={'#FE9900'} invoice="Freelancers" />
                    </div>
                  </div>
                </div>
              )}
            </CardStyle>
          </CardStyle>
        </GraphContainer>
      ) : (
        <GraphContainer>
          <CardStyle height="483px" style={{ flex: 1.5 }}>
            <div>
              <div>
                <TableHeader padding="0" className="stats">
                  <div
                    style={{
                      borderBottom: '1px solid #F2F6FF',
                      width: 'fit-content',
                    }}
                  >
                    <Heading color=" var(--primary2)" level={2}>
                      Invoice distribution
                    </Heading>
                    <Text color="var(--grey2)">
                      Track your invoice flow for any given period
                    </Text>
                  </div>
                </TableHeader>

                <LabelContainer>
                  <ChartLabel color={'#6C98F3'} invoice="Internal Invoices" />
                  <ChartLabel color={'#FE9900'} invoice="External Invoices" />
                </LabelContainer>
              </div>
              <BarChart timeData={graphData} admin={true} />
            </div>
          </CardStyle>

          <CardStyle
            height="483px"
            className="cheat-CardStyle"
            style={{ position: 'relative' }}
          >
            <Text
              size={24}
              weight={700}
              color="#002776"
              text="Freelancers vs Contractors"
            />
            <CircularProgressBar
              count={clientUserInfo?.freelancers}
              width={200}
              radius={90}
              color="#FE9900"
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Text size="20px" weight="700" style={{ margin: 0 }}>
                {clientUserInfo?.freelancers + clientUserInfo?.contractors || 0}
              </Text>
              <Text weight="700" style={{ margin: 0 }}>
                Total
              </Text>
            </div>

            <div>
              <CircularProgressBar
                count={clientUserInfo?.contractors}
                width={180}
                radius={72}
                color="#00C8F0"
                style={{ pointerEvents: 'none' }}
                onMouseEnter={handleCircularGraphHover}
              />
              {isCircularGraphHovered && (
                <div
                  style={{
                    position: 'fixed',
                    top: CircularGraphhoverPosition.y + 10,
                    left: CircularGraphhoverPosition.x + 10,
                    backgroundColor: '#000',
                    color: '#fff',
                    padding: '5px',
                    borderRadius: '5px',
                    display: 'inline-block',
                  }}
                >
                  Contractors: {clientUserInfo?.contractors}
                </div>
              )}
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '10px',
              }}
            >
              <div className="label-circle">
                <ChartLabel color={'#00C8F0'} invoice="Contractors" />
                <ChartLabel color={'#FE9900'} invoice="Freelancers" />
              </div>
            </div>
          </CardStyle>
        </GraphContainer>
      )}
    </OrgUserDashboardWrapper>
  );
};

export default ClientDashboard;
