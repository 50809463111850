import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';

const entity = 'charge';

export const initialState = {
  charges: [],
  chargeTypes: [],
  loading: false,
};

const getCharges = createAsyncThunk(
  `${entity}`,

  async (inputs) => {
    const res = await api.charge.getAll(inputs);
    return res.data;
  }
);
const getUnitTypes = createAsyncThunk(
  `${entity}/unitTypes`,

  async () => {
    const res = await api.charge.getUnitTypes();
    return res;
  }
);
const createCharge = createAsyncThunk(
  `${entity}/create`,

  async (input) => {
    const res = await api.charge.createCharge(input);
    return res.data;
  }
);

const editCharge = createAsyncThunk(
  `${entity}/edit`,

  async (input) => {
    const res = await api.charge.editCharge(input);
    return res.data;
  }
);

const chargeSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCharges.fulfilled, (state, action) => {
        state.charges = action.payload.data;
        state.loading = false;
      })
      .addCase(getCharges.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCharges.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getUnitTypes.fulfilled, (state, action) => {
        state.chargeTypes = action.payload.data;
        state.loading = false;
      })
      .addCase(getUnitTypes.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getUnitTypes.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(createCharge.fulfilled, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createCharge.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createCharge.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(editCharge.fulfilled, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(editCharge.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(editCharge.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export const ChargeActions = {
  ...chargeSlice.actions,
  getCharges,
  getUnitTypes,
  createCharge,
  editCharge,
};

export const ChargeSelectors = {
  isLoading: (state) => state.charge.loading,
  charges: (state) => state.charge.charges,
  chargeTypes: (state) => state.charge.chargeTypes,
};

export default chargeSlice.reducer;
