import Button from 'app/components/atoms/button/button.component';
import { useDispatch, useSelector } from 'react-redux';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormInput, {
  ErrorText,
} from 'app/components/atoms/form-input/form-input.component';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import { FormContent, UplodedImg } from 'app/pages/settings/settings.styles';
import {
  SettingsActions,
  SettingsSelectors,
} from 'store/reducers/settings/settingsSlice';
import { validateFileType } from 'utils/helpers/validateFileType/validateFileType';
import CustomColor, {
  ColorPreview,
} from 'app/components/atoms/custom-color/custom-color';
import CustomModal from 'app/components/atoms/modal/modal.component';
import styled from 'styled-components';
import { InvertedButton } from 'app/components/atoms/button/button.styles';
import FormSelect from 'app/components/atoms/form-select/form-select.component';
import FormSwitch from 'app/components/atoms/form-switch/form-switch.component';

const CustomModalContent = styled.div`
  min-height: 80vh;
  display: grid;
  place-content: center start;
  width: 100%;
  text-align: center;
`;
const CustomLogoContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const validation = Yup.object({
  accountName: Yup.string().when('onlinePaymentEnabled', {
    is: true,
    then: Yup.string().required('Field is required!'),
  }),
  bankName: Yup.string().when('onlinePaymentEnabled', {
    is: true,
    then: Yup.string().required('Field is required!'),
  }),
  accountNumber: Yup.string().when('onlinePaymentEnabled', {
    is: true,
    then: Yup.string().required('Field is required!'),
  }),
  onlinePaymentEnabled: Yup.boolean(),
  showAccountDetails: Yup.boolean(),
});
const LogoBillHeader = ({ organization, isLoading }) => {
  const [selectedColor, setSelectedColor] = useState('');
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedAccount, setCheckedAccount] = useState(false);
  const [show, setShow] = useState(false);
  const { handleApiResponse } = useHandleApiResponse();
  const dispatch = useDispatch();
  const [file, setFile] = useState('');
  const [fileError, setFileError] = useState('');
  const bankList = useSelector(SettingsSelectors.bankList);

  const {
    getBankList,
    uploadFile,
    updateOrganizationDetails,
    getOrganizationDetails,
  } = SettingsActions;
  const ref = useRef();
  const defaultFormFields = {
    billingHeaderColor: organization.billingHeaderColor || '',
    logo: organization.logo || '',
    accountName: organization.accountName || '',
    bankName: organization.bankName || '',
    accountNumber: organization.accountNumber || '',
    onlinePaymentEnabled: organization.onlinePaymentEnabled || false,
    showAccountDetails: organization.showAccountDetails || false,
    bankCode: organization.bankCode || '',
  };
  const PostData = (fileUrl, setSubmitting, resetForm, values) => {
    dispatch(
      updateOrganizationDetails({
        ...organization,
        ...values,
        logo: fileUrl || organization.logo,
        billingHeaderColor: selectedColor || organization.billingHeaderColor,
      })
    ).then((res) => {
      handleApiResponse(res, null, true);
      dispatch(getOrganizationDetails());
      resetForm();
      if (ref.current.value) {
        ref.current.value = '';
      }
      setFile('');
      setFileError('');
      setSubmitting(false);
      setShow(false);
    });
  };
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const handleSubmit = async (setSubmitting, resetForm, values) => {
    setSubmitting(true);

    if (file === '' || file === undefined || file === null) {
      PostData(null, setSubmitting, resetForm, values);
    } else {
      if (validateFileType(file.type)) {
        dispatch(uploadFile(file)).then((fileUrl) => {
          PostData(fileUrl.payload.data, setSubmitting, resetForm, values);
        });
      } else {
        setSubmitting(false);
        setFileError(
          'Invalid File Type, accepted formats are: Jpeg, Jpg and png'
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getBankList());
  }, [dispatch, getBankList]);

  return (
    <div>
      <Text color="var(--grey2)" size="18px" padding="1.5rem 0 0 0">
        Account Settings
      </Text>
      <Formik
        initialValues={defaultFormFields}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(setSubmitting, resetForm, values);
        }}
        validationSchema={validation}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form className="form">
              <FormContent margin="20px 0">
                <Text>Organization Logo</Text>
                {organization?.logo && !show ? (
                  <CustomLogoContent>
                    <UplodedImg>
                      <img
                        src={organization?.logo}
                        alt={organization?.businessName}
                        className="img"
                      />
                    </UplodedImg>{' '}
                    <Span
                      color="var(--primary)"
                      onClick={() => setShow(!show)}
                      style={{ cursor: 'pointer' }}
                    >
                      Change Logo
                    </Span>
                  </CustomLogoContent>
                ) : (
                  <div className="file">
                    <input
                      type="file"
                      ref={ref}
                      name="file"
                      accept="image/png, image/jpg, image/jpeg"
                      placeholder="Choose Files"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                    {organization?.logo && show && (
                      <Span
                        color="var(--red)"
                        onClick={() => setShow(!show)}
                        style={{ cursor: 'pointer' }}
                      >
                        Cancel
                      </Span>
                    )}
                    {fileError && <ErrorText id="text">{fileError}</ErrorText>}
                  </div>
                )}
              </FormContent>
              <FormContent margin="20px 0">
                <Text>Adjust Logo</Text>
                <Text>
                  <Span display="inline">
                    Need to Adjust(edit/format) your logo?{' '}
                  </Span>
                  <a
                    href="https://www.canva.com/photo-editor/"
                    target="_blank"
                    className="link-policy"
                    rel="noreferrer"
                  >
                    Click here
                  </a>
                </Text>
              </FormContent>
              <FormContent margin="20px 0">
                <Text>Header Color </Text>

                <div className="file">
                  {!selectedColor && !organization.billingHeaderColor ? (
                    <>
                      <div
                        className="custom-file-upload"
                        onClick={() => setVisible(!visible)}
                      >
                        <Span htmlFor="file-upload">Choose Color</Span>
                      </div>
                      <Span>No color choosen</Span>
                    </>
                  ) : (
                    <>
                      <ColorPreview
                        size="2.625rem"
                        color={selectedColor || organization.billingHeaderColor}
                      />
                      <Span
                        color="var(--primary)"
                        onClick={() => setVisible(!visible)}
                        style={{ cursor: 'pointer' }}
                      >
                        Change Color
                      </Span>
                    </>
                  )}
                </div>
              </FormContent>
              <FormContent margin="20px 0" className="name">
                <Text>
                  Account Name{' '}
                  {values['onlinePaymentEnabled'] && (
                    <Span color="red" display="inline">
                      *
                    </Span>
                  )}
                </Text>

                <FormInput
                  type="text"
                  name="accountName"
                  placeholder="Account name"
                  style={{
                    backgroundColor: 'white',
                    padding: '25px 20px',
                  }}
                  value={values['accountName']}
                  onChange={(value) =>
                    setFieldValue('accountName', value.value)
                  }
                />
              </FormContent>
              <FormContent margin="20px 0">
                <Text>
                  Account Number{' '}
                  {values['onlinePaymentEnabled'] && (
                    <Span color="red" display="inline">
                      *
                    </Span>
                  )}
                </Text>
                <FormInput
                  type="text"
                  name="accountNumber"
                  placeholder="Account number"
                  style={{
                    backgroundColor: 'white',
                    padding: '25px 20px',
                  }}
                  value={values['accountNumber']}
                  onChange={(value) =>
                    setFieldValue('accountNumber', value.value)
                  }
                />
              </FormContent>
              <FormContent margin="20px 0">
                <Text>
                  Bank Name{' '}
                  {values['onlinePaymentEnabled'] && (
                    <Span color="red" display="inline">
                      *
                    </Span>
                  )}
                </Text>

                <FormSelect
                  options={bankList}
                  value={values['bankName']}
                  onChange={(value) => {
                    setFieldValue('bankName', value.label);
                    setFieldValue('bankCode', value.bankCode);
                  }}
                  name="bankName"
                  required={values['onlinePaymentEnabled']}
                />
              </FormContent>
              <FormContent margin="20px 0">
                <Text>Show Account Number on Bills</Text>
                <FormSwitch
                  checked={checked || values['showAccountDetails']}
                  name="showAccountDetails"
                  onChange={(e) => setCheckedAccount(e.target.checked)}
                />
              </FormContent>
              <FormContent margin="20px 0">
                <Text>Receive Payment Online</Text>
                <FormSwitch
                  checked={checkedAccount || values['onlinePaymentEnabled']}
                  name="onlinePaymentEnabled"
                  onChange={handleChange}
                />
              </FormContent>
              <FormContent margin="20px 0" className="cheat">
                <div></div>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading || Object.keys(errors).length > 0}
                >
                  Save Changes
                </Button>
              </FormContent>
            </Form>
          );
        }}
      </Formik>

      <CustomModal
        isOpen={visible}
        close={setVisible}
        className="modal modal-del overflow"
      >
        <CustomModalContent>
          <Heading level={2} color="var(--primary2)" size="36px">
            Header Color
          </Heading>
          <Text margin="0 0 16px 0">
            Choose your preferred header color and view the preview.
          </Text>
          <CustomColor
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              marginTop: '25px',
              justifyContent: 'space-between',
              padding: '10px 0',
              gap: '24px',
            }}
          >
            <InvertedButton
              type="button"
              onClick={() => {
                setSelectedColor('');
                setVisible(!visible);
              }}
            >
              Cancel
            </InvertedButton>
            <Button
              onClick={() => {
                setVisible(!visible);
              }}
            >
              Set Color
            </Button>
          </div>
        </CustomModalContent>
      </CustomModal>
    </div>
  );
};

export default LogoBillHeader;
