import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AiOutlineCheck } from 'react-icons/ai';
import DollarIcon from '../../../assets/img/dollarIcon.png';
import Text, { Heading } from 'app/components/atoms/typography';
import _ from 'lodash';
import { OutcomeCircle } from 'app/components/atoms/divs/div.styles';
import {
  INVOICE_STATUS,
  INVOICE_PAYMENT_STATUS,
} from 'utils/constants/constants.utils';
import { Flex, FlexInvoiceActions } from 'app/pages/dashboard/dashboard.styles';
import { useViewport } from 'hooks';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentSelectors } from 'store/reducers/payment/paymentSlice';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import {
  ResponsiveBaseButton,
  ResponsiveInvertedButton,
} from 'app/pages/receipt/receipt.style';
import { appDataActions } from 'store/reducers/appData/appDataSlice';
import { FiDownload } from 'react-icons/fi';
import ActionButton from 'app/components/molecules/action-btn/action-btn.component';
import LoadingDots from 'app/components/atoms/loading-dots/loading-dots.component';
import CustomModal from 'app/components/atoms/modal/modal.component';
import { billActions, billSelectors } from 'store/reducers/bills/billsSlice';
import BaseBillDetails from './BaseBillDetails';

const ViewBIll = () => {
  const [paymentConfirmation, setPaymentConfirmation] = useState(false);
  const [paymentSucesss, setPaymentSucesss] = useState(false);
  const [update, setUpdate] = useState(false);

  const { setHeaderText } = appDataActions;
  const { getDetails, updateStatus: updateBillStatus } = billActions;
  const { handleDownload } = useHandleApiResponse();

  const bill = useSelector(billSelectors.bill);
  const loading = useSelector(billSelectors.isLoading);

  const isPaymentLoading = useSelector(paymentSelectors.isLoading);

  const dispatch = useDispatch();

  const { id } = useParams();
  const { mobile } = useViewport();

  useEffect(() => {
    dispatch(getDetails(id)).then((res) => {
      dispatch(
        setHeaderText({ value: res.payload.invoiceNo, type: 'setHeaderText' })
      );
    });

    // eslint-disable-next-line
  }, []);

  //#region handle events

  const handleGetActions = (invoiceStatus, invoicePaymentStatus) => {
    if (
      invoiceStatus === INVOICE_STATUS.APPROVED &&
      invoicePaymentStatus === INVOICE_PAYMENT_STATUS.UNPAID
    ) {
      return organizationUserPaymentAction;
    }
    return [];
  };

  const handleDisplaySendPaymentConfirmationModal = () => {
    setPaymentConfirmation(true);
  };

  const handleSendPaymentConfirmation = () => {
    let payload = {
      billId: id,
    };

    dispatch(updateBillStatus(payload)).then(() => {
      setPaymentSucesss(true);
      setUpdate(!update);
      dispatch(getDetails(id));
    });

    setPaymentConfirmation(false);
  };
  //#endregion

  //#region Actions
  const organizationUserPaymentAction = [
    {
      name: 'Confirm Payment',
      icon: <img src={DollarIcon} alt="Payment Confirmation" width="24px" />,
      iconColor: '#FE9900',
      action: handleDisplaySendPaymentConfirmationModal,
    },
  ];

  const actions = handleGetActions(
    bill?.statusName,
    bill?.invoicePaymentStatusName
  );

  //#endregion

  if (loading || _.isEmpty(bill)) {
    return <LoadingDots text={'Hold on'} />;
  }
  return (
    <BaseBillDetails
      invoice={bill}
      loading={loading}
      backRoute={-1}
      extraComponent={
        <>
          <FlexInvoiceActions padding="64px 0">
            <Flex style={{ display: actions.length === 0 ? 'none' : 'flex' }}>
              {actions?.map((action, index) => (
                <ActionButton
                  style={{ margin: '5px' }}
                  height={mobile ? 13.33 : 40}
                  key={index}
                  iconColor={action?.iconColor}
                  onClick={() => {
                    action?.action(action.name);
                  }}
                >
                  {action?.icon}
                  <Text size={mobile ? 12 : ''}>{action?.name}</Text>
                </ActionButton>
              ))}
            </Flex>

            <ActionButton
              border="0.50px solid #DBE7FF "
              height={mobile ? '20px' : '40px'}
              iconColor={'var(--accent)'}
              onClick={() => {
                handleDownload(bill?.invoicePdfUrl, bill?.invoiceNo);
              }}
            >
              <FiDownload />
              <Text size={mobile ? 12 : ''}>Download as PDF</Text>
            </ActionButton>
          </FlexInvoiceActions>

          {paymentConfirmation && (
            <CustomModal
              isOpen={paymentConfirmation}
              className="modal modal-del overflow"
              close={setPaymentConfirmation}
            >
              <Flex justifyContent="center" flexDirection="column">
                <Heading responsiveFontSize={18} level={2} margin="24px 0 0 0">
                  Payment Confirmation
                </Heading>
                <Text size={mobile ? 12 : ''}>
                  Confirm payment of $4500 for Ref-121010?
                </Text>
              </Flex>
              <Flex margin="100px 0 0 0" justifyContent="center">
                <ResponsiveInvertedButton
                  marginRight="1rem"
                  onClick={(e) => {
                    e.preventDefault();
                    setPaymentConfirmation(false);
                  }}
                >
                  Cancel
                </ResponsiveInvertedButton>
                <ResponsiveBaseButton
                  disabled={isPaymentLoading}
                  isLoading={isPaymentLoading}
                  type="submit"
                  onClick={handleSendPaymentConfirmation}
                >
                  Confirm
                </ResponsiveBaseButton>
              </Flex>
            </CustomModal>
          )}

          {paymentSucesss && (
            <CustomModal
              isOpen={paymentSucesss}
              className="modal modal-del overflow"
              close={setPaymentSucesss}
            >
              <Flex
                margin="30px"
                justifyContent="center"
                flexDirection="column"
              >
                <OutcomeCircle>
                  <AiOutlineCheck />
                </OutcomeCircle>
                <Heading responsiveFontSize={18} level={2}>
                  Request Sent Successfully
                </Heading>
              </Flex>
            </CustomModal>
          )}
        </>
      }
    />
  );
};

export default ViewBIll;
