import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Flex } from '../dashboard/dashboard.styles';
import Text, { Heading } from 'app/components/atoms/typography';
import { RiArrowLeftSFill } from 'react-icons/ri';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BaseButton } from 'app/components/atoms/button/button.styles';
import { FiDownload } from 'react-icons/fi';
import { convertCurrencyToSign } from 'utils/helpers/convertCurrencyToSign/convertCurrencyToSign';
import { setDecimalPlaces } from 'utils/helpers/setDecimalPlaces/setDecimalPlaces';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import {
  FlexReceiptButton,
  ResponsiveBaseButton,
  ResponsiveInvertedButton,
  PaidWatermark,
  ReceiptFooter,
} from './receipt.style';
import { useDispatch, useSelector } from 'react-redux';
import {
  receiptActions,
  receiptSelectors,
} from 'store/reducers/receipt/receiptSlice';
import { Vortex } from 'react-loader-spinner';
import { appDataActions } from 'store/reducers/appData/appDataSlice';
import { MOBILE_WINDOW_SIZE } from 'utils/constants/constants.utils';
import {
  FlexDiv,
  InvoiceDetailsContent,
  TableTotalContainer,
} from 'app/components/atoms/divs/div.styles';
import Card from 'app/components/atoms/card/card.component';
import { useHandleApiResponse } from 'hooks/useHandleApiResponse';
import { SettingsSelectors } from 'store/reducers/settings/settingsSlice';

const dateFormat = (date) => {
  return new Date(date).toLocaleDateString('en-us', {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  });
};

const ReceiptView = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const receipt = useSelector(receiptSelectors.receipt);
  const loading = useSelector(receiptSelectors.isLoading);
  const organization = useSelector(SettingsSelectors.organization);
  const { state } = useLocation();
  const { getById } = receiptActions;
  const { handleDownload } = useHandleApiResponse();
  const { setHeaderText, resetState } = appDataActions;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef();
  const { id } = useParams();

  const handlePrint = () => {};

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(getById({ id, receiptType: state?.receiptType })).then((res) => {
      dispatch(
        setHeaderText({
          value: res.payload.data.receiptNo,
          type: 'setHeaderText',
        })
      );
    });

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      dispatch(resetState());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {windowWidth > MOBILE_WINDOW_SIZE && (
        <Card>
          <Flex textAlign="left">
            <div>
              <Heading color=" var(--primary2)">{receipt?.receiptNo}</Heading>
              <Text color="#5cbc77">Paid</Text>
            </div>
            <BaseButton width="true" onClick={() => navigate(-1)}>
              <RiArrowLeftSFill color="white" /> <Text color="white">Back</Text>
            </BaseButton>
          </Flex>
        </Card>
      )}

      {windowWidth < MOBILE_WINDOW_SIZE && (
        <Text color="#5cbc77" margin="15px ">
          Paid
        </Text>
      )}

      <div className="dashboard-container">
        <div>
          <FlexReceiptButton>
            <ResponsiveInvertedButton
              width="true"
              marginRight="15px"
              onClick={() => {
                receipt.invoiceId &&
                  navigate(
                    state.receiptType === 1
                      ? `/invoices/${receipt?.invoiceId}`
                      : `/bills/${receipt?.invoiceId}`
                  );
              }}
            >
              {state.receiptType === 1 ? 'View Invoice' : 'View Bill'}
            </ResponsiveInvertedButton>
            <div
              className="invoice-details-download-button"
              onClick={handlePrint}
            >
              <ResponsiveBaseButton
                padding="0 5px"
                mobilePadding="8px 7px"
                onClick={() => {
                  receipt.receiptPdfUrl &&
                    handleDownload(receipt.receiptPdfUrl, receipt.receiptNo);
                }}
              >
                <FiDownload style={{ marginRight: '10px' }} />
                <Text color="white">Download as PDF</Text>
              </ResponsiveBaseButton>
            </div>
          </FlexReceiptButton>

          {loading || _.isEmpty(receipt) ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <Vortex
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperClass="vortex-wrapper"
                colors={['red', 'blue', 'yellow', '#fe9900']}
              />
            </div>
          ) : (
            <InvoiceDetailsContent
              ref={componentRef}
              marginTop="40px"
              bgcolor={organization?.billingHeaderColor ?? '#542AC1'}
            >
              <Card className="card">
                <div className="card__header">
                  {organization?.logo && (
                    <img src={organization?.logo} alt="" />
                  )}
                  <Heading size="48px" color="#f2f6ff" responsiveFontSize={24}>
                    {receipt.subject}
                  </Heading>
                  <Text color="#B5CCFE" size="18px" responsiveFontSize={14}>
                    {dateFormat(receipt.date)}
                  </Text>
                </div>

                <div className="card__body">
                  <div className="flex">
                    <div>
                      <Text responsiveFontSize={14}>Invoice Number</Text>
                      <Text size="18px" weight="500" responsiveFontSize={14}>
                        {receipt.invoiceNo}
                      </Text>
                    </div>
                    <div className="right">
                      <Text responsiveFontSize={14}>Bill to:</Text>
                      <Text weight="500" size="18px" responsiveFontSize={14}>
                        {receipt.billTo}
                      </Text>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="grid__header">
                      <Text responsiveFontSize={12} weight="500">
                        Service
                      </Text>
                      <Text responsiveFontSize={12} weight="500">
                        Description
                      </Text>
                      <Text responsiveFontSize={12} weight="500">
                        Units
                      </Text>
                      <Text responsiveFontSize={12} weight="500">
                        Unit Price
                      </Text>
                      <Text responsiveFontSize={12} weight="500">
                        Unit Tax
                      </Text>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <PaidWatermark>PAID</PaidWatermark>
                    </div>
                    <div>
                      {receipt?.services?.map(
                        (
                          { serviceName, description, itemUnit, charge, tax },
                          key
                        ) => (
                          <div className="grid__body" key={key}>
                            <Text responsiveFontSize={12}>{serviceName}</Text>
                            <Text responsiveFontSize={12}>{description}</Text>
                            <Text responsiveFontSize={12}>{itemUnit}</Text>
                            <Text responsiveFontSize={12}>
                              {addCommasToNumber(setDecimalPlaces(charge))}
                            </Text>
                            <Text responsiveFontSize={12}>
                              {addCommasToNumber(setDecimalPlaces(tax))}
                            </Text>
                          </div>
                        )
                      )}
                    </div>

                    <TableTotalContainer
                      style={{ fontWeight: '700', fontSize: '20px' }}
                    >
                      <div>
                        <FlexDiv>
                          <Text responsiveFontSize={12} className="sub">
                            Sub Total:
                          </Text>
                          <Text responsiveFontSize={12}>
                            {convertCurrencyToSign(receipt.currency)}
                            {addCommasToNumber(
                              setDecimalPlaces(receipt.totalAmount)
                            )}
                          </Text>
                        </FlexDiv>
                        <FlexDiv>
                          <Text responsiveFontSize={12} className="sub">
                            Tax:{' '}
                          </Text>
                          <Text responsiveFontSize={12}>
                            {convertCurrencyToSign(receipt.currency)}
                            {addCommasToNumber(
                              setDecimalPlaces(receipt.totalTax)
                            )}
                          </Text>
                        </FlexDiv>
                        <FlexDiv>
                          <Text responsiveFontSize={12} className="sub">
                            Total
                          </Text>
                          <Text responsiveFontSize={12}>
                            {convertCurrencyToSign(receipt.currency)}
                            {addCommasToNumber(
                              setDecimalPlaces(
                                receipt.totalAmount + receipt.totalTax
                              )
                            )}
                          </Text>
                        </FlexDiv>
                      </div>
                    </TableTotalContainer>
                  </div>
                  <ReceiptFooter>
                    Powered by Easei
                    <img
                      src="https://easeitestac8f.blob.core.windows.net/uploadblob-test/easeiInvoiceLogo.png"
                      alt=""
                      width="18"
                      height="18"
                      style={{ marginLeft: '13px' }}
                    />
                  </ReceiptFooter>
                </div>
              </Card>
            </InvoiceDetailsContent>
          )}
        </div>
      </div>
    </>
  );
};

export default ReceiptView;
