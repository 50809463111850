import styled from 'styled-components';

const InputCardContainer = styled.div`
  background: ${({ bg }) => bg || '#fff'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 2;
  gap: 4px;
  max-width: ${({ maxWidth }) => maxWidth || ''};
  margin: ${({ margin }) => margin};
  position: relative;
  padding: 1.3rem;
  font-size: ${({ size }) => size || ''};
  border-radius: 10px;
  height: ${({ height }) => height || '64px'};
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
  svg {
    color: ${({ iconColor }) => iconColor || ''};
    width: ${({ size }) => size || '30px'};
    height: ${({ size }) => size || '30px'};
  }
  cursor: pointer;
`;

const InputCard = ({ children, ...props }) => {
  return (
    <InputCardContainer {...(props ?? null)}>{children}</InputCardContainer>
  );
};

export default InputCard;
