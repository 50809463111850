import React from 'react';
import Text from 'app/components/atoms/typography';
import { ProfilePictureStyle } from 'app/components/molecules/user-profile/user-profile.styles';
import ActionMenu from 'app/components/molecules/action-menu/action-menu.component';
import ProfilePicture from 'app/components/molecules/profile-picture/profile-picture.component';
import ReactPaginate from 'react-paginate';
import { Vortex } from 'react-loader-spinner';
import '../../../../src/index.css';
import { useState } from 'react';
import { FlexedDiv } from 'app/components/atoms/divs/div.styles';
import { CardStyle } from 'app/components/atoms/card/card.component';
import { getRandomColor } from 'utils/helpers/getRandomColor/getRandomColor';

const ProformaMobile = ({ transactions, pageCount, loading, onChangePage }) => {
  const basicMenu = (row) => (
    <ActionMenu
      popupList={['View', 'Download']}
      row={row}
      downloadFileName={row.receiptNo}
      downloadLink={row.receiptPdfUrl}
    />
  );

  const [page, setPage] = useState(0);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <Vortex
            height="80"
            width="80"
            ariaLabel="vortex-loading"
            wrapperClass="vortex-wrapper"
            colors={['red', 'blue', 'yellow', '#fe9900']}
          />
        </div>
      ) : (
        <>
          <CardStyle>
            {transactions?.map((row, index) => (
              <div
                key={index}
                style={{
                  borderBottom: '0.5px solid #6C98F3',
                  paddingBottom: '10px',
                }}
              >
                <FlexedDiv>
                  <Text size="12px" weight="900">
                    {row.receiptNo}
                  </Text>
                  <div>{basicMenu(row)}</div>
                </FlexedDiv>
                <FlexedDiv>
                  <FlexedDiv>
                    <ProfilePictureStyle
                      style={{ backgroundColor: getRandomColor() }}
                    >
                      <ProfilePicture
                        name={row?.paymentBeneficiaryInformation
                          .map((item) => item.accountName)
                          .join(', ')}
                      ></ProfilePicture>
                    </ProfilePictureStyle>

                    <FlexedDiv
                      flexDirection="column"
                      alignItems="start"
                      style={{ marginLeft: 10 }}
                    >
                      <Text color="#3A3E47" size="14px">
                        {row?.paymentBeneficiaryInformation
                          .map((item) => item.accountName)
                          .join(', ')}
                      </Text>
                      <Text margin="-10px 0 0 0" color="#3A3E47" size="10px">
                        {row.createdAt && new Date().toLocaleDateString()}
                      </Text>
                    </FlexedDiv>
                  </FlexedDiv>
                  <Text weight="900">
                    {row.currency +
                      ' ' +
                      row.totalAmount
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Text>
                </FlexedDiv>
              </div>
            ))}
          </CardStyle>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 30,
            }}
          >
            <ReactPaginate
              breakLabel="..."
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              forcePage={page}
              onPageChange={(e) => {
                setPage(e.selected);
                onChangePage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ProformaMobile;
