import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { PLAN_TYPE } from 'utils/constants/constants.utils';

const entity = process.env.REACT_APP_BASE_URL + 'plan';

export const initialState = {
  planList: [],
  loading: false,
  organizationPlan: [],
  clientUserPlan: [],
  plansCount: 0,
  myPlan: {},
};

const getPlans = createAsyncThunk(`${entity}/list`, async (payload) => {
  const res = await api.plan.getPlan(payload);
  return res.data.data;
});

const myPlan = createAsyncThunk(`${entity}/myPlan`, async () => {
  const res = await api.plan.myPlan();
  return res.data.data;
});

const createPlan = createAsyncThunk(`${entity}/create`, async (input) => {
  const res = await api.plan.createPlan(input);
  return res.data;
});

const updatePlan = createAsyncThunk(`${entity}/update`, async (input) => {
  const res = await api.plan.updatePlan(input);
  return res.data;
});

const makePlanPayment = createAsyncThunk(`${entity}/payment`, async (input) => {
  const res = await api.plan.makePlanPayment(input);
  return res.data;
});

const verifyPayment = createAsyncThunk(
  `${entity}/verify-payment`,
  async (reference) => {
    const res = await api.plan.verifyPayment(reference);
    return res;
  }
);

const planSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    getPlansCount: (state) => {
      state.plansCount = state.planList.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlans.fulfilled, (state, action) => {
        state.planList = action.payload;
        state.organizationPlan = state.planList.filter(
          (plan) => plan.planTypeName === PLAN_TYPE.ORGANIZATION
        );
        state.clientUserPlan = state.planList.filter(
          (plan) => plan.planTypeName === PLAN_TYPE.CLIENT
        );
        state.loading = false;
      })
      .addCase(getPlans.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getPlans.rejected, (state) => {
        return { ...state, loading: true };
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(createPlan.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(createPlan.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(updatePlan.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updatePlan.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(myPlan.fulfilled, (state, action) => {
        state.myPlan = action.payload;
        state.loading = false;
      })
      .addCase(myPlan.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(myPlan.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(makePlanPayment.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(makePlanPayment.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(makePlanPayment.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(verifyPayment.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(verifyPayment.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(verifyPayment.rejected, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      });
  },
});

export const planActions = {
  ...planSlice.actions,
  getPlans,
  myPlan,
  createPlan,
  updatePlan,
  makePlanPayment,
  verifyPayment,
};

export const planSelectors = {
  isLoading: (state) => state.plan.loading,
  planList: (state) => state.plan.planList,
  organizationPlan: (state) => state.plan.organizationPlan,
  clientUserPlan: (state) => state.plan.clientUserPlan,
  plansCount: (state) => state.plan.plansCount,
  allPlans: (state) => state.plan.planList,
  myPlan: (state) => state.plan.myPlan,
  organizationPlansForDropdown: (state) => [
    { value: null, label: 'All' },
    ...state.plan.organizationPlan?.map(function (item) {
      return { value: item.id, label: item.name };
    }),
  ],
};

export default planSlice.reducer;
