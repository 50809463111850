import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentActions,
  paymentSelectors,
} from 'store/reducers/payment/paymentSlice';
import Card from 'app/components/atoms/card/card.component';
import styled from 'styled-components';
import Text, { Heading } from 'app/components/atoms/typography';
import { toast } from 'react-toastify';
import { ResponsiveBaseButton } from '../receipt/receipt.style';
const StylePaymentView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;
const RemitaPay = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const successDetails = useSelector(paymentSelectors.successDetails);
  const paymentLoading = useSelector(paymentSelectors.isLoading);

  const {
    getTransactionDetailsByReference,
    confirmTransactionDetailsByReference,
  } = paymentActions;
  const [activeView, setActiveView] = useState('initial');
  const [closed, setClosed] = useState('');
  const [response, setResponse] = useState(null);
  useEffect(() => {
    if (activeView !== 'initial' || closed) {
      handleClose();
    }
  }, [activeView, closed]);
  useEffect(() => {
    const handlePay = (transactionDetails) => {
      const paymentEngine = window.RmPaymentEngine.init({
        key: transactionDetails?.hashKey,
        processRrr: true,
        transactionId: transactionDetails?.transactionId,
        narration: '',
        extendedData: {
          customFields: [
            {
              name: 'rrr',
              value: transactionDetails?.authorizationId,
            },
          ],
        },
        onSuccess: function (resp) {
          // function callback when payment is successful
          dispatch(
            confirmTransactionDetailsByReference({
              transactionRef: params?.id,
            })
          );
          setActiveView('success');
          toast.success('Payment Successful!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError: function (resp) {
          // function callback when payment fails
          toast.error('Payment unsuccessful, Something went wrong!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setActiveView('error');
        },
        onClose: function () {
          // function callback when payment modal is closed
          setClosed('closed' + Math.random());
        },
      });
      paymentEngine.showPaymentWidget();
    };
    if (params?.id) {
      dispatch(
        getTransactionDetailsByReference({ transactionReference: params?.id })
      ).then((res) => handlePay(res.payload));
    }
  }, [dispatch, params?.id, getTransactionDetailsByReference]);

  const handleClose = () => {
    if (activeView !== 'success') {
      setResponse(
        activeView === 'error'
          ? 'Something went wrong redirecting you in 6 seconds'
          : 'You closed the payment modal redirecting you in 6 seconds'
      );
      setTimeout(() => {
        window.top.close();
      }, 6000);
    }
    if (activeView === 'success' && paymentLoading) {
      setResponse('Please wait while we confirm Your payment');
    }
  };

  return (
    <StylePaymentView>
      <div
        style={{
          width: '100%',
          maxWidth: '320px',
          minHeight: 'max-content',
          textAlign: 'center',
        }}
      >
        <Card>
          {activeView === 'initial' && (
            <>
              <Heading responsiveFontSize={18} level={2} textAlign="center">
                Please wait while we Process your transactions
              </Heading>
              {response && (
                <Text textAlign="center" size="24px">
                  {typeof response === 'string'
                    ? response
                    : 'Something went wrong'}
                </Text>
              )}
            </>
          )}
          {activeView === 'error' && (
            <>
              <Heading responsiveFontSize={18} level={2} textAlign="center">
                Payment Error!
              </Heading>
              <Text textAlign="center" size="24px">
                {JSON.stringify(response)}
              </Text>
            </>
          )}
          {activeView === 'success' && (
            <>
              <Heading responsiveFontSize={18} level={2} textAlign="center">
                Payment Successful!
              </Heading>
              <Text textAlign="center" size="24px" margin="0 0 16px 0">
                {paymentLoading ? response : successDetails}
              </Text>

              <ResponsiveBaseButton
                padding={'2px !important'}
                minWidth={'0'}
                fontSize="13px"
                height="40px"
                responsiveFontSize=".6rem"
                isLoading={paymentLoading}
                onClick={(e) => window.top.close()}
              >
                Close page
              </ResponsiveBaseButton>
            </>
          )}
        </Card>
      </div>
    </StylePaymentView>
  );
};

export default RemitaPay;
