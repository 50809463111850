import { GrFormClose } from 'react-icons/gr';
import Modal from 'react-modal';
import { forwardRef } from 'react';
import { CloseButton, customStyles } from './modal.styles';

const CustomModal = forwardRef(
  ({ close, children, isOpen, className, style }, ref) => {
    return (
      <Modal
        isOpen={isOpen}
        style={className ? style : customStyles}
        className={className}
        onRequestClose={() => close(false)}
        ariaHideApp={false}
        ref={ref}
      >
        <CloseButton className="modal-close">
          <GrFormClose onClick={() => close(false)} size={30} />
        </CloseButton>
        <div>{children}</div>
      </Modal>
    );
  }
);

export default CustomModal;
