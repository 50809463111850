import styled from 'styled-components';

export const TitleContainer = styled.div`
  text-align: center;
`;

export const FlexDiv = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: ${({ gap }) => gap || '30px'};
`;

export const TableTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  @media screen and (min-width: 768px) {
    div: nth-child(2) {
      min-width: 288px;
    }
  }
  @media screen and (max-width: 400px) {
    justify-content: center;
  }
  .total {
    flex: 1;
  }

  .sub {
    display: flex;
    gap: 40px;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    p {
      font-size: 15px;
      font-weight: 900;
      margin: 0;
    }
    p: first-child {
      text-align: start;
      width: 4rem;
    }
    @media screen and (min-width: 768px) {
    }
  }
  @media screen and (min-width: 700px) {
    .total {
      flex: initial;
    }
  }
`;

export const DetailsContent = styled.div`
  p {
    color: '#3A3E47';
  }
`;

export const TopBanner = styled.div`
  overflow: hidden;
  background: white;
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
  border-top: 2px solid #dbe7ff;
  width: 100%;

  .client-invoice__new {
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    @media screen and (min-width: 700px) {
      align-items: center;
      flex-direction: row;
      gap: 0;
      justify-content: space-between;
    }
    div {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .invoice-details__close {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }

    svg {
      cursor: pointer;
      opacity: 0.65;
      filter: alpha(opacity=65);
      color: #808080;
      width: 25px;
      height: 25px;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }
`;

export const ActionDivContainer = styled.div`
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 20px;
  border-radius: 10px;
  border: none;
  height: ${({ height }) => height || '64px'};
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
  cursor: pointer;
  svg {
    color: ${({ iconColor }) => iconColor || ''};
    width: 18.75px;
    height: 18.75px;
  }
`;

export const CustomTableButton = styled.button`
  outline: none;
  border: 1px solid #dbe7ff;
  border-radius: 8px;
  font-size: 20px;
  color: black;
  background: #fbfcff;
  height: 3.9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 10px 16px;
`;

export const CustomTableSearch = styled.div`
  border: 1px solid #dbe7ff;
  border-radius: 8px;
  font-size: 20px;
  color: black;
  background: #fbfcff;
  height: 3.9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 10px 16px;
  input {
    border: 0;
    background-color: transparent;
    outline: none;
    ::placeholder {
      color: black;
      font-size: 18px;
    }
  }
`;

export const SimpleSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
  margin: 32px 0;
`;

export const FlexedDiv = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: center;
  gap: 8px;
  width: ${({ width }) => width};
`;

export const SummaryLink = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  overflow: hidden;
  cursor: pointer;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 4px;
    max-width: max-content;
    flex: 1;
    text-align: center;

    &.active,
    &.inactive {
      border: 0.5px #a4b0c9 solid;
      border-radius: 18px;
      padding: 5px 15px;
      gap: 10px;
      p {
        margin: 0;
      }
    }
    &.active {
      color: var(--primary1);
      background-color: #b8c6e4;
    }
    &.inactive {
      color: var(--grey2);
      background-color: none;
    }

    @media screen and (min-width: 768px) {
      padding: 0 0.25rem;
      &.active,
      &.inactive {
        border: none;
        border-radius: 0;
        padding: 0;
        gap: 10px;
      }
      &.active {
        background: none;
        border-bottom: 1px solid var(--grey1);
        width: fit-content;
        font-weight: 700;
      }

      p {
        margin: 0;
      }
    }
  }
  .summary-link-count {
    display: flex;
    width: 41px;
    height: 24px;
    border: 1px solid #b8c6e4;
    border-radius: 30px;
    background: #f2f6ff;
    align-items: center;
    justify-content: center;

    p {
      color: #7b8599;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }
  @media screen and (min-width: 800px) {
    justify-content: flex-start;
  }
`;

export const MetricDiv = styled.div`
  position: relative;
  margin-top: 32px;
  overflow: hidden;
  padding-inline: 20px;
`;

export const InvoiceContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: 950px) {
    padding: 20px 50px;
    div:first-child {
      svg {
        width: 80%;
        height: 80%;
      }
    }
  }
`;

export const InvoiceContainer = styled.div`
  p,
  h1 {
    margin: 0;
  }
`;

export const InvoiceTextContainer = styled.div`
  margin: 70px 0 40px;
  @media screen and (max-width: 768px) {
    margin: 30px 0 15px;
  }
`;

export const AdditionalNoteArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 15px;
  padding-left: 15px;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
  textarea {
    background-color: #e3edff;
    padding-right: 15px;
    ::placeholder {
      color: var(--grey1);
      font-weight: 500;
      opacity: 1;
      font-size: 12px;
    }
  }
`;

export const TotalWrapper = styled(FlexedDiv)`
  border: 1px solid #a4b0c9;
  border-radius: 4px;
  background: #f2f6ff;
  padding: 10px;
  p {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const InvoiceDetailsContent = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  p {
    color: #3a3e47;
  }
  .card {
    border-radius: 0;
    padding: 0;
    color: var(--grey1);
    &__header {
      background-color: ${({ bgcolor }) => bgcolor || '#011747'};
      text-align: right;
      padding: 10px 10px 100px;
      min-height: 8.125rem;
      p {
        color: #b5ccfe;
      }
      img {
        float: left;
        height: 6.875rem;
        width: auto;
      }

      @media screen and (max-width: 768px) {
        padding: 10px 10px 26px;
      }
    }
    &__body {
      padding: 30px 50px;
      @media screen and (max-width: 768px) {
        padding: 30px 15px;
      }
      .flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .right {
          text-align: right;
        }

        @media screen and (min-width: 768px) {
          flex-direction: row;
          .right {
            text-align: right;
          }
        }
      }
      .grid__header {
        border-top: 1px solid #a4b0c9;
        border-bottom: 1px solid #a4b0c9;
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(${({ repeat }) => repeat || '5'}, 1fr);
        padding: 20px 0;
        p {
          font-weight: 700;
          color: var(--grey1);
          font-size: 10px;
        }
      }
      .grid__header.proforma {
        background: var(--primary8);
        padding: 20px;
        margin-top: 40px;
        border: none;
        p {
          font-size: 14px;
        }
      }
      .grid__body {
        display: grid;
        gap: 15px;
        padding: 20px 20px 50px;
        grid-template-columns: repeat(${({ repeat }) => repeat || '5'}, 1.5fr);
        p {
          font-weight: 500;
          color: var(--grey1);
          font-size: 12px;
        }
      }
    }
    &__footer {
      background-color: #dbe7ff;
      .footer {
        font-size: 10px;
        border-top: 1px solid #7b8599;
        padding: 11px 20px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      @media screen and (min-width: 768px) {
        padding: 70px 0 0 28px;
        .footer {
          padding: 30px 60px;
        }
      }
    }
  }
`;

export const AccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 30px 6px 0;
  .sub {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 21px;
    p {
      color: var(--primar1);
      font-size: 12px;
      margin: 0;
    }
    p:last-child {
      font-weight: 700;
    }
  }
`;

export const AdditionalInfo = styled.div`
  background-color: #e6eefe;
  border: 0.1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 11px 0;
  font-size: 10px;
  @media screen and (min-width: 768px) {
  }
`;

export const AdditionalInformation = styled.div`
  border-top: 0.1px solid var(--grey4);
  width: 100%;
  padding: 2.5rem;
  & > p:first-child {
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--grey1);
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 768px) {
    padding: 1.25rem;
    p:first-child {
      margin-bottom: 1rem;
    }
  }
`;

export const FileUpload = styled.div`
  position: relative;
  label,
  input[type='file'] {
    position: absolute;
    cursor: pointer;
    top: 40px;
    left: 90%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: rotate(30deg);
    @media screen and (max-width: 550px) {
      top: 30px;
      left: 88%;
    }
    @media screen and (max-width: 450px) {
      top: 10px;
    }
    @media screen and (max-width: 360px) {
      top: 0px;
    }
  }
  label {
    font-size: 1.5rem;
  }
`;

export const FileUploaded = styled.div`
  background-color: #dbe7ff;
  width: fit-content;
  border: none;
  color: #0148de;
  display: flex;
  margin: 0 0 25px;
  align-items: center;
  border-radius: 8px;
  padding: 5px 12px;
  font-weight: 900;
  p {
    margin-right: 15px;
  }
`;

export const OutcomeCircle = styled.div`
  height: 7rem;
  width: 7rem;
  background-color: ${({ decline }) => (decline ? 'red' : 'green')};
  color: white;
  font-size: 5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  margin-bottom: 30px;
`;

export const ActionButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 30px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 2fr 1fr;

    button {
      width: auto;
    }
    div:first-child {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      width: auto;
    }

    div:last-child {
      max-width: 250px;
    }

    .invoice-details-download-button {
      justify-self: end;
    }
  }
`;

export const VeryFlexedDiv = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  margin: ${({ margin }) => margin};

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }

  .invoice-details__invoice-number {
    margin-bottom: 20px;
    p {
      margin: 0;
      color: '#3A3E47';
    }
  }

  .invoice-details__banner {
    gap: 40px;
  }
`;

export const SearchComponentContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  align-items: flex-start;
  font-size: 1rem;
  width: 100vw;
  padding-inline: 1rem;
`;

export const MobileSearchComponentContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  row-gap: 1rem;
`;

export const CreateNewContainer = styled.div`
  padding: 8px 24px;
  letter-spacing: 0.5px;
  line-height: 60px;
  background-color: #0148de;
  color: #f2f6ff;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  p {
    margin: 0;
  }
  @media screen and (max-width: 650px) {
    padding: 10px;
  }
`;

export const OptionSelectCircle = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin: 0 3px 0 13px;
  border: ${({ border }) => border || '2px solid var(--accent)'};
  background-color: ${({ active }) => (active ? 'var(--accent)' : '')};

  @media screen and (max-width: 345px) {
    width: 10px;
    height: 10px;
  }
  @media screen and (min-width: 768px) {
    border: ${({ border }) => border || '2px solid var(--primary2)'};
    background-color: ${({ active }) => (active ? 'var(--primary2)' : '')};
  }
  @media screen and (max-width: 800px) {
    padding: 0.1rem;
    width: 15px;
    height: 15px;
  }
`;

export const MobileResponsiveButton = styled.div`
  min-width: ${({ minWidth }) => minWidth || '200px'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '60px'};
  letter-spacing: 0.5px;
  padding: ${({ padding }) => padding || '16px 32px'};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  margin: ${({ margin }) => margin};
  background-color: #0148de;
  color: ${({ color }) => color || '#f2f6ff'};
  font-family: 'DM Sans';
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;

  button[disabled='disabled'],
  button:disabled {
    cursor: not-allowed;
    background-color: var(--primary7);
  }

  &:hover:not([disabled]):not(:disabled) {
    cursor: pointer;
    background-color: #3777ff;
    color: #f2f6ff;
    border: none;
  }
  border-radius: 20px;
  height: fit-content;
  padding: ${({ padding }) => padding || '.6rem '};
  font-size: 0.75rem;
  width: ${({ width }) => width || 'fit-content'};
  min-width: fit-content;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  max-height: ${({ maxHeight }) => maxHeight};
  p {
    margin: 0;
  }
  @media screen and (max-width: 350px) {
    font-size: 0.5rem;
    padding: ${({ paddingRes }) => paddingRes};
  }
`;

export const Empty = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 100%;
  }
  p + p {
    margin-top: 1em;
  }
`;

export const SelectOccurance = styled.div`
  font-size: ${({ size }) => size || ' 12px'};
  color: var(--primary2);
  font-weight: 700;
  @media screen and (max-width: 345px) {
    font-size: 10px;
  }
`;

export const GeneralDivIII = styled.div`
  background: var(--primary8);
  p {
    color: var(--primary2);
  }
  line-height: 1;
  padding: 0 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .recipient__cancel {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    text-indent: 10em;
    overflow: hidden;
    cursor: pointer;
    &::after {
      position: absolute;
      content: '\u2715';
      line-height: 0.5;
      font-size: 1rem;
      top: 0.4em;
      left: 0;
      text-indent: 0;
    }
  }
`;

export const DateSelectInput = styled.div`
  background-color: transparent;
  padding: 2em;
  font-size: 14px;
  padding: 2em;
  display: block;
  width: ${({ width }) => width || '100%'};
  margin: 20px 0 10px;
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--primary);
  align-items: center;
  @media screen and (max-width: 345px) {
    font-size: 10px;
  }
  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
  position: relative;
  .calendar {
    position: absolute;
    z-index: 3;
    top: 1.5em;
  }
`;
