import React, { useState } from 'react';
import { Flex } from '../dashboard/dashboard.styles';
import { BsSearch } from 'react-icons/bs';
import Text, { Heading } from 'app/components/atoms/typography';
import { CardStyle } from 'app/components/atoms/card/card.component';
import { Vortex } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import { PeopleMobileWrapper } from '../people/people.styles';
import { NoStyleButton } from 'app/components/atoms/button/button.styles';

const ChargeMobileView = ({
  setFilterInput,
  charges,
  handleEdit,
  loading,
  onChangePage,
  pageCount,
}) => {
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  return (
    <>
      <CardStyle margin="32px 0 0 0 ">
        <PeopleMobileWrapper>
          <div className="filter_container">
            <Flex style={{ width: '100%' }}>
              <Heading
                margin="0"
                color="var(--primary2)"
                size={14}
                style={{ flex: 1, width: '100%', textAlign: 'left' }}
              >
                Charges
              </Heading>
              <div
                className="search_filter field"
                style={{ maxWidth: '200px' }}
              >
                <BsSearch
                  className="icon"
                  onClick={() => {
                    setFilterInput(searchInput);
                  }}
                />
                <input
                  type="text"
                  style={{ outline: 'none' }}
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </Flex>
          </div>

          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <Vortex
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperClass="vortex-wrapper"
                colors={['red', 'blue', 'yellow', '#fe9900']}
              />
            </div>
          ) : (
            <div>
              <Text
                style={{
                  borderBottom: '2px solid #3A3E47',
                  width: 'fit-content',
                  padding: '8px',
                  marginBottom: '8px',
                }}
                color="#3A3E47"
              >
                All ({charges.length})
              </Text>

              {charges?.map((charge, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      borderBottom: '.5px solid var(--primary2)',
                      marginBottom: '15px',
                    }}
                  >
                    <Flex
                      justifyContent="flex-start"
                      alignItems="center"
                      textAlign="start"
                      gap="10px"
                    >
                      <div style={{ flex: 1 }}>
                        <Flex>
                          <Heading
                            style={{ flex: '60%' }}
                            margin="0"
                            color="var(--primary2)"
                            textAlign="start"
                            size={14}
                          >
                            {charge.serviceChargeTypeName}
                          </Heading>
                          <Text margin="0px 0px 0px 0px">
                            cap: {charge.cap}
                          </Text>
                          <div>
                            <NoStyleButton onClick={() => handleEdit(charge)}>
                              <Text
                                color="var(--primary)"
                                size="14px"
                                weight="700"
                                style={{ textDecoration: 'underline' }}
                              >
                                Edit
                              </Text>
                            </NoStyleButton>
                          </div>
                        </Flex>
                        <Flex>
                          <div>
                            <Flex wrap="wrap" wordWrap="break-word">
                              <Text margin="0" color="var(--grey2)" size={12}>
                                Min: {charge.minimumAmount}
                              </Text>
                            </Flex>
                            <Flex wrap="wrap" wordWrap="break-word">
                              <Text
                                margin="0"
                                color="var(--grey2)"
                                wordBreak="break-word"
                                textAlign="start"
                                size={14}
                              >
                                Max: {charge.maximumAmount}
                              </Text>
                            </Flex>
                          </div>
                          <Text margin="0" color="var(--grey2)" size={12}>
                            Charge: {charge.serviceCharge}
                            {charge.isPercentage && '%'}
                          </Text>
                        </Flex>
                      </div>
                    </Flex>
                  </div>
                );
              })}
            </div>
          )}
        </PeopleMobileWrapper>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}
        >
          <ReactPaginate
            breakLabel="..."
            previousLabel={'←'}
            nextLabel={'→'}
            forcePage={page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={(e) => {
              setPage(e.selected);
              onChangePage(e.selected + 1);
            }}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active'}
          />
        </div>
      </CardStyle>
    </>
  );
};

export default ChargeMobileView;
