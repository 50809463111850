import { useSelector } from 'react-redux';
import { appdataSelector } from 'store/reducers/appData/appDataSlice';
import BaseBillSetup from '../baseSetup/baseSetupBill';

const BaseCreateBill = ({
  handleSave,
  ClientDropDown,
  organization,
  setOrganization,
}) => {
  const bill = useSelector(appdataSelector.previewBill);

  return (
    <BaseBillSetup
      invoice={bill}
      handleSave={handleSave}
      organization={organization}
      setOrganization={setOrganization}
      ClientDropDown={ClientDropDown}
    />
  );
};

export default BaseCreateBill;
