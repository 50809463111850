import PreFooter from 'app/components/molecules/pre-footer/pre-footer.component';
import { useViewport } from 'hooks';
import styled from 'styled-components';

export const Header = styled.div`
  p {
    margin: 0;
  }
  & > p:nth-child(3) {
    margin-top: 30px;
  }
`;

export const ContentCardsContainer = styled.div`
  margin-top: 80px;

  & > p {
    margin: 0;
    margin-top: 8px;
  }

  > div {
    margin-top: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  > div > * + * {
    margin-top: 24px;
  }
`;

const Container = styled.div`
  background: var(--white);
  position: relative;

  & > .container {
    position: relative;
    z-index: 2;
    padding: 50px 0;
  }
  p,
  h1,
  h2,
  h3 {
    color: var(--grey1);
  }
`;
const Rectangle = styled.div`
  position: absolute;
  content: '';
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const LeftRectangle = styled(Rectangle)`
  & div:nth-child(1) {
    content: '';
    position: absolute;
    width: 308px;
    height: 503px;
    left: -80px;
    top: 50px;
    /* White Text */
    background: #f2f6ff;
    border: 1px solid rgba(254, 186, 39, 0.48);
    border-radius: 10px;
    transform: rotate(-62.03deg);
  }
  & div:nth-child(2) {
    position: absolute;
    width: 216px;
    height: 503px;
    left: -100px;
    top: 50px;
    background: #b3c8f4;
    border-radius: 5px;
    transform: rotate(52.36deg);
  }
`;
const RightRectangle = styled(Rectangle)`
  & div:nth-child(1) {
    content: '';
    position: absolute;
    width: 308px;
    height: 503px;
    right: -50px;
    top: 50px;
    /* White Text */
    background: #f2f6ff;
    border: 1px solid rgba(254, 186, 39, 0.48);
    border-radius: 10px;
    transform: rotate(-62.03deg);
  }
  & div:nth-child(2) {
    position: absolute;
    width: 216px;
    height: 503px;
    right: -100px;
    top: 10px;
    background: #b3c8f4;
    border-radius: 5px;
    transform: rotate(52.36deg);
  }
`;
const ContentContainer = ({ children, ...otherProps }) => {
  const { mobile } = useViewport();
  return (
    <Container {...otherProps}>
      {children}
      <PreFooter />
      {!mobile && (
        <RightRectangle>
          <div></div>
          <div></div>
        </RightRectangle>
      )}
      <LeftRectangle>
        <div></div>
        <div></div>
      </LeftRectangle>
    </Container>
  );
};

export default ContentContainer;
