import { useField } from 'formik';
import { useViewport } from 'hooks';
import Select from 'react-select';

import styled from 'styled-components';

const mainColor = '#3A3E47';

export const FormInputLabel = styled.label`
  color: ${mainColor};
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: -3px;
  left: 0px;
  transition: 300ms ease all;
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin: ${({ margin }) => margin || '20px 0 10px'};

  .select {
    &__control {
      background-color: #f2f6ff;
      width: ${({ width }) => width || '100%'};
      min-height: ${({ height }) => height};
      border: 1px solid #dbe7ff;
      border-radius: 5px;
      option {
        font-size: 18px;
      }
    }
    &__value-container {
      padding: ${({ padding }) => padding};
    }
    &__menu-list {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  position: relative;
`;

const FormSelect = ({ label, onChange, disabled, options, ...otherProps }) => {
  const [field] = useField(otherProps);
  const { mobile } = useViewport();
  return (
    <FormGroup>
      <CustomSelect
        {...otherProps}
        classNamePrefix="select"
        value={
          options ? options.find((option) => option.value === field.value) : ''
        }
        options={options}
        onChange={(value) => onChange(value)}
      />
      {label && (
        <FormInputLabel
          shrink={field.value?.length}
          htmlFor={field?.name}
          color={mobile ? '#fff' : ''}
        >
          {label}
        </FormInputLabel>
      )}
    </FormGroup>
  );
};

export default FormSelect;
