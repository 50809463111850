import ContentContainer, {
  ContentCardsContainer,
  Header,
} from 'app/components/atoms/about-content-layout/ContentContainer.component';
import { CardContainer } from 'app/components/atoms/card/card.component';
import List from 'app/components/atoms/list/list.component';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import './policy.styles.css';

const Policy = () => {
  return (
    <ContentContainer>
      <div className="container">
        <Header>
          <Heading weight="400" size="56">
            Privacy &{' '}
            <Span color="#fe9900" display="inline">
              {' '}
              Policy _{' '}
            </Span>
          </Heading>
          <Text>Last updated on January 10, 2023</Text>
          <Text style={{ maxWidth: '900px ' }}>
            This privacy policy shows how your personal information is
            collected, used and disclosed when you use our services. Please note
            that this policy is subject to change.{' '}
            <Span weight="600">
              {' '}
              But regardless of any changes we will never sell your data.
            </Span>
          </Text>
        </Header>
        <ContentCardsContainer>
          <CardContainer>
            <Heading level={2}>Who we are</Heading>
            <Text>
              Easei is a product owned by Blazor Technologies LLC. We’re
              committed to protecting and respecting your privacy. If you have
              any question(s) about the storage or use of your personal
              information please contact us.
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>The information we have</Heading>
            <div className="info">
              <Heading level={3}>Billing Information</Heading>
              <Text>
                When you pay for Easei, we ask for your credit card. That’s so
                we can charge you for Service, and send you invoices. Your
                credit card is passed directly to our payment processor and
                doesn’t ever go through our servers.
              </Text>
              <Heading level={3}>Invoicing and User Information</Heading>
              <Text>
                In order to render our services to you, we collect some of your
                data, including invoicing and user information. All the data we
                collect from you is private to you and the entities you
                associate within the application. Your information is stored on
                secure servers, and we only access it when you permit us to. We
                have employed the use of firewalls, data protection rules, and
                continuous monitoring to ensure we never compromise your data.
              </Text>
              <Heading level={3}>Browser information</Heading>
              <Text>
                This information includes your IP address, browser type, browser
                version, the pages of our app that you visit, the time and date
                of your visit, the time spent on those pages, unique device
                identifiers and other diagnostic data your internet browser
                sends when you use our service or access our website.
              </Text>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>How we use the information</Heading>
            <div>
              <Heading level={3}>
                To provide our services, we use your personal data to;
              </Heading>
              <List el={'ol'}>
                <Text>Setup your account</Text>
                <Text>Verify your unique identity</Text>
                <Text>Render our services</Text>
                <Text>
                  Communicate new features and recent changes in the app
                </Text>
                <Text>
                  Maintain a smooth relationship with you, by acknowledging your
                  queries and feedback.
                </Text>
              </List>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>How secure is your information</Heading>
            <Text>
              We recognize the importance of your data, as such, we take the
              security of every information provided to us by you and our
              third-party service providers, very seriously. We store personal
              information in our secured server managed by us and our service
              providers. The security measures include firewalls, hashing, data
              encryption, and information access authorization controls.
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Contact us</Heading>
            <Text>
              We are here because you are here, feel free to contact us any time
              here
            </Text>
          </CardContainer>
        </ContentCardsContainer>
      </div>
    </ContentContainer>
  );
};

export default Policy;
