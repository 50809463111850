import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as NoDataSvg } from 'app/assets/svg/rafiki2.svg';
import Card from 'app/components/atoms/card/card.component';
import Text, { Heading, Span } from 'app/components/atoms/typography';
import {
  Flex,
  OrgUserDashboardWrapper as OrgDashboardWrapper,
} from './dashboard.styles';
import { TableHeader } from '../reminder/clientsTable';
import { getInvoiceStatusCount } from 'utils/helpers/getInvoiceStatusCount/getInvoiceStatusCount';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import {
  createPaymentStatusStyle,
  createStatusStyle,
  getPaymentStatusName,
  getStatusName,
} from 'utils/helpers/createStatusStyle/createStatusStyle';
import BarChart from 'app/components/chart';

import ChartLabel from 'app/components/chart/ChartLabel';

import {
  dashboardSelectors,
  dashboardActions,
} from 'store/reducers/dashboard/dashboardSlice';
import { useCheckAdmin, useOnClickOutside, useViewport } from 'hooks';
import { USER_ROLES } from 'store/reducers/auth/utils';
import Carousel from 'app/components/MetricCarousel';
import CircularProgressBar from 'app/components/progress-bar/CircularProgressBar';
import {
  FlexedDiv,
  OptionSelectCircle,
  SelectOccurance,
  SummaryLink,
} from 'app/components/atoms/divs/div.styles';
import InvoiceList from 'app/components/cell/invoiceList/invoiceList';
import {
  INVOICE_STATUS_DROPDOWN_DATA,
  ROUTES,
} from 'utils/constants/constants.utils';
import { addCommasToNumber } from 'utils/helpers/addCommaToNumber/addCommaToNumbers';
import CircularProgressWithChildren from 'app/components/progress-bar/CircularProgressWithChildren';
import DateFilterDropdown from 'app/components/molecules/datefilter/datefilter-dropdown.component';
import { Link } from 'react-router-dom';
import { randomColorGenerator } from 'utils/helpers/randomColorGenerator/randomColorGenerator';
import Dropdown from 'app/components/atoms/dropdown/dropdown.component';
import { FaAngleDown } from 'react-icons/fa';
import {
  invoiceActions,
  invoiceSelectors,
} from 'store/reducers/invoice/invoiceSlice';
import DoughnutChart from 'app/components/chart/DoughnutCart';
import { pruneReminders } from 'utils/contents/reminders.content';

export const WeekActive = styled.div`
  position: relative;
  width: fit-content;
  @media screen and (max-width: 800px) {
    bottom: 20%;
    margin-inline: auto;
  }
  > p {
    position: absolute;
    margin: 0;
    left: 50%;
    bottom: 50%;
    transform: translate(-30%, -10%);
    @media screen and (max-width: 800px) {
      bottom: 20%;
    }
  }
  .activate {
    background: #5cbc77;
    border-radius: 50px;
    width: 112px;
    height: 44px;
    position: relative;
    display: inline-block;
    border: none;
    align-self: flex-end;
    ::after {
      content: '';
      position: absolute;
      right: 0.5em;
      top: 0.7em;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
    }
  }
  .inactive {
    background: #7b8599;
    ::after {
      left: 0.5em;
      right: auto;
    }
  }
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopLayout = styled.div`
  display: flex;
  gap: 1.5625rem;
  justify-content: between;
  flex-direction: column;
  .reminders {
    background-color: white;
    flex: 1;
    border-radius: 1.25rem;
    padding: 0.625rem 0.5rem;
    display: flex;
    flex-direction: column;
    .reminder-header {
      padding: 0 4px;
      gap: 8px;
      border-bottom: 1px solid var(--grey3);
      margin-bottom: 0.25rem;
    }
    .reminders-content {
      height: 100%;
      column-count: 2;
      column-gap: 2.5rem;
      .no-reminder {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    @media screen and (min-width: 1200px) {
      max-width: 14.0625rem;
      .reminders-content {
        display: flex;
        flex-direction: column;
      }
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  margin-right: auto;

  @media screen and (min-width: 800px) {
    margin: 0 0 38px auto;
  }
`;
export const StyledLink = styled(Link)`
  color: var(--primary);
  font-size: 12px;
  font-weight: 700;
`;
export const StatusContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 26px;
  @media screen and (max-width: 830px) {
    flex-direction: column;
    align-items: center;
  }
  .stats-container,
  .stats2-container {
    background-color: white;
    border-radius: 8px;
    flex: 1;
    display: flex;
    gap: 40px;
    padding: 40px 24px;
    @media screen and (max-width: 500px) {
      gap: 10px;
      padding: 0 10px;
      margin-top: 20px;
    }
    .stat-item {
      flex: 1;
      &:not(:last-child) {
        border-right: 1px solid var(--grey3);
      }
      .stat-invoice {
        white-space: nowrap;
      }
    }
  }
  .stats2-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .stat-item {
      &:nth-child(2) {
        border-right: transparent;
      }
    }
  }
`;
const CircularStats = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};

  .status-container {
    background-color: white;
    border-radius: 8px;
    gap: 20px;
    padding: 16px 20px;
    min-height: ${({ minHeight }) => minHeight || '224px'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    .selected-item {
      flex: 1;
      position: relative;
    }
    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      @media screen and (max-width: 500px) {
        gap: 10px;
      }
    }
  }
`;

const OrganizationDashboard = ({
  active,
  setActive,
  timeInput,
  setTimeInput,
}) => {
  const dispatch = useDispatch();
  const [statusInput] = useState('Status');
  const { visible, setVisible, ref } = useOnClickOutside(false);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedView, setSelectedView] = useState('Paid vs Unpaid');
  const [selectedClientView, setSelectedClientView] =
    useState('Invoice Status');
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('');
  const { usersDashboard, clientDashboard } = dashboardActions;
  const { searchInvoices } = invoiceActions;

  const userDash = useSelector(dashboardSelectors.usersDashboard);
  const timeData = useSelector(dashboardSelectors.timeData);
  const orgMetrics = useSelector(dashboardSelectors.orgMetrics);
  const clientMetrics = useSelector(dashboardSelectors.clientMetrics);
  const userInvoiceMetrics = useSelector(dashboardSelectors.userInvoiceMetrics);
  const lastFiveInvoices = useSelector(invoiceSelectors.invoices);
  const isLoading = useSelector(dashboardSelectors.isLoading);
  const { role } = useCheckAdmin();
  const { mobile } = useViewport();
  useEffect(() => {
    dispatch(
      role === USER_ROLES.CLIENT_USER
        ? clientDashboard(timeInput)
        : usersDashboard(timeInput)
    );
    dispatchSearchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInput]);
  const dispatchSearchInvoices = () => {
    dispatch(
      searchInvoices({
        page: 1,
        pageSize: 5,
        startDate: new Date('1/1/1970'),
        endDate: new Date(),
        currency: timeInput.currency ?? 'NGN',
      })
    );
  };
  const totalClientAmount = clientMetrics?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.amount,
    0
  );
  const columns = [
    {
      name: 'Date',
      selector: (row) => row.createdDate,
      format: (row) => new Date(row.createdDate).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Invoice No',
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: 'Client',
      selector: (row) =>
        role === USER_ROLES.CLIENT_USER ? row.to : row.client,
      sortable: true,
      span: 2,
    },
    {
      name: 'Currency',
      selector: (row) => row.currency,
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
      format: (row) =>
        row.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
    },
    {
      name: 'Invoice status',
      selector: (row) => row.statusName,
      sortable: true,
      format: (row) => (
        <p
          style={{
            color: createStatusStyle(row.statusName),
          }}
        >
          {getStatusName(row.statusName)}
        </p>
      ),
    },
    {
      name: 'Payment status',
      selector: (row) => row.invoicePaymentStatus,
      sortable: true,
      format: (row) => (
        <p
          style={{
            color: createPaymentStatusStyle(row.invoicePaymentStatusName),
          }}
        >
          {getPaymentStatusName(row.invoicePaymentStatusName)}
        </p>
      ),
    },
  ];
  const sortedReminders = useMemo(
    () => pruneReminders(userDash?.reminders?.datesData),
    [userDash?.reminders?.datesData]
  );
  return (
    <OrgDashboardWrapper>
      {mobile ? (
        <Carousel invoiceMetrics={userInvoiceMetrics} />
      ) : (
        <TopLayout>
          <StatusContainer>
            <div className="stats-container">
              {orgMetrics.map((stat) =>
                stat?.notAllowed?.includes(role) ? (
                  []
                ) : (
                  <div className="stat-item" key={stat.name}>
                    <Text color={'#7D7D7D'} size={mobile && '12px'}>
                      {stat.name}
                    </Text>
                    <Text
                      weight="500"
                      size={mobile ? '16px' : '24px'}
                      color="#323232"
                    >
                      {stat.amount ? addCommasToNumber(stat.amount) : '0'}
                    </Text>
                    <Text
                      padding="0 10px"
                      bg={'#7D7D7D33'}
                      display="inline-block"
                      size={mobile && '12px'}
                      radius="10px"
                      color="var(--grey1)"
                      className="stat-invoice"
                    >
                      {stat.count} Invoices
                    </Text>
                  </div>
                )
              )}
            </div>
            {role === USER_ROLES.CLIENT_USER && (
              <div className="stats2-container">
                {clientMetrics.map((stat) => (
                  <div className="stat-item" key={stat.name}>
                    <Text color={'#7D7D7D'} size={mobile && '12px'}>
                      {stat.name}
                    </Text>
                    <Text
                      weight="500"
                      size={mobile ? '16px' : '24px'}
                      color="#323232"
                    >
                      {stat.amount ? addCommasToNumber(stat.amount) : '0'}
                    </Text>
                    <Text
                      padding="0 10px"
                      bg={'#7D7D7D33'}
                      display="inline-block"
                      size={mobile && '12px'}
                      radius="10px"
                      color="var(--grey1)"
                      className="stat-invoice"
                    >
                      {stat.count} Invoices
                    </Text>
                  </div>
                ))}
              </div>
            )}
            {role !== USER_ROLES.CLIENT_USER && (
              <CircularStats className="circular-stats">
                <div className="status-container ">
                  <div className="filters">
                    <Span color="var(--primary2)" size="16px" weight="500">
                      Bill Status
                    </Span>
                    <DateFilterDropdown
                      selected={selectedTimeFilter}
                      setSelected={setSelectedTimeFilter}
                    />
                    <StyledLink to={ROUTES.BILLS}>View</StyledLink>
                  </div>
                  <CircularProgressWithChildren
                    values={{
                      total: {
                        value: userDash?.unPaidBillsCount ?? 0,
                        color: 'var(--primary1)',
                        label: 'Unpaid',
                      },
                      remaining: {
                        value: userDash?.paidBillsCount ?? 0,
                        color: 'var(--grey3)',
                        label: 'Paid',
                      },
                    }}
                    strokeWidth={12}
                  >
                    <Span size="21px" weight="bold" display="block">
                      Unpaid
                    </Span>
                    <Span
                      size="21px"
                      weight="bold"
                      display="block"
                      bg="white"
                      radius="6px"
                      padding="0 6px"
                    >
                      {addCommasToNumber(userDash?.unPaidBillsAmount) ?? '0'}
                    </Span>
                  </CircularProgressWithChildren>
                </div>

                <div className="status-container ">
                  <div className="filters">
                    <div ref={ref} className="selected-item">
                      <FlexedDiv
                        justifyContent="space-between"
                        onClick={() => setVisible(!visible)}
                      >
                        {selectedView && (
                          <Span
                            color="var(--primary2)"
                            size="16px"
                            weight="500"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {selectedView}
                          </Span>
                        )}

                        <FaAngleDown size={16} className="icon" />
                      </FlexedDiv>
                      {visible && (
                        <Dropdown
                          options={[
                            { label: 'Paid vs Unpaid' },
                            { label: 'Invoice Status' },
                          ]}
                          callback={(val) => setSelectedView(val.label)}
                        />
                      )}
                    </div>

                    <DateFilterDropdown
                      selected={selectedTimeFilter}
                      setSelected={setSelectedTimeFilter}
                    />
                    <StyledLink to={ROUTES.INVOICE}>View</StyledLink>
                  </div>
                  {
                    <DoughnutChart
                      invoices={
                        selectedView === 'Paid vs Unpaid'
                          ? {
                              UnPaid: {
                                amount:
                                  userDash?.unPaidBillsAmount +
                                    userDash?.unPaidInvoicesAmount ?? 0,
                                percentage:
                                  ((userDash?.unPaidBillsAmount +
                                    userDash?.unPaidInvoicesAmount) /
                                    (userDash?.paidBillsAmount +
                                      userDash?.paidInvoicesAmount +
                                      userDash?.unPaidBillsAmount +
                                      userDash?.unPaidInvoicesAmount)) *
                                  100,
                                color: '#011747',
                              },
                              Paid: {
                                amount:
                                  userDash?.paidBillsAmount +
                                    userDash?.paidInvoicesAmount ?? 0,
                                percentage:
                                  ((userDash?.paidBillsAmount +
                                    userDash?.paidInvoicesAmount) /
                                    (userDash?.paidBillsAmount +
                                      userDash?.paidInvoicesAmount +
                                      userDash?.unPaidBillsAmount +
                                      userDash?.unPaidInvoicesAmount)) *
                                  100,
                                color: '#6C98F3',
                              },
                            }
                          : userDash
                      }
                      label={
                        selectedView === 'Paid vs Unpaid'
                          ? selectedView
                          : 'Invoices'
                      }
                    />
                  }
                </div>
              </CircularStats>
            )}
          </StatusContainer>
          {role === USER_ROLES.CLIENT_USER && (
            <CircularStats
              className="circular-stats"
              maxWidth="500px"
              minHeight="100%"
            >
              <div className="status-container ">
                <div className="filters">
                  <div ref={ref} className="selected-item">
                    <FlexedDiv
                      justifyContent="space-between"
                      onClick={() => setVisible(!visible)}
                    >
                      {selectedClientView && (
                        <Span
                          color="var(--primary2)"
                          size="16px"
                          weight="500"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {selectedClientView}
                        </Span>
                      )}

                      <FaAngleDown size={16} className="icon" />
                    </FlexedDiv>
                    {visible && (
                      <Dropdown
                        options={[
                          { label: 'Invoice Status' },
                          { label: 'Payment Status' },
                        ]}
                        callback={(val) => setSelectedClientView(val.label)}
                      />
                    )}
                  </div>

                  <DateFilterDropdown
                    selected={selectedTimeFilter}
                    setSelected={setSelectedTimeFilter}
                  />
                  <StyledLink to={ROUTES.INVOICE}>View</StyledLink>
                </div>
                {
                  <DoughnutChart
                    invoices={
                      selectedClientView === 'Invoice Status'
                        ? {
                            Pending: {
                              amount: clientMetrics[1].amount,
                              percentage:
                                (clientMetrics[1].amount / totalClientAmount) *
                                100,
                              color: '#C99A0A',
                            },
                            Approved: {
                              amount: clientMetrics[0]?.amount,
                              percentage:
                                (clientMetrics[0]?.amount / totalClientAmount) *
                                100,
                              color: '#5CBC77',
                            },
                            'Validation Requests': {
                              amount: clientMetrics[3]?.amount,
                              percentage:
                                (clientMetrics[3]?.amount / totalClientAmount) *
                                100,
                              color: '#0148DE',
                            },
                          }
                        : {
                            UnPaid: {
                              amount: 0,
                              percentage:
                                ((userDash?.unPaidBillsAmount +
                                  userDash?.unPaidInvoicesAmount) /
                                  (userDash?.paidBillsAmount +
                                    userDash?.paidInvoicesAmount +
                                    userDash?.unPaidBillsAmount +
                                    userDash?.unPaidInvoicesAmount)) *
                                100,
                              color: '#011747',
                            },
                            Paid: {
                              amount:
                                userDash?.paidBillsAmount +
                                  userDash?.paidInvoicesAmount ?? 0,
                              percentage:
                                ((userDash?.paidBillsAmount +
                                  userDash?.paidInvoicesAmount) /
                                  (userDash?.paidBillsAmount +
                                    userDash?.paidInvoicesAmount +
                                    userDash?.unPaidBillsAmount +
                                    userDash?.unPaidInvoicesAmount)) *
                                100,
                              color: '#6C98F3',
                            },
                          }
                    }
                    label={'amount'}
                    height={'284px'}
                  />
                }
              </div>
            </CircularStats>
          )}
          {role !== USER_ROLES.CLIENT_USER && (
            <aside className="reminders">
              <FlexedDiv
                className="reminder-header"
                justifyContent="space-between"
              >
                <Text
                  margin="0"
                  color="var(--primary1)"
                  weight="700"
                  size="16px"
                >
                  Reminders
                </Text>{' '}
                <StyledLink to={ROUTES.REMINDER}>View</StyledLink>
              </FlexedDiv>
              <div className="reminders-content">
                {sortedReminders?.length > 0 ? (
                  sortedReminders?.map((item, i) => (
                    <div key={i}>
                      <Text color="var(--grey1)" weight="700" size="12px">
                        {item?.dayOfWeekName}
                        <Span display="inline" margin="0 4px">
                          {item?.day}
                        </Span>
                      </Text>
                      {item.data?.map((reminder) => (
                        <Text
                          key={reminder?.id}
                          color="#7B8599"
                          size="14px"
                          bg={randomColorGenerator()}
                          padding="4px"
                          margin="10px 0"
                        >
                          {reminder?.title}
                        </Text>
                      ))}
                    </div>
                  ))
                ) : (
                  <Text color="#7B8599" className="no-reminder">
                    No reminders yet!
                  </Text>
                )}
              </div>
            </aside>
          )}
        </TopLayout>
      )}

      {mobile ? (
        ''
      ) : (
        <div style={{ marginTop: '24px' }}>
          <InvoiceList
            invoices={lastFiveInvoices}
            loading={isLoading}
            columnsOrg={columns}
            selectableRows={false}
            pagination={false}
            tableHeader={
              <TableHeader
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 0,
                  margin: 0,
                }}
              >
                <div
                  style={{ borderBottom: '1px solid #F2F6FF', width: '100%' }}
                >
                  <Heading color=" var(--primary2)" level={2}>
                    Invoice Status
                  </Heading>
                  <Text color="var(--grey2)">
                    Monitor the progress of your invoice
                  </Text>
                </div>

                <SummaryLink style={{ borderBottom: 'none', margin: 0 }}>
                  {INVOICE_STATUS_DROPDOWN_DATA.option.map((status, index) => {
                    let count = getInvoiceStatusCount(
                      status.label,
                      lastFiveInvoices?.filter(
                        (item) => item.statusName === 'Pending'
                      ).length || 0,
                      lastFiveInvoices?.filter(
                        (item) => item.statusName === 'Approved'
                      ).length || 0,
                      lastFiveInvoices?.filter(
                        (item) => item.statusName === 'Declined'
                      ).length || 0,
                      lastFiveInvoices?.filter(
                        (item) => item.statusName === 'ChangesRequested'
                      ).length || 0,
                      lastFiveInvoices.length
                    );
                    return (
                      <div
                        key={index}
                        className={`${
                          (statusInput === status.label && status.label) ||
                          ((statusInput === '' || statusInput === 'Status') &&
                            status.label === 'All')
                            ? 'active'
                            : ''
                        }`}
                      >
                        <Text
                          size={18}
                          weight={500}
                          color={createStatusStyle(status.label)}
                        >
                          {status.label}
                        </Text>
                        <div className="summary-link-count">
                          <p>{count}</p>
                        </div>
                      </div>
                    );
                  })}
                </SummaryLink>
              </TableHeader>
            }
            noDataComponent={
              <NoData>
                <NoDataSvg width={'100%'} />
                <Text color="#7B8599" style={{ marginTop: '16px' }}>
                  You have no invoice to review yet. Create an invoice to get
                  started.
                </Text>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                    marginTop: '40px',
                  }}
                ></div>
              </NoData>
            }
          />
        </div>
      )}

      <Card style={{ marginTop: '24px' }}>
        {mobile ? (
          <>
            <TableHeader style={{ display: 'block' }}>
              <div
                className="week-active"
                style={{ borderBottom: '1px solid #F2F6FF' }}
              >
                <Flex justifyContent="flex-end">
                  <SelectOccurance
                    margin="0 0 0 15px"
                    size={14}
                    onClick={() => {
                      setActive(true);
                      setTimeInput({ ...timeInput, weekly: true });
                      setSelectedDuration('Weekly');
                    }}
                  >
                    <Flex>
                      <OptionSelectCircle
                        border="2px solid var(--primary2)"
                        style={{
                          backgroundColor:
                            selectedDuration === 'Weekly'
                              ? 'var(--primary2)'
                              : '',
                        }}
                      ></OptionSelectCircle>
                      Weekly
                    </Flex>
                  </SelectOccurance>

                  <SelectOccurance
                    size={14}
                    onClick={() => {
                      setActive(false);
                      setTimeInput({ ...timeInput, weekly: false });
                      setSelectedDuration('Monthly');
                    }}
                  >
                    <Flex>
                      <OptionSelectCircle
                        border="2px solid var(--primary2)"
                        style={{
                          backgroundColor:
                            selectedDuration === 'Monthly'
                              ? 'var(--primary2)'
                              : '',
                        }}
                      ></OptionSelectCircle>
                      Monthly
                    </Flex>
                  </SelectOccurance>
                </Flex>
                <div>
                  <Heading color=" var(--primary2)" size={mobile ? 16 : 18.72}>
                    {role === USER_ROLES.CLIENT_USER
                      ? 'Paid vs Unpaid Invoice'
                      : 'Payables vs Receivables'}
                  </Heading>
                  <Text color="var(--grey2)" size={mobile ? 12 : 16}>
                    Track your invoice flow for any given period
                  </Text>
                </div>
              </div>
            </TableHeader>

            <LabelContainer>
              <ChartLabel
                color={'#6C98F3'}
                name={
                  role === USER_ROLES.CLIENT_USER
                    ? 'Paid Invoices'
                    : 'Received Bills'
                }
              />
              <ChartLabel
                color={'#BF4545'}
                name={
                  role === USER_ROLES.CLIENT_USER
                    ? 'Unpaid Invoices'
                    : 'Paid Invoices'
                }
              />
            </LabelContainer>
          </>
        ) : (
          <>
            <TableHeader style={{ display: 'block' }}>
              <div
                className="week-active"
                style={{ borderBottom: '1px solid #F2F6FF' }}
              >
                <div>
                  <Heading color=" var(--primary2)" level={3}>
                    {role === USER_ROLES.CLIENT_USER
                      ? 'Paid vs UnPaid'
                      : 'Payables vs Receivables'}
                  </Heading>

                  <Text color="var(--grey2)" size={16}>
                    Track your invoice flow for any given period
                  </Text>
                </div>
                <Flex justifyContent="flex-end">
                  <SelectOccurance
                    margin="0 0 0 15px"
                    size={14}
                    onClick={() => {
                      setActive(true);
                      setTimeInput({ ...timeInput, weekly: true });
                      setSelectedDuration('Weekly');
                    }}
                  >
                    {' '}
                    <Flex>
                      <OptionSelectCircle
                        border="2px solid var(--primary2)"
                        style={{
                          backgroundColor:
                            selectedDuration === 'Weekly'
                              ? 'var(--primary2)'
                              : '',
                        }}
                      ></OptionSelectCircle>
                      Weekly
                    </Flex>
                  </SelectOccurance>

                  <SelectOccurance
                    size={14}
                    onClick={() => {
                      setActive(false);
                      setTimeInput({ ...timeInput, weekly: false });
                      setSelectedDuration('Monthly');
                    }}
                  >
                    <Flex>
                      <OptionSelectCircle
                        border="2px solid var(--primary2)"
                        style={{
                          backgroundColor:
                            selectedDuration === 'Monthly'
                              ? 'var(--primary2)'
                              : '',
                        }}
                      ></OptionSelectCircle>
                      Monthly
                    </Flex>
                  </SelectOccurance>
                </Flex>
              </div>
            </TableHeader>

            <LabelContainer>
              <ChartLabel
                color={'#6C98F3'}
                name={
                  role === USER_ROLES.CLIENT_USER
                    ? 'Paid Invoices'
                    : 'Received Bills'
                }
              />
              <ChartLabel
                color={'#BF4545'}
                name={
                  role === USER_ROLES.CLIENT_USER
                    ? 'Unpaid Invoices'
                    : 'Paid Invoices'
                }
              />
            </LabelContainer>
          </>
        )}

        {role === USER_ROLES.CLIENT_USER ? (
          <BarChart timeData={timeData} client />
        ) : (
          <BarChart timeData={timeData} user />
        )}
      </Card>

      <div style={{ marginTop: '20px' }}>
        {mobile ? (
          <Card
            h="400px"
            className="cheat-card"
            style={{ position: 'relative' }}
          >
            <Text
              textAlign="center"
              size={14}
              weight={700}
              color="#23218B"
              text="External Clients vs Organisation"
            />
            <CircularProgressBar
              // count={clientUserInfo?.freelancers}
              count={80}
              width={200}
              radius={90}
              color="#FE9900"
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Text size="20px" weight="700" style={{ margin: 0 }}>
                {/* {clientUserInfo?.freelancers + clientUserInfo?.contractors || 0} */}
              </Text>
              <Text weight="700" style={{ margin: 0 }}>
                Total
              </Text>
            </div>
            <CircularProgressBar
              count={20}
              width={180}
              radius={72}
              color="#00C8F0"
            />
            <div
              style={{
                position: 'absolute',
                bottom: '10px',
              }}
            >
              <div style={{ display: 'flex', gap: '20px' }}>
                <div>
                  <ChartLabel
                    size={12}
                    color={'#6C98F3'}
                    invoice="External Clients"
                  />
                  <Text>80&</Text>
                </div>
                <div>
                  <ChartLabel
                    size={12}
                    color={'#FE9900'}
                    invoice=" Organisation"
                  />
                  <Text>20%</Text>
                </div>
              </div>
            </div>
          </Card>
        ) : (
          ''
        )}
      </div>
    </OrgDashboardWrapper>
  );
};

export default OrganizationDashboard;
