import {
  appDataActions,
  appdataSelector,
} from 'store/reducers/appData/appDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import BaseBillDetails from './BaseBillDetails';
import { ROUTES } from 'utils/constants/constants.utils';
import {
  SettingsActions,
  SettingsSelectors,
} from 'store/reducers/settings/settingsSlice';
import { useEffect } from 'react';

const PreviewBill = () => {
  const bill = useSelector(appdataSelector.previewBill);
  const organization = useSelector(SettingsSelectors.organization);
  const { getOrganizationDetails } = SettingsActions;
  const { setHeaderText } = appDataActions;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationDetails());

    dispatch(setHeaderText({ value: 'Preview Bill', type: 'setHeaderText' }));

    // eslint-disable-next-line
  }, []);
  return (
    <BaseBillDetails
      backRoute={ROUTES.CREATE_BILLS}
      invoice={{
        accountName: organization.accountName,
        accountNumber: organization.accountNumber,
        bankName: organization.bankName,
        sortCode: organization.sortCodeIBAN,
        ...bill,
      }}
    />
  );
};

export default PreviewBill;
