import styled from 'styled-components';

const RainbowContainer = styled.div`
  width: 100%;
  height: 148px;
  display: flex; /* Stack stripes horizontally */

  margin-bottom: 2rem;
`;

const RainbowStripe = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    ${({ stripeIndex }) =>
      `${colors[stripeIndex]}, ${colors[(stripeIndex + 1) % colors.length]}`}
  );
`;
export const ColorPreview = styled.div`
  height: ${({ size }) => size || '2rem'};
  width: ${({ size }) => size || '2rem'};
  border-radius: 50%;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.15));
  background: ${({ color }) => color};
`;

const colors = [
  '#302E2E',
  '#F02E2E',
  '#DF2B2B',
  '#F5C51B',
  '#38E071',
  '#54A0CB',
  '#0733CF',
  '#422EC1',
  '#CC10C4',
  '#E10909',
];

const CustomColor = ({ selectedColor, setSelectedColor }) => {
  return (
    <div>
      <RainbowContainer>
        {colors.map((color, index) => (
          <RainbowStripe
            key={color}
            stripeIndex={index}
            onMouseOver={() => setSelectedColor(color)}
            onClick={() => setSelectedColor(color)}
          />
        ))}
      </RainbowContainer>
      {selectedColor && <ColorPreview color={selectedColor} />}
    </div>
  );
};

export default CustomColor;
