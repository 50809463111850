import { ErrorMessage, useField } from 'formik';
import styled from 'styled-components';
import { ErrorText } from '../form-input/form-input.component';

const Switch = styled.div`
  position: relative;
  width: 44px;
  height: 26px;
  background: #bf4545;
  border-radius: 32px;
  padding: 3px;
  transition: 300ms all ease-in;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 35px;
    top: 3px;
    left: 3px;
    background: white;
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: #5cbc77;

    &:before {
      left: 21px;
    }
  }
`;

const FormSwitch = ({ label, ...otherProps }) => {
  const [field, meta] = useField(otherProps);

  return (
    <div>
      <label htmlFor={field?.name}>
        {label}
        <Input
          id={field?.name}
          type="checkbox"
          {...otherProps}
          {...field}
          error={meta.touched && meta.error}
        />
        <Switch />
      </label>

      <ErrorMessage name={field?.name}>
        {(msg) => <ErrorText id="text">{msg}</ErrorText>}
      </ErrorMessage>
    </div>
  );
};

export default FormSwitch;

export const FormSwitchNoFormik = ({ label, ...otherProps }) => {
  return (
    <div>
      <label htmlFor={otherProps?.name}>
        {label}
        <Input id={otherProps?.name} type="checkbox" {...otherProps} />
        <Switch />
      </label>
    </div>
  );
};
