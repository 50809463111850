import styled from 'styled-components';
import Select from 'react-select/creatable';
import Card from 'app/components/atoms/card/card.component';
import {
  FormInputLable,
  errorStyle,
  shrinkLabelStyle,
} from '../form-input/form-input.styles';

export const InvoiceDataInput = styled.input`
  background: none;
  background-color: #f2f6ff;
  color: grey;
  font-size: 18px;
  display: block;
  width:'100%';
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  margin: ${({ margin }) => margin || '20px 0 10px'};

  :invalid {
    ${({ error }) => error && errorStyle};
  }

  &[type="date"] {
    height: 40px;
    width: 100%;
    background-color: #fff;
    border-color: #cccccc;
  }

  ::placeholder {
    color: #3777ff;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &:focus ~ ${FormInputLable} {
    ${shrinkLabelStyle};
  }

  @media screen and (max-width: 1200px) {
    &[type="date"] {
      height: 55px;
      margin: ${({ margin }) => margin || '10px 0 10px'};
    }
  }
}
`;

export const InputDataHouse = styled.div`
  @media screen and (max-width: 768px) {
    gap: 0.1rem;
    width: 85%;
    font-size: 14px;
  }
`;

export const InvoiceInputDataHouse = styled.div`
  @media screen and (max-width: 768px) {
    gap: 0.1rem;
    width: 85%;
    font-size: 14px;
  }
`;

export const InvoiceSubjectContainer = styled.div`
  background-color: #fff;
  border-color: #cccccc;
  width: 31.3%;
  font-size: 40px;
  margin-top: -40px;
  @media screen and (max-width: 768px) {
    width: 50%;
    height: 60px;
    font-size: 14px;
  }
`;

export const SubjectContainer = styled.div`
  background-color: #fff;
  border-color: #cccccc;
  margintop: 20px;
  width: 31.3%;
  font-size: 40px;

  @media screen and (max-width: 768px) {
    width: 50%;
    font-size: 14px;
  }
`;

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  padding: 20px 40px 40px;
  margin-top: 48px;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;

export const InvoiceInput = styled.input`
  font-size: 10px;
  padding: 0 5px;
  display: block;
  width: 100%;
  height: 35px;
  border: 1px solid #b8c2cf;
  border-radius: 4px;
  background: #fbfcff;

  ::placeholder {
    color: var(--grey2);
    opacity: 1;
    word-break: break-all;
  }

  &:focus {
    outline: none;
  }
`;

export const InvoiceSubjectInput = styled.input`
  font-size: 15px;
  padding: 0 5px;
  display: block;
  width: 100%;
  height: 35px;
  border: 1px solid #b8c2cf;
  border-radius: 4px;
  background: #fbfcff;
  margin: 10px 0 10px;

  ::placeholder {
    color: var(--grey2);
    opacity: 1;
    word-break: break-all;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    height: 55px;
    margin-top: 10px;
    font-size: 14px;
  }
`;

export const InvoiceBody = styled(Card)`
  padding: 0;
  .details {
    padding-bottom: 40px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (min-width: 700px) {
    flex-direction: row;
    .cancel {
      margin-left: auto;
    }
  }
  > * {
    flex: 1;
  }
`;
export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ justifyContent }) => justifyContent || 'center'};
  padding: 20px;
  button {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-weight: bold;
    color: #0148de;
    cursor: pointer;
  }
`;
export const Label = styled.label`
  color: #3a3e47;
  font-weight: bold;
  font-size: 12px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-content: space-between;
  gap: 0.5rem;
  position: relative;
  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #b8c2cf;
    height: 35px;
    width: 100%;
    border-radius: 4px;
    background: #fbfcff;
    p {
      font-size: 10px;
      margin-left: 5px;
      color: var(--grey2);
    }
    .search_icon {
      background-color: var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% + 3px);
      border-radius: 0 3px 3px 0;
      color: white;
      width: 32px;
      svg {
        width: 20px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
    .search_icon {
      margin-left: 20px;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ repeat }) => repeat || '3'}, 1fr);
  gap: 20px;
  justify-content: space-between;
  position: relative;
  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #b8c2cf;
    height: 35px;
    width: 100%;
    border-radius: 4px;
    background: #fbfcff;
    p {
      font-size: 10px;
      margin-left: 5px;
      color: var(--grey2);
    }
    .search_icon {
      background-color: var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% + 3px);
      border-radius: 0 3px 3px 0;
      color: white;
      width: 32px;
      svg {
        width: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1200px) {
    gap: 2rem;
    .search_icon {
      margin-left: 20px;
    }
  }
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  margin: 10px 0 10px;
  .react-select__control {
    @media (max-width: 1200px) {
      height: 55px;
      margin: 10px 0 10px;
    }
  }
  .select {
    &__control {
      width: ${({ width }) => width || '100%'};
      border: 1px solid #b8c2cf;
      border-radius: 5px;

      option {
        font-size: 18px;
      }
    }

    &__menu-list {
      display: flex;
      flex-direction: column;
    }
  }
`;
