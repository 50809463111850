import styled from 'styled-components';

export const GreetingsContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; */
  margin-bottom: 20px;
  @media screen and (min-width: 855px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    'a a a'
    'b b b'
    'c c c'
    'd d d'
    'e e e';

  @media screen and (min-width: 950px) {
    grid-template-areas: ;
  }
`;

export const OrgUserDashboardWrapper = styled.div`
  .metric {
    position: relative;
    .left {
      position: absolute;
      top: 3.8em;
      left: -1.5em;
      @media screen and (min-width: 850px) {
        left: -2.7em;
      }
    }
    .right {
      position: absolute;
      top: 3.8em;
      right: -1.5em;
      @media screen and (min-width: 850px) {
        right: -2.7em;
      }
    }
  }
  .cheat-card {
    flex: 1;
    flex-basis: 400px;
    @media screen and (min-width: 700px) {
      height: auto;
    }
    @media screen and (min-width: 1200px) {
      flex-basis: 0;
    }
  }
  .label-circle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (min-width: 1500px) {
      flex-direction: row;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  width: ${({ width }) => width};
  flex-wrap: ${({ wrap }) => wrap};
  word-wrap: ${({ wordWrap }) => wordWrap};
  gap: ${({ gap }) => gap || '1.25rem'};
  margin: ${({ margin }) => margin};
  .invoice-details__invoice-number {
    margin-bottom: 20px;
    p {
      margin: 0;
      color: '#3A3E47';
    }
  }

  .invoice-details__banner {
    gap: 40px;
  }

  @media (max-width: 500px) {
    gap: 8px;
  }
`;

export const FlexInvoiceActions = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  margin: ${({ margin }) => margin};

  .invoice-details__invoice-number {
    margin-bottom: 20px;
    p {
      margin: 0;
      color: '#3A3E47';
    }
  }

  .invoice-details__banner {
    gap: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
